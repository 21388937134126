<template>
    <div>
        <div class="comments__reviews">
            <ul class="list-inline">
                <li>
                    <a
                        href="#"
                        v-b-modal="'commentModal_' + id"
                        class="report"
                        @click.prevent
                    >
                        <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            xml:space="preserve"
                            width="30.9987mm"
                            height="30.9951mm"
                            version="1.1"
                            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                            viewBox="0 0 5765.47 5764.8"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
                        >
                            <use xlink:href="#indecator-icon"></use>
                        </svg>
                    </a>
                </li>
                <li>
                    <a
                        class="comment"
                        :class="[replyBox ? 'active' : '']"
                        @click="toggleComments"
                    >
                        <svg
                            fill="currentColor"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 48 46.4"
                        >
                            <use xlink:href="#write-comment"></use>
                        </svg>
                    </a>
                </li>
                <li>
                    <a class="like" @click="likeComment">
                        <svg
                            fill="currentColor"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 450.89 512"
                        >
                            <use xlink:href="#review-like-icon"></use>
                        </svg>
                        <span>{{ likeCount }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div v-if="replyBox" class="comments__reviews--reply active">
            <input
                v-model="comment"
                type="text"
                class="form-control"
                :placeholder="$t('Type your comment')"
            />
            <button @click="addComment" type="button" class="btn">
                <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 321.49 294.65"
                >
                    <use xlink:href="#review-reply-icon"></use>
                </svg>
            </button>
        </div>
        <base-comments
            v-if="replyBox && comments.length"
            :object="object"
            :comments="comments"
            :loadding="false"
            @addComment="$emit('addComment', $event)"
            @likeComment="$emit('likeComment', $event)"
        ></base-comments>
        <!-- report popup -->
        <b-modal :id="'commentModal_' + id" hide-footer>
            <div class="categoryReport">
                <h5 class="categoryReport_maintitle">
                    Why do you want to report?
                </h5>
                <h4 class="categoryReport_subtitle">
                    Select Comment Report Category
                </h4>
                <div class="categoryReport_radio">
                    <div class="categoryReport_radio--section">
                        <input type="radio" name="radio6" checked="" />
                        <div>
                            We live in a communication era, with rapid expansion
                            in the reach of mass media, and improved techniques
                            for the interpersonal exchange of ideas. The advent
                            of the cheap transistor radio, for example, has
                            brought this medium to remote corners of even the
                            least-developed countries, where a lack of
                            electricity can be overcome by solar-powered
                            transmitters and receivers.
                        </div>
                    </div>
                    <div class="categoryReport_radio--section">
                        <input type="radio" name="radio6" />
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nunc eget dolor tortor. Maecenas ac dui sed
                            arcu porttitor luctus sit amet in mauris. Maecenas
                            interdum consectetur nulla quis elementum. Phasellus
                            vitae mauris diam. Nullam in vehicula neque. Integer
                            bibendum ante eu orci lacinia laoreet eu in orci.
                            Fusce in quam volutpat, sollicitudin turpis eu,
                            pulvinar odio.
                        </div>
                    </div>
                    <div class="categoryReport_radio--section">
                        <input type="radio" name="radio6" />
                        <div>
                            Maecenas interdum consectetur nulla quis elementum.
                            Phasellus vitae mauris diam. Nullam in vehicula
                            neque. Integer bibendum ante eu orci lacinia laoreet
                            eu in orci. Fusce in quam volutpat, sollicitudin
                            turpis eu, pulvinar odio.
                        </div>
                    </div>
                    <div class="categoryReport_radio--section">
                        <input type="radio" name="radio6" />
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nunc eget dolor tortor. Maecenas ac dui sed
                            arcu porttitor luctus sit amet in mauris. Maecenas
                            interdum consectetur nulla quis elementum. Phasellus
                            vitae mauris diam. Nullam in vehicula neque. Integer
                            bibendum ante eu orci lacinia laoreet eu in orci.
                            Fusce in quam volutpat, sollicitudin turpis eu,
                            pulvinar odio.
                        </div>
                    </div>
                </div>
                <div class="button-row">
                    <a href="#" class="btn btn-pink">report</a>
                </div>
            </div>
        </b-modal>
        <!-- report popup -->
    </div>
</template>

<script>
export default {
    props: {
        id: Number,
        likeCount: Number,
        comments: Array,
        object: Object,
        commentObject: Object,
    },
    data() {
        return {
            replyBox: false,
            comment: '',
        }
    },
    methods: {
        toggleComments: function() {
            this.replyBox = !this.replyBox
        },
        addComment() {
            this.$emit('addComment', {
                uuid: this.object.uuid,
                parent: this.id,
                comment: this.comment,
                commentObject: this.commentObject,
            })
            this.comment = ''
        },
        likeComment() {
            this.$emit('likeComment', {
                id: this.id,
                comment: this.comment,
                commentObject: this.commentObject,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.comments__reviews {
    .list-inline {
        justify-content: flex-end;
        align-items: center;
        li:not(:last-child) {
            margin-right: rem(15px);
        }
    }
    a {
        color: #928f91;
        svg {
            width: 30px;
            height: auto;
        }
        &.report {
            svg {
                width: 27px;
            }
        }
        &.report.active {
            color: red;
        }
        &.comment.active {
            color: #009b74;
        }
        &.like {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: flex-start;
            span {
                display: inline-block;
                padding-left: 0.9375rem;
                font-size: 1.4375rem;
                font-weight: 600;
            }
        }
        &.like.active {
            color: #69be29;
        }
        @media screen and (min-width: 1025px) {
            &.like:hover {
                color: #69be29;
            }
            &.report:hover {
                color: red;
            }
            &.comment:hover {
                color: #009b74;
            }
        }
    }
    &--reply {
        background: #fff;
        border-radius: 2px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
        padding: rem(7px) rem(15px);
        margin-top: rem(20px);
        @include flex(center, space-between);
        flex-wrap: nowrap;
        display: none;
        &.active {
            display: flex;
        }
        .form-control {
            border: 0px;
            background: transparent;
            font-size: rem(16px);
            color: #464646;
            padding: 0px;
            box-shadow: none;
            height: rem(50px);
            &::placeholder {
                color: #464646;
                font-size: rem(16px);
            }
            &:focus {
                box-shadow: none;
                border-color: transparent;
            }
        }
        .btn {
            background: transparent;
            padding: 0px;
            color: #e4d700;
            margin-left: rem(8px);
            @media screen and (min-width: 1025px) {
                &:hover {
                    color: var(--primary);
                }
            }
            svg {
                width: 27px;
                height: auto;
                transform: rotate(-40deg);
                position: relative;
                top: -4px;
            }
        }
    }
    @media screen and (max-width: 575px) {
        a {
            svg {
                width: 22px;
            }
            &.report {
                svg {
                    width: 22px;
                }
            }
        }
    }
}

// ar style

.ar {
    .comments__reviews {
        direction: ltr;
        display: flex;
        // .list-inline {
        //     li:not(:last-child) {
        //         margin-left: rem(15px);
        //         margin-right: 0;
        //     }
        // }

        &--reply {
            .btn {
                margin-right: rem(8px);
                margin-left: 0;
            }
        }
    }
    // .comments__reviews a.like {
    //     /deep/{
    //         span{
    //             padding-right: .9375rem;
    //             padding-left:0;
    //         }
    //     }
    // }
}

// report model styling
/deep/ {
    .modal {
        .modal-dialog {
            max-width: 1230px;
            .modal-content {
                padding: rem(50px) rem(80px) rem(60px);
                border-radius: 0;
                .modal-header {
                    padding: 0;
                    .close {
                        background-color: transparent;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24px' height='24px'%3E%3Cpath fill-rule='evenodd' fill='rgb(27, 12, 37)' d='M13.711,12.337 L23.103,21.728 C23.622,22.247 23.622,23.088 23.103,23.607 C22.584,24.125 21.743,24.125 21.225,23.607 L11.833,14.215 L2.441,23.607 C1.922,24.125 1.082,24.125 0.563,23.607 C0.044,23.088 0.044,22.247 0.563,21.728 L9.955,12.337 L0.563,2.945 C0.044,2.426 0.044,1.585 0.563,1.066 C1.082,0.547 1.922,0.547 2.441,1.066 L11.833,10.458 L21.225,1.066 C21.743,0.547 22.584,0.547 23.103,1.066 C23.622,1.585 23.622,2.426 23.103,2.945 L13.711,12.337 Z'/%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        border: 0;
                        padding: 0;
                        font-size: 0;
                        height: 24px;
                        width: 24px;
                        position: absolute;
                        margin: 0;
                        right: 40px;
                        top: 40px;
                        opacity: 1;
                        @media screen and (min-width: 1025px) {
                            &:hover {
                                opacity: 0.7;
                            }
                        }
                    }
                }
                .modal-body {
                    padding: 0;
                    .categoryReport {
                        &_maintitle {
                            font-size: rem(36px);
                            color: var(--primary);
                            text-align: center;
                            font-weight: 900;
                            margin-bottom: rem(65px);
                        }
                        &_subtitle {
                            font-size: rem(26px);
                            color: #010101;
                            font-weight: 600;
                            margin-bottom: rem(20px);
                        }
                        &_radio {
                            &--section {
                                position: relative;
                                &:not(:last-child) {
                                    margin-bottom: rem(28px);
                                }
                                div {
                                    position: relative;
                                    font-size: rem(22px);
                                    color: black;
                                    border: solid 1px transparent;
                                    background-color: #f7f7f7;
                                    padding: rem(20px) rem(25px);
                                    padding-bottom: rem(30px);
                                    transition: 0.3s all ease-in;
                                    line-height: normal;
                                    text-align: justify;
                                }
                                input[type='radio'] {
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    z-index: 9;
                                    opacity: 0;
                                    cursor: pointer;
                                    + div {
                                        &:after {
                                            content: '';
                                            position: absolute;
                                            left: 0px;
                                            top: 0%;
                                            opacity: 0;
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                    &:checked {
                                        + div {
                                            border-color: #e4d700;
                                            background-color: #f5f0f6;
                                            box-shadow: 2px 2px 8px 1px
                                                rgba(184, 172, 180, 0.7);
                                        }
                                    }
                                }
                            }
                        }
                        .button-row {
                            justify-content: center;
                            margin-top: rem(35px);
                        }
                    }
                }
                @media screen and (max-width: 767px) {
                    padding: rem(35px) rem(40px) rem(20px);
                    .modal-header {
                        padding: 0;
                        .close {
                            right: 15px;
                            top: 15px;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

// ar style

.ar {
    /deep/ {
        .modal {
            .modal-dialog {
                .modal-content {
                    .modal-header {
                        .close {
                            left: 40px;
                            right: auto;
                        }
                    }
                    .modal-body {
                        .categoryReport {
                            &_radio {
                                &--section {
                                    input[type='radio'] {
                                        + div {
                                            &:after {
                                                right: 0px;
                                                left: auto;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    @media screen and (max-width: 767px) {
                        .modal-header {
                            .close {
                                left: 15px;
                                right: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
// report model styling
</style>
