import AccountNav from '@/common/layout/AccountNav.vue'
import VSelect from '@alfsnd/vue-bootstrap-select'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import {
    Hooper,
    Slide,
    Navigation as HooperNavigation,
    Pagination as HooperPagination,
    Progress as HooperProgress,
} from 'hooper'
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import Vue from 'vue'
import BeautifulChat from 'vue-beautiful-chat'
import vuescroll from 'vuescroll'

const requireComponent = require.context(
    './base',
    false,
    /Base[A-Z]\w+\.(vue|js)$/,
)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = upperFirst(
        camelCase(
            // Gets the file name regardless of folder depth
            fileName
                .split('/')
                .pop()
                .replace(/\.\w+$/, ''),
        ),
    )
    Vue.component(componentName, componentConfig.default || componentConfig)
})
// Install BootstrapVue
Vue.use(BootstrapVue)

//FontAwsome
library.add(faUserSecret)

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(BeautifulChat)

Vue.component('AccountNav', AccountNav)
Vue.component('Hooper', Hooper)
Vue.component('Slide', Slide)
Vue.component('Hooper-navigation', HooperNavigation)
Vue.component('hooper-pagination', HooperPagination)
Vue.component('hooper-progress', HooperProgress)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', VSelect)
Vue.component('scrollbar', vuescroll)
Vue.mixin({
    methods: {
        uuid: function(uuid) {
            return uuid.replace(/-/g, '')
        },
        getFileName(fullPath) {
            return fullPath.split('/').pop()
        },
        getNextPage(next) {
            if (!next) return false
            let url = new URL(next)
            return url.searchParams.get('page')
        },
        getNextOffset(next) {
            if (!next) return false
            let url = new URL(next)
            return url.searchParams.get('offset')
        },
    },
})
