import NotFound from '@/common/views/NotFound'
import ideas from '@/modules/ideas/router'
import leaderboard from '@/modules/leaderboard/router'
import mentorship from '@/modules/mentorship/router'
import users from '@/modules/users/router'
import workshop from '@/modules/workshop/router'
import Vue from 'vue'
import VueRouter from 'vue-router'

import i18n from '../common/i18n'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: `/${i18n.locale}`,
    },
    {
        path: '/:lang',
        component: () => import('@/common/views/RouterBase'),
        children: [
            ...ideas,
            ...workshop,
            ...mentorship,
            ...users,
            ...leaderboard,
        ],
    },
    { path: '*', component: NotFound },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        // ...
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})

export default router
