<template>
    <div class="score" v-if="userData.level">
        <h1>{{ userData.level.name }} {{ $t('Ideator') }}</h1>
        <div class="progress-bar-main">
            <div class="progress">
                <div
                    class="progress-bar bg-warning"
                    role="progressbar"
                    style="height:100%;"
                    :style="{
                        width: `${barPercentage}%`,
                    }"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
            <div class="details">
                <span class="percenteage"
                    >{{ userData.score }} {{ $t('points') }}</span
                >
                <span class="percenteage">
                    {{
                        userData.score > userData.level.max_score
                            ? 0
                            : userData.level.max_score - userData.score + 1
                    }}
                    {{ $t('points to next level') }}</span
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        userData: Object,
    },
    data() {
        return {
            barPercentage: 0,
        }
    },

    created() {
        this.setUserProgress()
    },
    watch: {
        'userData.score'() {
            this.setUserProgress()
        },
    },
    methods: {
        setUserProgress() {
            if (this.userData && this.userData.level) {
                this.barPercentage =
                    ((this.userData.score - this.userData.level.min_score) /
                        (this.userData.level.max_score -
                            this.userData.level.min_score)) *
                    100
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.score {
    padding-top: 1rem;
    h1 {
        color: #009b74;
        font-size: rem(32px);
        @media screen and (max-width: 991px) {
            font-size: rem(24px);
        }
    }
    .progress-bar-main {
        width: 100%;
        max-width: 315px;
        height: auto;
        background: transparent;
        position: relative;
        overflow: revert;
        @media screen and (max-width: 991px) {
            max-width: 80%;
        }
        @media screen and (max-width: 767px) {
            max-width: 310px;
            margin: auto !important;
        }

        progress {
            width: 100%;
        }
        .details {
            padding-top: 0.5rem;
            display: flex;
            justify-content: space-between;
            color: white;
            @media screen and (max-width: 767px) {
                padding-bottom: 0.5rem;
            }
        }
    }
}
</style>
