import { UsersService } from '@/common/api_services/users'
import i18n from '@/common/i18n'
import { API_BASE } from '@/conf'
// import { BASE_URL_DOMAIN } from '@/conf'
import { FETCH_SUCCESS, FETCH_ERROR, FETCH_START } from '@/store/common'
import axios from 'axios'
// import Cookies from 'js-cookie'
// import uniqid from 'uniqid'

import { USERS } from './actions'

const state = {
    userAuth: {},
    usersList: [],
    user: {},
    zainers: {},
    zainer: {},
    token: '',
    refreshToken: '',
    badgesList: {},
    opcoRanking: [],
    campaignFilters: [],
}

const getters = {
    userAuth: state => state.userAuth,
    usersList: state => state.usersList,
    user: state => state.user,
    zainers: state => state.zainers,
    zainer: state => state.zainer,
    token: state => state.token,
    badgesList: state => state.badgesList,
    opcoRanking: state => state.opcoRanking,
    campaignFilters: state => state.campaignFilters,
}

const mutations = {
    [USERS.SET_AUTH_USER]: (state, resp) => {
        state.userAuth = resp.data
    },
    [USERS.SET_LIST]: (state, resp) => {
        state.usersList = resp.data
    },
    [USERS.SET_USER]: (state, resp) => {
        state.user = resp.data
    },
    [USERS.SET_ZAINERS]: (state, resp) => {
        state.zainers = resp.data
    },
    [USERS.SET_ZAINER]: (state, resp) => {
        state.zainer = resp.data
    },
    [USERS.SET_AUTH_LOGOUT]: state => {
        state.token = ''
        state.refreshToken = ''
        state.userAuth = {}
    },
    [USERS.SET_TOKENS]: (state, resp) => {
        state.token = resp.data.access
        if (resp.data.refresh) state.refreshToken = resp.data.refresh
    },
    [USERS.SET_BADGES]: (state, resp) => {
        state.badgesList = resp.data
    },
    [USERS.SET_OPCO_RANKING]: (state, resp) => {
        state.opcoRanking = resp.data
    },
    [USERS.SET_CAMPAIGNS]: (state, resp) => {
        state.campaignFilters = resp.data
    },
}
const actions = {
    // Users entity actions
    [USERS.GET_AUTH_USER]: ({ commit }, payload) => {
        // commit(FETCH_START)
        return UsersService.getAuthUser(payload)
            .then(resp => {
                window.dataLayer = window.dataLayer || []
                function gtag() {
                    window.dataLayer.push(arguments)
                }
                gtag('config', process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID, {
                    user_id: resp.data.username,
                })
                commit(USERS.SET_AUTH_USER, resp)
                // commit(FETCH_SUCCESS)
            })
            .catch(() => {
                // commit(FETCH_ERROR)
            })
    },
    [USERS.LOGIN_USER]: ({ commit, dispatch }, payload) => {
        commit(FETCH_START)
        return axios
            .post(API_BASE + '/en/api/token/', payload.data)
            .then(async resp => {
                // let uniq_id = uniqid()
                // Cookies.set('user_session', uniq_id, {
                //     path: '/',
                //     domain: BASE_URL_DOMAIN,
                // })
                axios.defaults.headers.common['Authorization'] =
                    'Bearer ' + resp.data.access
                localStorage.setItem('token', JSON.stringify(resp.data.access))
                localStorage.setItem(
                    'refresh-token',
                    JSON.stringify(resp.data.refresh),
                )
                commit(USERS.SET_TOKENS, resp)
                await dispatch(USERS.GET_AUTH_USER)
                // commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err.response.data
            })
    },
    [USERS.SIGNUP_NEW_USER]: ({ commit }, payload) => {
        commit(FETCH_START)
        return axios
            .post(
                API_BASE + `/${i18n.locale}/api/v1/users/signup/`,
                payload.data,
            )
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err.response.data
            })
    },
    [USERS.REFRESH_TOKEN]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            let refreshObj = {
                refresh: JSON.parse(localStorage.getItem('refresh-token')),
            }
            if (!refreshObj.refresh) return
            axios
                .post(API_BASE + '/en/api/token/refresh/', refreshObj)
                .then(resp => {
                    axios.defaults.headers.common['Authorization'] =
                        'Bearer ' + resp.data.access
                    localStorage.setItem(
                        'token',
                        JSON.stringify(resp.data.access),
                    )
                    localStorage.setItem(
                        'refresh-token',
                        JSON.stringify(resp.data.refresh),
                    )
                    commit(USERS.SET_TOKENS, resp)
                    dispatch(USERS.GET_AUTH_USER)
                    resolve(resp)
                })
                .catch(err => {
                    dispatch(USERS.AUTH_LOGOUT)
                    reject(err)
                })
        })
    },
    [USERS.RESET_PASSWORD]: ({ commit }, payload) => {
        commit(FETCH_START)
        return axios
            .post(
                API_BASE + '/en/api/v1/users/users/reset-password/',
                payload.data,
            )
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err.response.data
            })
    },
    [USERS.AUTH_LOGOUT]: ({ commit }) => {
        return new Promise(resolve => {
            commit(USERS.SET_AUTH_LOGOUT)
            // axios.defaults.headers.credentials = false
            axios.defaults.headers.common['Authorization'] = ''
            // axios.defaults.headers.common['X-CSRFToken'] = ''
            localStorage.removeItem('token')
            localStorage.removeItem('refresh-token')
            localStorage.removeItem('csrf_token')
            localStorage.removeItem('feedback_posted')
            // Cookies.remove('user_session', {
            //     path: '/',
            //     domain: BASE_URL_DOMAIN,
            // })
            resolve()
        })
    },
    [USERS.LIST]: ({ commit }, payload) => {
        return UsersService.query(payload)
            .then(resp => {
                commit(USERS.SET_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [USERS.GET_USER]: ({ commit }, payload) => {
        commit(FETCH_START)
        return UsersService.get(payload)
            .then(resp => {
                commit(USERS.SET_USER, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [USERS.GET_ZAINERS]: ({ commit }, payload) => {
        // commit(FETCH_START)
        return UsersService.getZainers(payload)
            .then(resp => {
                commit(USERS.SET_ZAINERS, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [USERS.GET_ZAINER]: ({ commit }, payload) => {
        // commit(FETCH_START)
        return UsersService.getZainer(payload)
            .then(resp => {
                commit(USERS.SET_ZAINER, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [USERS.UPDATE_USER]: ({ commit }, payload) => {
        commit(FETCH_START)
        return UsersService.updateUser(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [USERS.SUBSCRIBE]: ({ commit }, payload) => {
        commit(FETCH_START)
        return axios
            .post(API_BASE + '/en/newsletters/subscriptions', payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [USERS.SUBMIT_FEEDBACK]: ({ commit }, payload) => {
        commit(FETCH_START)
        return axios
            .post(
                API_BASE + '/en/api/v1/settings/submit-feedback/',
                payload.submitData,
            )
            .then(resp => {
                // commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [USERS.GET_BADGES]: ({ commit }) => {
        return UsersService.getBadgesList()
            .then(resp => {
                commit(USERS.SET_BADGES, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [USERS.GET_OPCO_RANKING]: ({ commit }) => {
        return UsersService.getOpcoRanking()
            .then(resp => {
                commit(USERS.SET_OPCO_RANKING, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [USERS.GET_CAMPAIGNS]: ({ commit }) => {
        return UsersService.getCampaigns()
            .then(resp => {
                commit(USERS.SET_CAMPAIGNS, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
