export const CHAT = {
    LIST: 'getChat',
    MORE_LIST: 'getMoreChat',
    SET_LIST: 'setChat',
    SET_MORE_LIST: 'setMoreChat',
    FILE_UPLOAD: 'fileUpload',
    GET_CHAT_LIST: 'getChatList',
    SET_CHAT_LIST: 'setChatList',
    CEATE_CHAT: 'createChat',
    SATISTICS_CHAT: 'chatSatistics',
    SET_SATISTICS_CHAT: 'setChatSatistics',
}
