var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"vuejs-countdown"},[(!_vm.isSprintEnded)?[(_vm.days > 0)?_c('li',{style:([
                _vm.$i18n.locale == 'ar' ? { order: '4' } : { order: '1' } ])},[_c('p',{staticClass:"digit"},[_vm._v(_vm._s(_vm._f("twoDigits")(_vm.days)))]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.days > 1 ? _vm.$t('Days') : _vm.$t('Day')))])]):_vm._e(),_c('li',{style:([
                _vm.$i18n.locale == 'ar' ? { order: '3' } : { order: '2' } ])},[_c('p',{staticClass:"digit"},[_vm._v(_vm._s(_vm._f("twoDigits")(_vm.hours)))]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.hours > 1 ? _vm.$t('Hours') : _vm.$t('Hour')))])]),_c('li',{style:([
                _vm.$i18n.locale == 'ar' ? { order: '2' } : { order: '3' } ])},[_c('p',{staticClass:"digit"},[_vm._v(_vm._s(_vm._f("twoDigits")(_vm.minutes)))]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('Min')))])]),_c('li',{style:([
                _vm.$i18n.locale == 'ar' ? { order: '1' } : { order: '4' } ])},[_c('p',{staticClass:"digit"},[_vm._v(_vm._s(_vm._f("twoDigits")(_vm.seconds)))]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('Sec')))])])]:[_c('li',{style:([
                _vm.$i18n.locale == 'ar' ? { order: '3' } : { order: '1' } ])},[_c('p',{staticClass:"digit"},[_vm._v(_vm._s(_vm._f("twoDigits")(_vm.sprintEndDate.getDate())))]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('Day')))])]),_c('li',{style:([
                _vm.$i18n.locale == 'ar' ? { order: '2' } : { order: '2' } ])},[_c('p',{staticClass:"digit"},[_vm._v(" "+_vm._s(_vm._f("twoDigits")(_vm.sprintEndDate.toLocaleString('default', { month: 'short', })))+" ")]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('Month')))])]),_c('li',{style:([
                _vm.$i18n.locale == 'ar' ? { order: '1' } : { order: '3' } ])},[_c('p',{staticClass:"digit"},[_vm._v(" "+_vm._s(_vm._f("twoDigits")(_vm.sprintEndDate.getFullYear()))+" ")]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('Year')))])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }