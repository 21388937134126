export const NOTIFICATION = {
    LIST: 'getNotification',
    SET_LIST: 'setNotification',
    SEEN: 'seenNotification',
    LIST_COUNT: 'getNotificationCount',
    SET_LIST_COUNT: 'setNotificationCount',
}

export const SOCKET_MUTATIONS = {
    LIST: 'getNotification',
    SET_LIST: 'setNotification',
    SEEN: 'seenNotification',
    SOCKET_ONOPEN: 'SocketConnected',
    SOCKET_ONCLOSE: 'SocketDisconnected',
    SOCKET_ONERROR: 'SocketError',
    SOCKET_ONMESSAGE: 'WebsocketMessageReceived',
    SOCKET_RECONNECT: 'WebsocketReconnected',
    SOCKET_RECONNECT_ERROR: 'WebsocketReconnectError',
}
