import { MENTORSHIP } from './actions'

import { MentorshipService } from '@/common/api_services/mentorship'
import { FETCH_SUCCESS, FETCH_ERROR, FETCH_START } from '@/store/common'

const state = {
    mentorshipList: [],
    progressList: [],
    historyList: [],
    mentorshipObject: {},
    listTask: [],
    listNote: [],
    profile: {},
    listMyTask: [],
}

const getters = {
    mentorshipList: state => state.mentorshipList,
    mentorshipObject: state => state.mentorshipObject,
    listTask: state => state.listTask,
    listNote: state => state.listNote,
    profile: state => state.profile,
    listMyTask: state => state.listMyTask,
}

const mutations = {
    [MENTORSHIP.SET_LIST]: (state, resp) => {
        state.mentorshipList = resp.data
    },
    [MENTORSHIP.SET_OBJECT]: (state, resp) => {
        state.mentorshipObject = resp.data
    },
    [MENTORSHIP.SET_PROFILE]: (state, resp) => {
        state.profile = resp.data
    },
    [MENTORSHIP.SET_LIST_TASK]: (state, resp) => {
        state.listTask = resp.data
    },
    [MENTORSHIP.SET_LIST_NOTE]: (state, resp) => {
        state.listNote = resp.data
    },
    [MENTORSHIP.SET_LIST_MY_TASK]: (state, resp) => {
        state.listMyTask = resp.data
    },
}

const actions = {
    // MENTORSHIP actions
    [MENTORSHIP.LIST]: ({ commit }, payload) => {
        // commit(FETCH_START)
        return MentorshipService.query(payload)
            .then(resp => {
                commit(MENTORSHIP.SET_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [MENTORSHIP.GET]: ({ commit }, payload) => {
        commit(FETCH_START)
        return MentorshipService.get(payload)
            .then(resp => {
                commit(MENTORSHIP.SET_OBJECT, resp)
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(error => {
                commit(FETCH_ERROR)
                throw error
            })
    },
    [MENTORSHIP.UPDATE_MY_MENTORSHIP]: ({ commit }, payload) => {
        commit(FETCH_START)
        return MentorshipService.updateMyMentorship(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [MENTORSHIP.GET_PROFILE]: ({ commit }, payload) => {
        commit(FETCH_START)
        return MentorshipService.getProfile(payload)
            .then(resp => {
                commit(MENTORSHIP.SET_PROFILE, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [MENTORSHIP.LIST_TASK]: ({ commit }, payload) => {
        commit(FETCH_START)
        return MentorshipService.listTask(payload)
            .then(resp => {
                commit(MENTORSHIP.SET_LIST_TASK, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [MENTORSHIP.CREATE_TASK]: ({ commit }, payload) => {
        commit(FETCH_START)
        return MentorshipService.createTask(payload)
            .then(() => {
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [MENTORSHIP.LIST_MY_TASK]: ({ commit }, payload) => {
        return MentorshipService.listMyTask(payload)
            .then(resp => {
                commit(MENTORSHIP.SET_LIST_MY_TASK, resp)
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [MENTORSHIP.UPDATE_TASK]: ({ commit }, payload) => {
        commit(FETCH_START)
        return MentorshipService.updateTask(payload)
            .then(() => {
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [MENTORSHIP.LIST_NOTE]: ({ commit }, payload) => {
        commit(FETCH_START)
        return MentorshipService.listNote(payload)
            .then(resp => {
                commit(MENTORSHIP.SET_LIST_NOTE, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [MENTORSHIP.ADD_NOTE]: ({ commit }, payload) => {
        return MentorshipService.addNote(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
