import { isValidPhoneNumber } from 'libphonenumber-js'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import Vue from 'vue'

// vee validate rules
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule])
})

extend('required', {
    ...rules.required,
    message: 'This field is required',
})

extend('fullName', {
    validate: fullName => {
        // return /^[a-zA-Z]+(\s[a-zA-Z]+)+$/.test(fullName)
        return /^(\w|\W)+ (\w|\W)+$/.test(fullName)
    },
})

extend('password', {
    validate: password => {
        return /^(?=.*?[A-Z])(?=.*?[0-9]).{8,}$/.test(password)
    },
})

extend('phoneNumber', {
    validate(value, [phoneNumber]) {
        return isValidPhoneNumber(phoneNumber)
    },
})

extend('specialCharactersNotAllowed', {
    validate: value => {
        return /^[a-zA-Z0-9_\s\u0600-\u06FF-]+$/.test(value)
    },
})

extend('emailDomain', {
    validate: (email, domain) => {
        // eslint-disable-next-line no-useless-escape
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (re.test(email)) {
            if (domain && domain.length >= 2) {
                for (let i = 0; i < domain.length; i++) {
                    let checkEmail =
                        email.indexOf(
                            domain[i],
                            email.length - domain[i].length,
                        ) !== -1
                    if (checkEmail) return true
                }
                return false
            } else if (
                domain.length === 1 &&
                email.indexOf(domain[0], email.length - domain[0].length) !== -1
            ) {
                return true
            } else {
                return false
            }
        } else return false
    },
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
