import { ChatService } from '@/common/api_services/chat'
import { CHAT } from '@/store/actions/chat'
import { FETCH_SUCCESS, FETCH_ERROR } from '@/store/common'

const state = {
    chatList: [],
    moreChatList: [],
    getChatList: [],
    unreadChatCount: {},
}

const getters = {
    chatList: state => state.chatList,
    moreChatList: state => state.moreChatList,
    getChatList: state => state.getChatList,
    unreadChatCount: state => state.unreadChatCount,
}

const mutations = {
    [CHAT.SET_LIST]: (state, resp) => {
        state.chatList = resp.data
    },
    [CHAT.SET_MORE_LIST]: (state, resp) => {
        state.moreChatList = resp.data
    },
    [CHAT.SET_CHAT_LIST]: (state, resp) => {
        state.getChatList = resp.data
    },
    [CHAT.SET_SATISTICS_CHAT]: (state, resp) => {
        state.unreadChatCount = resp.data
    },
}

const actions = {
    [CHAT.LIST]: ({ commit }, payload) => {
        return ChatService.getChat(payload)
            .then(resp => {
                commit(CHAT.SET_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [CHAT.MORE_LIST]: ({ commit }, payload) => {
        return ChatService.getMoreChat(payload)
            .then(resp => {
                commit(CHAT.SET_MORE_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [CHAT.FILE_UPLOAD]: ({ commit }, payload) => {
        return ChatService.fileUpload(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [CHAT.GET_CHAT_LIST]: ({ commit }, payload) => {
        return ChatService.getChatList(payload)
            .then(resp => {
                commit(CHAT.SET_CHAT_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [CHAT.CEATE_CHAT]: ({ commit }, payload) => {
        return ChatService.createChat(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [CHAT.SATISTICS_CHAT]: ({ commit }, payload) => {
        return ChatService.chatSatistics(payload)
            .then(resp => {
                commit(CHAT.SET_SATISTICS_CHAT, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
