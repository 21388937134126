<template>
    <div>
        <div class="pageSections">
            <b-navbar
                v-b-scrollspy="{ offset: 320, method: 'auto' }"
                fixed="top"
                class="bg-faded"
            >
                <b-nav pills>
                    <b-nav-item
                        v-for="section in pageSections"
                        :key="section.id"
                        :href="'#' + section.id"
                        ><span>{{ section.title }}</span></b-nav-item
                    >
                </b-nav>
            </b-navbar>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pageSections: Array,
    },
    methods: {
        updateScroll() {
            let hh = 0
            let header = document.querySelector('.page-banner')
            if (header) {
                hh = header.clientHeight
            }
            let yp = hh - 420
            if (window.scrollY > yp) {
                document.body.classList.add('lightbg')
            } else {
                document.body.classList.remove('lightbg')
            }
        },
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll)
    },
}
</script>

<style lang="scss" scoped>
.pageSections {
    --linkcolor: #fff;
    .navbar {
        position: fixed;
        top: rem(350px);
        left: rem(30px);
        width: fit-content;
        .nav {
            display: flex;
            flex-direction: column;
            &-item {
                //border-left: solid 3px #8d8490;
                padding: 0px;
                a {
                    font-size: rem(18px);
                    font-weight: 700;
                    background: none;
                    text-transform: uppercase;
                    height: rem(46px);
                    position: relative;
                    display: inline-block;
                    padding: 0px;
                    padding-left: rem(20px);
                    @include flex(center, flex-start);
                    color: var(--linkcolor);
                    &:after {
                        position: absolute;
                        content: '';
                        width: 3px;
                        height: 100%;
                        background: #8d8490;
                        left: 0px;
                        top: 0px;
                    }
                    span {
                        opacity: 0;
                    }
                }
                &:not(:last-child) {
                    margin-bottom: 3px;
                }
                &.active {
                    a {
                        background: none;
                        span {
                            opacity: 1;
                            animation: fadeout 2s forwards;
                        }
                        &:after {
                            background: var(--linkcolor);
                        }
                    }
                }
                @media screen and (max-width: 1025px) {
                    &:hover {
                        a {
                            span {
                                opacity: 1 !important;
                            }
                        }
                    }
                }
            }
        }
        @keyframes fadeout {
            0% {
                opacity: 1;
            }
            75% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
        @media screen and (max-width: 1800px) {
            left: 0;
        }
    }
    @media screen and (max-width: 1199px) {
        display: none !important;
    }
}
body.lightbg {
    .pageSections {
        --linkcolor: var(--secondary);
    }
}

// ar style

.ar {
    .pageSections {
        .navbar {
            right: rem(30px);
            left: auto;
            .nav {
                &-item {
                    a {
                        padding: 0px;
                        padding-right: rem(20px);
                        &:after {
                            right: 0px;
                            left: auto;
                        }
                    }
                }
            }

            @media screen and (max-width: 1800px) {
                right: 0;
                left: auto;
            }
        }
    }
}
</style>
