var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fixed-header',{attrs:{"threshold":150}},[_c('header',{staticClass:"header",attrs:{"role":"Header"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"header--logo"},[_c('a',{on:{"click":function($event){return _vm.redirectToCMS(_vm.homeUrl)}}},[_c('img',{attrs:{"loading":"lazy","src":require("@/assets/images/logo.png"),"alt":""}})])]),_c('div',{staticClass:"header--links"},[_c('ul',{staticClass:"list-inline"},[_c('li',[_c('a',{staticClass:"search",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.searchOpen = !_vm.searchOpen}}},[_c('svg',{attrs:{"fill":"currentColor","height":"24","viewBox":"0 0 24 24","width":"24","xmlns":"http://www.w3.org/2000/svg"}},[_c('use',{attrs:{"xlink:href":"#header-search-icon"}})])])]),(_vm.userAuth && _vm.userAuth.id)?_c('li',{staticClass:"notification_dropdown"},[_c('a',{staticClass:"notification_icon",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.notificationOpenClike.apply(null, arguments)}}},[_c('svg',{attrs:{"id":"Capa_1","height":"512","viewBox":"0 0 512 512","width":"512","xmlns":"http://www.w3.org/2000/svg"}},[_c('use',{attrs:{"xlink:href":"#bell"}})]),(_vm.notificationCount)?_c('span',[_vm._v(_vm._s(_vm.notificationCount))]):_vm._e()]),_c('div',{staticClass:"notification",class:[
                                        _vm.notificationOpen ? 'active' : '' ],attrs:{"id":"notification-popup"}},[_c('div',{staticClass:"scrollWrapper",staticStyle:{"max-height":"300px"}},[_c('notifications')],1)])]):_vm._e(),(_vm.userAuth && _vm.userAuth.id)?_c('li',[_c('router-link',{staticClass:"user-login",attrs:{"to":{
                                        name: 'profile',
                                        params: {
                                            lang: _vm.$i18n.locale,
                                        },
                                    }}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 492.62 538.39"}},[_c('use',{attrs:{"xlink:href":"#header-user-icon"}})])])],1):_vm._e(),_vm._l((_vm.headersList),function(page,key){return _c('li',{key:key},[_c('a',{on:{"click":function($event){return _vm.redirectToCMS(
                                            page.url,
                                            page.new_tab
                                        )}}},[_c('img',{attrs:{"src":page.icon}})])])}),_c('li',{staticClass:"lang-selector"},[_c('a',{on:{"click":function($event){return _vm.changeLocale(_vm.otherLocale)}}},[_vm._v(_vm._s(_vm.otherLangText))])]),_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openMenu.apply(null, arguments)}}},[_c('svg',{attrs:{"fill":"currentColor","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","viewBox":"0 0 512 459.49"}},[_c('use',{attrs:{"xlink:href":"#header-menu-icon"}})])])])],2),(
                                _vm.$route.name !== 'login' &&
                                    _vm.$route.name !== 'submit-idea' &&
                                    _vm.$route.name !== 'resubmit-idea' &&
                                    _vm.$route.name !== 'edit-idea' &&
                                    !_vm.userAuth.is_mentor &&
                                    _vm.dashboardSettings &&
                                    _vm.dashboardSettings.dashboard_settings &&
                                    _vm.dashboardSettings.dashboard_settings
                                        .enable_idea_button
                            )?_c('router-link',{staticClass:"btn-idea",attrs:{"to":{
                                name: 'submit-idea',
                                params: {
                                    lang: _vm.$i18n.locale,
                                },
                            }}},[_c('svg',{attrs:{"fill":"#fff","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","viewBox":"0 0 459.89 512"}},[_c('use',{attrs:{"xlink:href":"#header-idea-icon"}})]),_c('span',[_vm._v(_vm._s(_vm.$t('ADD IDEA')))])]):_vm._e(),(_vm.userAuth && _vm.userAuth.id)?_c('div',{staticClass:"btn-idea on-mobile header-button-main enable-idea-button"},[(
                                    _vm.$route.name !== 'login' &&
                                        _vm.$route.name !== 'submit-idea' &&
                                        !_vm.userAuth.is_mentor &&
                                        _vm.dashboardSettings &&
                                        _vm.dashboardSettings.dashboard_settings &&
                                        _vm.dashboardSettings.dashboard_settings
                                            .enable_idea_button
                                )?_c('router-link',{staticClass:"header-button add-idea",attrs:{"to":{
                                    name: 'submit-idea',
                                    params: {
                                        lang: _vm.$i18n.locale,
                                    },
                                }}},[_c('span',[_c('svg',{attrs:{"fill":"#fff","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","viewBox":"0 0 459.89 512"}},[_c('use',{attrs:{"xlink:href":"#header-idea-icon"}})])]),_vm._v(" "+_vm._s(_vm.$t('ADD IDEA'))+" ")]):_vm._e(),(
                                    _vm.userAuth &&
                                        _vm.userAuth.id &&
                                        _vm.feedbackSettings &&
                                        _vm.feedbackSettings.form_fields
                                )?_c('a',{staticClass:"header-button feed-back",on:{"click":function($event){return _vm.$refs.feedbackForm.openForm()}}},[_vm._v(" "+_vm._s(_vm.feedbackSettings[ ("title_" + (_vm.$i18n.locale)) ])+" ")]):_vm._e()],1):_vm._e()],1)])])])]),_c('the-menu',{ref:"menu"}),_c('search',{attrs:{"searchOpen":_vm.searchOpen}}),(_vm.userAuth && _vm.userAuth.id)?_c('Feedback',{ref:"feedbackForm"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }