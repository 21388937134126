<template>
    <div>
        <div class="drop-zone">
            <div>
                <label class="control-label">{{ label }}</label>
            </div>
            <vue-dropzone
                v-if="dropzonBox"
                ref="vueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                :useCustomSlot="true"
                v-on:vdropzone-file-added="someFileAdde"
                v-on:vdropzone-success="someSuccessMethod"
                v-on:vdropzone-thumbnail="thumbnail"
            >
                <div
                    class="dropzone-custom-content"
                    :class="{ disabled: disabled }"
                >
                    <img
                        loading="lazy"
                        src="@/assets/images/svg/upload-icon.svg"
                        alt=""
                    />
                    <h3 class="dropzone-custom-title">
                        {{ $t('Drag and Drop your file in this area or') }}
                    </h3>
                    <div class="subtitle">{{ $t('Browse Files') }}</div>
                </div>
            </vue-dropzone>
        </div>
        <span
            class="invalid-feedback"
            :style="{ display: error ? 'block' : '' }"
            >{{ error }}</span
        >
        <draggable
            v-if="sortable"
            :list="uploadedFiles"
            :disabled="isWorkingOnDocument"
            class="filesList sortable"
            @change="updateListSortOrder"
        >
            <div
                class="filesList__items"
                v-for="(uploadedFile, k) in uploadedFiles"
                :key="k"
            >
                <div class="filesList__items--image">
                    <img
                        v-if="uploadedFile.filename.indexOf('pdf') > -1"
                        loading="lazy"
                        src="@/assets/images/svg/pdf2.svg"
                        alt=""
                    />
                    <img
                        v-else-if="
                            uploadedFile.filename.indexOf('mp4') > -1 ||
                                uploadedFile.filename.indexOf('webm') > -1
                        "
                        loading="lazy"
                        src="@/assets/images/svg/video.svg"
                        alt=""
                    />
                    <img v-else loading="lazy" :src="thumbnails[k]" alt="" />
                    <p>{{ uploadedFile.filename }}</p>
                </div>
                <base-input
                    v-if="showChangeName"
                    name="name"
                    :placeholder="$t('Please Enter file name')"
                    maxlength="50"
                    :infolabel="`50 ${$t('characters')}`"
                    :value="uploadedFile.name"
                    v-model="uploadedFile.name"
                    @input="$emit('changeFileName', $event, k)"
                    @onBlur="
                        $emit(
                            'onBlur',
                            $event && $event.target && $event.target.value
                                ? $event.target.value
                                : '',
                            k,
                        )
                    "
                />
                <div
                    v-if="!hideDeleteButton || !uploadedFile.created"
                    class="filesList__items--delete"
                    :class="{
                        'is-working-on-document': isWorkingOnDocument,
                    }"
                >
                    <a @click="deleteFile(k)">{{ $t('Delete') }}</a>
                </div>
            </div>
        </draggable>
        <div class="filesList" v-else>
            <div>
                <div
                    class="filesList__items"
                    v-for="(uploadedFile, k) in uploadedFiles"
                    :key="k"
                >
                    <div class="filesList__items--image">
                        <img
                            v-if="uploadedFile.filename.indexOf('pdf') > -1"
                            loading="lazy"
                            src="@/assets/images/svg/pdf2.svg"
                            alt=""
                        />
                        <img
                            v-else-if="
                                uploadedFile.filename.indexOf('mp4') > -1 ||
                                    uploadedFile.filename.indexOf('webm') > -1
                            "
                            loading="lazy"
                            src="@/assets/images/svg/video.svg"
                            alt=""
                        />
                        <img
                            v-else
                            loading="lazy"
                            :src="thumbnails[k]"
                            alt=""
                        />
                        <p>{{ uploadedFile.filename }}</p>
                    </div>
                    <base-input
                        v-if="showChangeName"
                        name="name"
                        :placeholder="$t('Please Enter file name')"
                        maxlength="50"
                        :infolabel="`50 ${$t('characters')}`"
                        :value="uploadedFile.name"
                        @input="$emit('changeFileName', $event, k)"
                        @onBlur="
                            $emit(
                                'onBlur',
                                $event && $event.target && $event.target.value
                                    ? $event.target.value
                                    : '',
                                k,
                            )
                        "
                    />
                    <div
                        v-if="!hideDeleteButton || !uploadedFile.created"
                        class="filesList__items--delete"
                        :class="{
                            'is-working-on-document': isWorkingOnDocument,
                        }"
                    >
                        <a @click="deleteFile(k)">{{ $t('Delete') }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_BASE } from '@/conf'
import vue2Dropzone from 'vue2-dropzone'
import draggable from 'vuedraggable'

import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
    name: 'app',
    props: {
        label: String,
        dropzonesettings: Object,
        limit: String,
        required: Boolean,
        name: String,
        error: String,
        existFiles: [Array],
        showChangeName: {
            type: Boolean,
            default: false,
        },
        isWorkingOnDocument: {
            type: Boolean,
            default: false,
        },
        hideDeleteButton: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        sortable: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        vueDropzone: vue2Dropzone,
        draggable,
    },
    data: function() {
        return {
            dropzoneOptions: {
                url: `${API_BASE}/en/hcfljiqzeb/`,
                paramName: 'file',
                thumbnailWidth: 150,
                addRemoveLinks: false,
                headers: {
                    'Cache-Control': null,
                    'X-Requested-With': null,
                },
                ...this.dropzonesettings,
            },
            uploadedFiles: [],
            thumbnails: [],
            dropzonBox: true,
            value: '',
        }
    },
    created() {
        this.setUploadedFiles()
        this.setlimit()
    },
    mounted() {
        if (this.disabled) {
            this.$refs.vueDropzone.disable()
        }
    },
    methods: {
        updateListSortOrder(value) {
            this.$emit('onSort', value.moved.oldIndex, value.moved.newIndex)
        },
        someSuccessMethod(file, response) {
            let tempResponse = response
            if (this.sortable) {
                tempResponse.sort_order = this.uploadedFiles.length
            }
            this.uploadedFiles.push(tempResponse)
            this.$refs.vueDropzone.removeAllFiles()
            this.setlimit()
            this.$emit('input', tempResponse)
        },
        async someFileAdde(file) {
            await this.$nextTick()
            if (file?.accepted) {
                this.$emit('fileAdde')
            }
        },
        deleteFile(k) {
            let fileId =
                this.uploadedFiles[k] && this.uploadedFiles[k].id
                    ? this.uploadedFiles[k].id
                    : 0
            this.uploadedFiles.splice(k, 1)
            this.thumbnails.splice(k, 1)

            this.setlimit()
            if (fileId) {
                this.$emit('deleteInput', fileId)
            } else {
                this.$emit('deleteInput', k)
            }
        },
        thumbnail: function(file, dataUrl) {
            this.thumbnails.push(dataUrl)
        },
        setlimit() {
            if (this.limit && this.uploadedFiles.length >= this.limit) {
                this.dropzonBox = false
            } else {
                this.dropzonBox = true
            }
        },
        getError(error) {
            if (this.$te(error)) {
                return this.$t(error)
            } else {
                return error
            }
        },
        setUploadedFiles() {
            if (this.existFiles) {
                this.existFiles.forEach(file => {
                    this.uploadedFiles.push(file)
                    this.thumbnails.push(file.path)
                })
            }
        },
    },
    watch: {
        existFiles: function() {
            this.value = this.existFiles.length ? 'exist' : ''
            this.setUploadedFiles()
            this.setlimit()
        },
    },
}
</script>

<style lang="scss" scoped>
/deep/.filesList {
    &__items {
        padding: rem(20px) 0 !important;
    }
}
/deep/.vue-dropzone {
    font-family: inherit !important;
}
.filesList {
    padding: 1.5625rem 0 6.25rem 0;
    &.sortable {
        cursor: grab;
    }
}
.drop-zone {
    .dropzone {
        border-radius: 0px;
        padding: rem(22px) rem(140px);
        border: dashed 2px #fdffd5;
        background-color: transparent;
        display: inline-block;
    }
    .control-label {
        font-size: rem(22px);
        color: var(--primary);
        font-weight: 700;
        padding-left: rem(20px);
        margin-bottom: rem(12px);
        text-align: left;
    }

    h1 {
        font-size: rem(22px);
        font-weight: 700;
        color: var(--primary);
        margin-bottom: rem(15px);
    }
    .dropzone-custom-content {
        svg {
            max-width: 35px;
            height: auto;
        }
        img {
            max-width: 35px;
            height: auto;
        }
        .dropzone-custom-title {
            font-size: rem(18px);
            color: #9c76a4;
            margin: rem(10px) 0 rem(20px) 0;
        }
        .subtitle {
            font-size: rem(16px);
            font-weight: 600;
            color: #9c76a4;
            border: 1px solid #976d9f;
            padding: rem(16px) rem(30px);
            display: inline-block;
            text-transform: uppercase;
            @media screen and (min-width: 1025px) {
                &:hover {
                    color: var(--secondary);
                    border-color: var(--secondary);
                }
            }
        }
        &.disabled {
            cursor: not-allowed;
            opacity: 0.6;
        }
    }
    @media screen and (max-width: 767px) {
        text-align: center;
        .dropzone {
            padding: rem(16px) rem(20px);
            width: 90%;
        }
    }
}
.filesList__items {
    &--image {
        img {
            width: 2.5rem;
        }
    }
    &--delete {
        padding-left: 1rem;
        &.is-working-on-document {
            pointer-events: none;
            opacity: 0.6;
        }
    }
}
.ar {
    .drop-zone {
        .control-label {
            padding-right: rem(20px);
            padding-left: 0;
            text-align: right;
        }
    }
}
</style>
