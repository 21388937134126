import { NotificationHelper } from '@/common/crud-helpers/notification'
import ideas from '@/modules/ideas/store'
import mentorship from '@/modules/mentorship/store'
import users from '@/modules/users/store'
import workshop from '@/modules/workshop/store'
import { SOCKET_MUTATIONS } from '@/store/actions/notification'
import { FETCH_SUCCESS, FETCH_ERROR, FETCH_START } from '@/store/common'
import activities from '@/store/modules/activities'
import chat from '@/store/modules/chat'
import events from '@/store/modules/events'
import notification from '@/store/modules/notification'
import settings from '@/store/modules/settings'
import { Loading } from 'notiflix'
import Vue from 'vue'
import Vuex from 'vuex'

var loader_svg = require('@/assets/images/zainiac_logo_grey.original.svg')
Vue.use(Vuex)
Loading.Init({
    customSvgUrl: loader_svg,
    svgSize: '135px',
    backgroundColor: '#ffffff',
    messageFontSize: '0px',
})
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        settings,
        notification,
        ideas,
        users,
        workshop,
        mentorship,
        events,
        activities,
        chat,
    },
    state: {
        socket: {
            isConnected: false,
            message: '',
            reconnectError: false,
        },
    },
    mutations: {
        [FETCH_START]: () => {
            Loading.Custom(`<div class="loader">xxxx</div>`)
            const elem = document.getElementsByClassName(
                'custom-loading-icon',
            )[0]
            elem.removeAttribute('alt')
        },
        [FETCH_SUCCESS]: () => {
            Loading.Remove()
        },
        [FETCH_ERROR]: () => {
            Loading.Remove()
        },
        [SOCKET_MUTATIONS.SOCKET_ONOPEN](state) {
            state.socket.isConnected = true
        },
        [SOCKET_MUTATIONS.SOCKET_ONCLOSE](state) {
            state.socket.isConnected = false
        },
        [SOCKET_MUTATIONS.SOCKET_ONERROR]() {},
        [SOCKET_MUTATIONS.SOCKET_ONMESSAGE](state, message) {
            state.socket.message = message
            NotificationHelper.getNotificationCount()
            NotificationHelper.getNotification({
                limit: 10,
                offset: 0,
            })
        },
        [SOCKET_MUTATIONS.SOCKET_RECONNECT]() {},
        [SOCKET_MUTATIONS.SOCKET_RECONNECT_ERROR](state) {
            state.socket.reconnectError = true
        },
    },
    actions: {
        sendMessage: function(context, message) {
            Vue.prototype.$socket.send(message)
        },
    },
    strict: debug,
})
