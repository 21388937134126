<template>
    <div class="mainNavigation">
        <a href="#" @click.stop.prevent="closeMenu" class="menuClose">
            <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 409.81 409.81"
            >
                <use xlink:href="#menu-close-icon"></use>
            </svg>
        </a>
        <nav>
            <ul>
                <li v-for="(menu, key) in menusList" :key="key">
                    <a @click="redirectToCMS(menu.url)">
                        <img loading="lazy" :src="menu.image_url" alt="" />
                        <span> {{ menu[`button_text_${$i18n.locale}`] }}</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { ActivitiesHelper } from '@/common/crud-helpers/activities'
import { API_BASE } from '@/conf'
import { gsap } from 'gsap'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            menus: [],
            api_base: API_BASE,
        }
    },
    methods: {
        closeMenu: function() {
            var m = document.querySelector('.mainNavigation')
            m.classList.remove('show')
            document.body.classList.remove('no-scroll')
        },
        menuAnimation: function() {
            var tl = gsap.timeline({
                paused: true,
            })
            tl.fromTo(
                'nav ul li',
                { y: 500 },
                {
                    y: 0,
                    delay: 0.2,
                    duration: 0.6,
                    ease: 'power1.out',
                    stagger: 0.1,
                },
            )
            tl.play()
        },
        redirectToCMS(redirectUrl) {
            if (
                localStorage.getItem('token') &&
                redirectUrl.includes(API_BASE)
            ) {
                ActivitiesHelper.cmsRedirectUrl({
                    API_BASE: API_BASE,
                    lang: this.$i18n.locale,
                    url: redirectUrl,
                })
                    .then(resp => {
                        window.location.href = JSON.parse(
                            resp.request.response,
                        ).url
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                window.location.href = redirectUrl
            }
        },
    },
    computed: {
        ...mapGetters(['navMenu', 'userAuth']),
        menusList() {
            return this.menus.filter(menu => {
                if (this.userAuth && this.userAuth.id) {
                    return menu.show === true
                } else {
                    return menu.login_required === false && menu.show === true
                }
            })
        },
    },
    watch: {
        $route() {
            this.closeMenu()
        },
        navMenu() {
            this.menus = cloneDeep(this.navMenu.results)
            this.menus.forEach(menu => {
                if (menu.external_url) {
                    menu.url = menu[`external_url_${this.$i18n.locale}`]
                } else if (menu.inner_page_url) {
                    menu.url = this.api_base + menu.inner_page_url
                } else {
                    menu.url = ''
                }
            })
        },
    },
    mounted() {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
}
</script>

<style lang="scss" scoped>
.mainNavigation {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    background: #fff;
    z-index: -9999;
    overflow-y: auto;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(0);
    min-height: -webkit-fill-available;
    .menuClose {
        position: absolute;
        top: 10%;
        right: 10%;
        color: #0c0c0c;
        svg {
            transition: all 0.5s ease;
            width: 29px;
            height: auto;
        }
        @media screen and (min-width: 1025px) {
            &:hover {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    nav {
        // height: 100%;
        max-width: 1120px;
        margin: auto;
        margin: rem(150px) auto rem(50px) auto;
        ul {
            @include flex(center, center);
            height: calc(100% + 25px);
            li {
                flex: 0 0 33.3%;
                max-width: 33.3%;
                list-style: none;
                margin: rem(30px) 0px;
                a {
                    font-size: rem(20px);
                    font-weight: 700;
                    color: #0c0c0c;
                    text-decoration: none;
                    span {
                        display: block;
                        padding-top: rem(10px);
                        line-height: 1;
                    }
                    img {
                        height: auto;
                        aspect-ratio: auto 350 / 200;
                        width: auto;
                    }
                    @media screen and (min-width: 1025px) {
                        &:hover {
                            opacity: 0.8;
                            color: var(--primary);
                        }
                    }
                }
            }
        }
    }
    &.show {
        opacity: 1;
        z-index: 9999;
        transform: scale(1);
    }
    @media screen and (max-width: 1199px) {
        nav {
            max-width: 990px;
            ul {
                justify-content: space-between;
                height: auto;
                li {
                    flex: 0 0 31.3%;
                    max-width: 31.3%;
                    a {
                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        height: calc(var(--vh, 1vh) * 100);
        nav {
            max-width: 100%;
            padding: 0 rem(20px);
            margin-top: rem(60px);
            padding-bottom: 100px;
            ul {
                li {
                    flex: 0 0 31.3%;
                    max-width: 31.3%;
                }
            }
        }
        .menuClose {
            top: 2%;
            right: 3%;
            svg {
                width: 20px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        nav {
            margin-bottom: rem(100px);
            ul {
                li {
                    max-width: 48%;
                    flex: 0 0 48%;
                }
            }
        }
    }
}

// ar style

.ar .mainNavigation .menuClose {
    left: 10%;
    right: auto;
}
.ar .mainNavigation nav ul li a {
    text-align: right;
    display: block;
}
.ar .mainNavigation {
    right: 0;
    left: auto;
}
</style>
