import { USERS } from '@/modules/users/store/actions'

import { ApiServiceHelper } from '../crud-helpers'

export const UsersHelper = {
    resetPassword(params) {
        return ApiServiceHelper.execAction(USERS.RESET_PASSWORD, params)
    },
    refreshToken(params) {
        return ApiServiceHelper.execAction(USERS.REFRESH_TOKEN, params)
    },
    loginUser(params) {
        return ApiServiceHelper.execAction(USERS.LOGIN_USER, params)
    },
    signupNewUser(params) {
        return ApiServiceHelper.execAction(USERS.SIGNUP_NEW_USER, params)
    },
    getAuthUser(params) {
        return ApiServiceHelper.execAction(USERS.GET_AUTH_USER, params)
    },
    list(params) {
        return ApiServiceHelper.execAction(USERS.LIST, params)
    },
    get(params) {
        return ApiServiceHelper.execAction(USERS.GET_USER, params)
    },
    getZainers(params) {
        return ApiServiceHelper.execAction(USERS.GET_ZAINERS, params)
    },
    getZainer(params) {
        return ApiServiceHelper.execAction(USERS.GET_ZAINER, params)
    },
    update(params) {
        return ApiServiceHelper.execAction(USERS.UPDATE_USER, params)
    },
    subscribe(params) {
        return ApiServiceHelper.execAction(USERS.SUBSCRIBE, params)
    },
    submitFeedBack(params) {
        return ApiServiceHelper.execAction(USERS.SUBMIT_FEEDBACK, params)
    },
    getBadgesList() {
        return ApiServiceHelper.execAction(USERS.GET_BADGES)
    },
    getOpcoRanking() {
        return ApiServiceHelper.execAction(USERS.GET_OPCO_RANKING)
    },
    getCampaigns() {
        return ApiServiceHelper.execAction(USERS.GET_CAMPAIGNS)
    },
}
