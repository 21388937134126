<template>
    <ul class="vuejs-countdown">
        <template v-if="!isSprintEnded">
            <li
                v-if="days > 0"
                :style="[
                    $i18n.locale == 'ar' ? { order: '4' } : { order: '1' },
                ]"
            >
                <p class="digit">{{ days | twoDigits }}</p>
                <p class="text">{{ days > 1 ? $t('Days') : $t('Day') }}</p>
            </li>
            <li
                :style="[
                    $i18n.locale == 'ar' ? { order: '3' } : { order: '2' },
                ]"
            >
                <p class="digit">{{ hours | twoDigits }}</p>
                <p class="text">{{ hours > 1 ? $t('Hours') : $t('Hour') }}</p>
            </li>
            <li
                :style="[
                    $i18n.locale == 'ar' ? { order: '2' } : { order: '3' },
                ]"
            >
                <p class="digit">{{ minutes | twoDigits }}</p>
                <p class="text">{{ $t('Min') }}</p>
            </li>
            <li
                :style="[
                    $i18n.locale == 'ar' ? { order: '1' } : { order: '4' },
                ]"
            >
                <p class="digit">{{ seconds | twoDigits }}</p>
                <p class="text">{{ $t('Sec') }}</p>
            </li>
        </template>
        <template v-else>
            <li
                :style="[
                    $i18n.locale == 'ar' ? { order: '3' } : { order: '1' },
                ]"
            >
                <p class="digit">{{ sprintEndDate.getDate() | twoDigits }}</p>
                <p class="text">{{ $t('Day') }}</p>
            </li>
            <li
                :style="[
                    $i18n.locale == 'ar' ? { order: '2' } : { order: '2' },
                ]"
            >
                <p class="digit">
                    {{
                        sprintEndDate.toLocaleString('default', {
                            month: 'short',
                        }) | twoDigits
                    }}
                </p>
                <p class="text">{{ $t('Month') }}</p>
            </li>
            <li
                :style="[
                    $i18n.locale == 'ar' ? { order: '1' } : { order: '3' },
                ]"
            >
                <p class="digit">
                    {{ sprintEndDate.getFullYear() | twoDigits }}
                </p>
                <p class="text">{{ $t('Year') }}</p>
            </li>
        </template>
    </ul>
</template>

<script>
let interval = null
export default {
    name: 'vuejsCountDown',
    props: {
        deadline: {
            type: String,
        },
        end: {
            type: String,
        },
        stop: {
            type: Boolean,
        },
        endDate: {
            type: String,
        },
    },
    data() {
        return {
            now: Math.trunc(new Date().getTime() / 1000),
            date: null,
            diff: 0,
            isSprintEnded: false,
            sprintEndDate: new Date(this.endDate),
        }
    },
    created() {
        if (!this.deadline && !this.end) {
            throw new Error("Missing props 'deadline' or 'end'")
        }
        let endTime = this.deadline ? this.deadline : this.end
        this.date = Math.trunc(Date.parse(endTime.replace(/-/g, '/')) / 1000)
        if (!this.date) {
            throw new Error(
                "Invalid props value, correct the 'deadline' or 'end'",
            )
        }
        interval = setInterval(() => {
            this.now = Math.trunc(new Date().getTime() / 1000)
        }, 1000)
    },
    computed: {
        seconds() {
            return Math.trunc(this.diff) % 60
        },
        minutes() {
            return Math.trunc(this.diff / 60) % 60
        },
        hours() {
            return Math.trunc(this.diff / 60 / 60) % 24
        },
        days() {
            return Math.trunc(this.diff / 60 / 60 / 24)
        },
    },
    watch: {
        now() {
            this.diff = this.date - this.now
            if (this.diff <= 0 || this.stop) {
                this.diff = 0
                this.isSprintEnded = true
                // Remove interval
                clearInterval(interval)
            }
        },
    },
    filters: {
        twoDigits(value) {
            if (value.toString().length <= 1) {
                return '0' + value.toString()
            }
            return value.toString()
        },
    },
    destroyed() {
        clearInterval(interval)
    },
}
</script>
<style lang="scss">
.vuejs-countdown {
    padding: 0;
    margin: 0;
}
.vuejs-countdown li {
    display: inline-block;
    margin: 0 8px;
    text-align: center;
    position: relative;
}
.vuejs-countdown li p {
    margin: 0;
}
.vuejs-countdown li:after {
    content: ':';
    position: absolute;
    top: 0;
    right: -13px;
    font-size: 32px;
}
.vuejs-countdown li:first-of-type {
    margin-left: 0;
}
.vuejs-countdown li:last-of-type {
    margin-right: 0;
}
.vuejs-countdown li:last-of-type:after {
    content: '';
}
.vuejs-countdown .digit {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 0;
}
.vuejs-countdown .text {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 10px;
}
.ar {
    .vuejs-countdown li:first-of-type {
        margin-left: 8px;
        margin-right: 0;
    }
    .vuejs-countdown li:last-of-type {
        margin-left: 0;
        margin-right: 8px;
    }
}
</style>
