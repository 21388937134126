import { ApiService } from '@/common/api_services'
export const MentorshipService = {
    query(params) {
        return ApiService.query('/mentorship/my-mentorship/', { params })
    },
    get(pk) {
        return ApiService.get('mentorship/my-mentorship', pk)
    },
    updateMyMentorship({ uuid, data }) {
        return ApiService.put(`mentorship/my-mentorship/${uuid}/`, data)
    },
    getProfile() {
        return ApiService.query('mentorship/profile/')
    },
    createTask(params) {
        return ApiService.post(`/mentorship/task/${params.uuid}/`, params)
    },
    listTask(params) {
        return ApiService.query(`/mentorship/task/${params.uuid}/`, { params })
    },
    listMyTask(params) {
        return ApiService.query(`/mentorship/my-tasks/`, { params })
    },
    updateTask({ uuid, id, data }) {
        // return ApiService.update(`/mentorship/task/${uuid}/`, id, data)
        return ApiService.put(`/mentorship/task/${uuid}/${id}/`, data)
    },
    listNote(params) {
        return ApiService.query(`/mentorship/note/${params.uuid}/`, { params })
    },
    addNote(params) {
        return ApiService.post(`/mentorship/note/${params.uuid}/`, params)
    },
}
