import { ApiService } from '@/common/api_services'
export const UsersService = {
    getAuthUser() {
        return ApiService.query('users/profile/')
    },
    query(params) {
        return ApiService.query('users/users/', { params })
    },
    get(username) {
        return ApiService.get('users/users', username)
    },
    getZainers(params) {
        return ApiService.query('users/zainers/', { params })
    },
    getZainer(params) {
        return ApiService.query(`users/zainers/${params}/`)
    },
    updateUser(params) {
        return ApiService.put(`users/profile/${params.username}/`, params)
    },
    getBadgesList() {
        return ApiService.query('users/my-badges/')
    },
    getOpcoRanking() {
        return ApiService.query('users/ranking-by-opco/')
    },
    getCampaigns() {
        return ApiService.query('users/campaigns/')
    },
}
