<template>
    <div>
        <div class="userProfile">
            <div class="userProfile__left">
                <div class="userProfile__left--image">
                    <img
                        v-if="userData.photo"
                        loading="lazy"
                        :src="userData.photo"
                        alt=""
                    />
                </div>
                <div class="userProfile__left--name">
                    <h1>{{ userData.display_name }}</h1>
                    <!-- cog icon redirect to profile-settings -->
                    <!-- <router-link
                        v-if="userRole == 'user'"
                        class="userProfile__left--setting"
                        :to="{
                            name: 'profile-settings',
                        }"
                    >
                        <svg
                            fill="currentColor"
                            enable-background="new 0 0 512 512"
                            height="512"
                            viewBox="0 0 512 512"
                            width="512"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <use xlink:href="#userprofile-setting"></use>
                        </svg>
                    </router-link> -->
                </div>
                <div class="userProfile__left--designation">
                    {{ userData.title }}
                </div>
                <base-user-level :userData="userData" />
            </div>
            <div class="userProfile__right">
                <div>
                    <div class="userProfile__right--message">
                        <a v-if="!userLogin" :href="`mailto:${userData.email}`">
                            <svg
                                fill="currentColor"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 512 512"
                                style="enable-background:new 0 0 512 512;"
                                xml:space="preserve"
                            >
                                <use xlink:href="#card-message-icon"></use>
                            </svg>
                            {{ $t('Email') }}
                        </a>
                    </div>
                    <div class="row">
                        <div class="col-7">
                            <!-- <ul class="list-inline list-unstyled">
                                <li>
                                    {{ $t('Division') }}
                                </li>
                                <li>
                                    {{ userData.division }}
                                </li>
                            </ul> -->
                            <ul class="list-inline list-unstyled">
                                <li>
                                    {{ $t('Phone') }}
                                </li>
                                <li>
                                    {{ userData.phone }}
                                </li>
                            </ul>
                            <ul class="list-inline list-unstyled">
                                <li>
                                    {{ $t('Opco') }}
                                </li>
                                <li>
                                    {{ userData.country }}
                                </li>
                            </ul>
                            <ul class="list-inline list-unstyled">
                                <li>
                                    {{ $t('Experience') }}
                                </li>
                                <li>
                                    {{ userData.experience }}
                                </li>
                            </ul>
                        </div>
                        <div class="col-5">
                            <ul
                                class="list-inline list-unstyled"
                                v-if="userRole == 'mentor'"
                            >
                                <li>
                                    {{ $t('User Role') }}
                                </li>
                                <li>
                                    {{ $t('Mentor') }}
                                </li>
                            </ul>
                            <!-- <ul class="list-inline list-unstyled">
                                <li>
                                    {{ $t('Active Ideas') }}
                                </li>
                                <li>
                                    {{ userData.my_active_ideas }}
                                </li>
                            </ul> -->
                            <ul
                                class="list-inline list-unstyled"
                                v-if="userRole == 'mentor'"
                            >
                                <li>
                                    {{ $t('Assigned Ideas') }}
                                </li>
                                <li>
                                    {{ userData.assigend_mentored_idea }}
                                </li>
                            </ul>
                            <ul
                                class="list-inline list-unstyled"
                                v-if="userRole == 'user'"
                            >
                                <li>
                                    {{ $t('Approved ideas') }}
                                </li>
                                <li>
                                    {{ userData.my_approved_idea }}
                                </li>
                            </ul>
                            <ul
                                class="list-inline list-unstyled"
                                v-if="userRole == 'user'"
                            >
                                <li>
                                    {{ $t('Dismissed ideas') }}
                                </li>
                                <li>
                                    {{ userData.my_dismissed_idea }}
                                </li>
                            </ul>
                            <ul
                                class="list-inline list-unstyled"
                                v-if="userRole == 'user'"
                            >
                                <li>
                                    {{ $t('Submitted ideas') }}
                                </li>
                                <li>
                                    {{ userData.my_submitted_idea }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        userData: Object,
        userRole: String,
        userLogin: Boolean,
    },
}
</script>

<style lang="scss" scoped>
.userProfile {
    @include flex(flex-end, space-between);
    margin-bottom: rem(100px);
    &__left {
        flex: 0 0 40%;
        max-width: 40%;
        &--image {
            width: 140px;
            height: 140px;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: rem(38px);
            img {
                width: 100%;
                height: 100%;
            }
        }
        &--name {
            position: relative;
            h1 {
                color: #009b74;
                font-size: rem(36px);
                @media screen and (max-width: 991px) {
                    font-size: rem(28px);
                }
            }
        }
        &--designation {
            color: #fff;
            font-size: rem(24px);
        }
        &--setting {
            position: absolute;
            right: rem(128px);
            @include center-vertical();
            color: #fff;
            svg {
                width: 22px;
                height: auto;
                transition: all 0.6s ease;
            }
            @media screen and (min-width: 1025px) {
                &:hover {
                    color: #fff;
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    &__right {
        flex: 0 0 60%;
        max-width: 60%;
        ul {
            flex-wrap: nowrap;
            &:not(:last-child) {
                margin-bottom: rem(15px);
            }
            li {
                font-size: rem(18px);
                color: #fff;
                text-align: left;
                flex: 0 0 160px;
                max-width: 160px;
                &:last-child {
                    font-weight: 900;
                    font-size: rem(20px);
                    color: var(--secondary);
                    flex: 1;
                    max-width: none;
                    text-align: revert;
                }
            }
        }
        &--message {
            --border-color: #fff;
            text-align: right;
            padding-bottom: rem(60px);
            a {
                display: inline-block;
                color: rgba(256, 256, 256, 0.7);
                border: solid 1px var(--border-color);
                border-left: 0px;
                position: relative;
                text-transform: uppercase;
                font-weight: 700;
                font-size: rem(16px);
                padding: rem(12px) rem(35px);
                svg {
                    width: 23px;
                    height: auto;
                    left: -10%;
                    position: absolute;
                    @include center-vertical();
                    opacity: 1;
                    color: var(--border-color);
                }
                &:before,
                &:after {
                    content: '';
                    width: 2px;
                    height: 4px;
                    display: block;
                    background: var(--border-color);
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                &:after {
                    top: auto;
                    bottom: 0;
                }
                @media screen and (min-width: 1025px) {
                    &:hover {
                        --border-color: var(--secondary);
                        color: var(--secondary);
                        text-decoration: none;
                        svg {
                            color: var(--secondary);
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        &__right {
            flex: 0 0 67%;
            max-width: 67%;
            ul {
                li {
                    flex: 0 0 125px;
                    max-width: 125px;
                }
            }
        }
        &__left {
            flex: 0 0 33%;
            max-width: 33%;
            &--image {
                width: 120px;
                height: 120px;
            }
            &--setting {
                right: rem(30px);
            }
        }
    }
    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        &__left {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
            text-align: center;
            padding-bottom: rem(20px);
            &--image {
                margin: auto auto rem(16px) auto;
            }
        }
        &__right {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
            &--message {
                text-align: center;
            }
            ul {
                li {
                    &:last-child {
                        font-size: rem(17px);
                    }
                }
            }
        }
    }
    @media screen and (max-width: 500px) {
        &__right {
            .row {
                flex-direction: column;
                max-width: 320px;
                margin: auto;
                > div {
                    flex: 0 0 100%;
                    max-width: 100%;
                    &:first-child {
                        margin-bottom: rem(20px);
                    }
                }
            }
            .d-flex {
                flex-direction: column;
                max-width: 265px;
                margin: auto;
                .flex-grow-1 {
                    margin-bottom: rem(20px);
                }
                .col-4 {
                    padding: 0;
                }
            }
        }
    }
}

// ar style

.ar {
    .userProfile {
        &__left {
            &--setting {
                left: rem(128px);
                right: auto;
            }
        }
        &__right {
            ul {
                li {
                    text-align: right;
                    &:last-child {
                        text-align: revert;
                    }
                }
            }
            &--message {
                text-align: left;
                a {
                    border: solid 1px var(--border-color);
                    border-right: 0px;
                    svg {
                        right: -10%;
                        left: auto;
                    }
                    &:before,
                    &:after {
                        content: '';
                        top: 0;
                        right: 0;
                        left: auto;
                    }
                }
            }
        }
        @media screen and (max-width: 991px) {
            &__left {
                &--setting {
                    left: rem(30px);
                    right: auto;
                }
            }
        }
        @media screen and (max-width: 767px) {
            &__left {
                text-align: center;
                &--image {
                    margin: auto auto rem(16px) auto;
                }
            }
            &__right {
                &--message {
                    text-align: center;
                }
            }
        }
    }
}
</style>
