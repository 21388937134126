import { ApiService } from '@/common/api_services'

export const ActivitiesService = {
    query(params) {
        return ApiService.query('activity/my-activity/', { params })
    },
    post(params) {
        return ApiService.post(
            `${params.API_BASE}/${params.lang}/token-login-redirect/?url=${params.url}`,
        )
    },
}
