<template>
    <svg
        v-if="name == 'toogle-btn'"
        fill="#fff"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="12.969"
        height="21"
        viewBox="0 0 12.969 21"
    >
        <defs>
            <filter
                id="filter"
                x="693"
                y="516"
                width="12.969"
                height="21"
                filterUnits="userSpaceOnUse"
            >
                <feFlood result="flood" flood-color="#fff" />
                <feComposite
                    result="composite"
                    operator="in"
                    in2="SourceGraphic"
                />
                <feBlend result="blend" in2="SourceGraphic" />
            </filter>
        </defs>
        <path
            id="Forma_1"
            data-name="Forma 1"
            class="cls-1"
            d="M693.465,527.535l9.791,9.026a1.69,1.69,0,0,0,2.255,0,1.391,1.391,0,0,0,0-2.079l-8.664-7.987,8.664-7.987a1.391,1.391,0,0,0,0-2.079,1.692,1.692,0,0,0-2.255,0l-9.791,9.027A1.39,1.39,0,0,0,693.465,527.535Z"
            transform="translate(-693 -516)"
        />
    </svg>
    <div class="icon" v-else>
        <svg
            v-if="name == 'ideas'"
            version="1.1"
            fill="currentColor"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 452.04 512"
            style="enable-background:new 0 0 452.04 512;"
            xml:space="preserve"
        >
            <g>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <path
                    class="st0"
                    d="M226.15,83c-0.35,0-0.69,0-1.04,0c-41.12,0.29-80.22,18.45-107.28,49.81c-3.61,4.18-3.14,10.5,1.04,14.1
                                        c4.18,3.61,10.5,3.14,14.1-1.04c23.29-27,56.92-42.63,92.28-42.88c0.3,0,0.6,0,0.9,0c32.7,0,63.45,12.67,86.67,35.72
                                        c23.44,23.27,36.34,54.27,36.34,87.3c0,31.54-11.92,61.53-33.57,84.45c-22.73,24.06-36.07,54.52-38.07,86.53h-19.5V291h6.33
                                        c14.52,0,26.33-11.81,26.33-26.33v-1.33c0-14.52-11.81-26.33-26.33-26.33c-14.52,0-26.33,11.81-26.33,26.33v7.67h-24v-7.67
                                        c0-14.52-11.81-26.33-26.33-26.33H186c-14.52,0-26.33,11.81-26.33,26.33v1.33c0,14.52,11.81,26.33,26.33,26.33h8.03v106h-19.26
                                        c-1.94-32.37-15.01-62.54-37.31-85.72c-23.02-23.93-35.2-55.44-34.3-88.7c0.15-5.52-4.2-10.12-9.72-10.27
                                        c-5.54-0.19-10.12,4.2-10.27,9.72c-1.05,38.66,13.11,75.28,39.88,103.11c20.62,21.43,31.97,49.95,31.97,80.3V407v27.38
                                        c0,21.39,16.22,39.05,37.01,41.36V478c0,18.75,15.25,34,34,34c18.75,0,34-15.25,34-34v-2.23c20.89-2.2,37.23-19.92,37.23-41.38V407
                                        v-1.55c0-29.95,11.67-58.8,32.87-81.25c25.17-26.65,39.03-61.51,39.03-98.18c0-38.39-15-74.44-42.25-101.49
                                        C299.91,97.72,264.16,83,226.15,83 M258.02,263.34c0-3.49,2.84-6.33,6.33-6.33c3.49,0,6.33,2.84,6.33,6.33v1.33
                                        c0,3.49-2.84,6.33-6.33,6.33h-6.33V263.34z M186,271.01c-3.49,0-6.33-2.84-6.33-6.33v-1.33c0-3.49,2.84-6.33,6.33-6.33h1.69
                                        c3.49,0,6.33,2.84,6.33,6.33v7.67H186z M214.02,291h24v106h-24V291z M240.02,478c0,7.72-6.28,14-14,14c-7.72,0-14-6.28-14-14v-2h28
                                        V478z M277.25,434.38c0,11.92-9.7,21.62-21.62,21.62h-5.62h-48h-5.39c-11.92,0-21.62-9.7-21.62-21.62V417h102.24V434.38z"
                />
                <path
                    class="st0"
                    d="M226.02,57.34c5.52,0,10-4.48,10-10V10c0-5.52-4.48-10-10-10c-5.52,0-10,4.48-10,10v37.34
                                        C216.02,52.87,220.5,57.34,226.02,57.34"
                />
                <path
                    class="st0"
                    d="M442.04,216.02H404.7c-5.52,0-10,4.48-10,10c0,5.52,4.48,10,10,10h37.34c5.52,0,10-4.48,10-10
                                        C452.04,220.5,447.56,216.02,442.04,216.02"
                />
                <path
                    class="st0"
                    d="M47.34,216.02H10c-5.52,0-10,4.48-10,10c0,5.52,4.48,10,10,10h37.34c5.52,0,10-4.48,10-10
                                        C57.34,220.5,52.87,216.02,47.34,216.02"
                />
                <path
                    class="st0"
                    d="M99.68,109.68c2.56,0,5.12-0.98,7.07-2.93c3.9-3.9,3.9-10.24,0-14.14L80.34,66.2c-3.91-3.9-10.24-3.9-14.14,0
                                        c-3.9,3.9-3.9,10.24,0,14.14l26.4,26.41C94.56,108.7,97.12,109.68,99.68,109.68"
                />
                <path
                    class="st0"
                    d="M371.7,66.2l-26.41,26.41c-3.91,3.9-3.91,10.24,0,14.14c1.95,1.95,4.51,2.93,7.07,2.93
                                        c2.56,0,5.12-0.98,7.07-2.93l26.41-26.41c3.9-3.9,3.9-10.24,0-14.14C381.93,62.3,375.6,62.3,371.7,66.2"
                />
                <path
                    class="st0"
                    d="M282.78,142.49c-16.83-11.49-36.51-17.56-56.95-17.56c-0.24,0-0.49,0-0.73,0c-5.52,0.04-9.97,4.55-9.93,10.07
                                        c0.04,5.5,4.51,9.93,10,9.93h0.07c0.2,0,0.4,0,0.59,0c33.3,0,62.79,19.89,75.25,50.81c1.57,3.9,5.32,6.27,9.28,6.27
                                        c1.24,0,2.51-0.24,3.73-0.73c5.12-2.06,7.6-7.89,5.54-13.01C312.14,169.66,299.4,153.83,282.78,142.49"
                />
                <path
                    class="st0"
                    d="M309.85,218.95c-1.86,1.86-2.92,4.44-2.92,7.07c0,2.63,1.06,5.21,2.92,7.07c1.86,1.86,4.44,2.93,7.08,2.93
                                        c2.63,0,5.21-1.07,7.07-2.93c1.86-1.86,2.92-4.44,2.92-7.07c0-2.63-1.06-5.21-2.92-7.07c-1.86-1.86-4.44-2.93-7.07-2.93
                                        C314.29,216.02,311.71,217.09,309.85,218.95"
                />
                <path
                    class="st0"
                    d="M96.45,167.43c-1.86,1.87-2.93,4.44-2.93,7.08c0,2.63,1.07,5.2,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93
                                        c2.63,0,5.21-1.07,7.07-2.93c1.86-1.87,2.93-4.44,2.93-7.07c0-2.64-1.07-5.21-2.93-7.08c-1.86-1.86-4.44-2.92-7.07-2.92
                                        C100.89,164.51,98.31,165.57,96.45,167.43"
                />
            </g>
        </svg>
        <svg
            v-if="name == 'workshops'"
            version="1.1"
            fill="currentColor"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 384 384"
            style="enable-background:new 0 0 384 384;"
            xml:space="preserve"
        >
            <g>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <path
                    class="st0"
                    d="M192,160h-0.31c-2.4-0.1-4.66-1.28-6.09-3.2L164,128h-44c-13.23,0-24-10.77-24-24V24c0-13.23,10.77-24,24-24
                                h144c13.23,0,24,10.77,24,24v80c0,13.23-10.77,24-24,24h-40.37l-25.62,29.27C196.5,159.01,194.3,160,192,160 M120,16
                                c-4.41,0-8,3.59-8,8v80c0,4.41,3.59,8,8,8h48c2.51,0,4.89,1.18,6.4,3.2l18.08,24.1l21.5-24.58c1.52-1.73,3.71-2.72,6.02-2.72h44
                                c4.41,0,8-3.59,8-8V24c0-4.41-3.59-8-8-8H120z"
                />
                <path
                    class="st0"
                    d="M320,176h-0.32c-2.41-0.1-4.66-1.28-6.09-3.21l-12.64-16.96c-2.64-3.55-1.91-8.56,1.63-11.2
                                c3.54-2.66,8.55-1.92,11.2,1.63l6.72,9.02l21.47-24.55c1.54-1.74,3.73-2.73,6.03-2.73h12c4.41,0,8-3.59,8-8V40c0-4.41-3.59-8-8-8
                                h-32c-4.41,0-8-3.59-8-8s3.59-8,8-8h32c13.23,0,24,10.77,24,24v80c0,13.23-10.77,24-24,24h-8.37l-25.62,29.27
                                C324.5,175.01,322.3,176,320,176"
                />
                <path
                    class="st0"
                    d="M64,176c-2.31,0-4.5-0.99-6.02-2.73L32.37,144H24c-13.23,0-24-10.77-24-24V40c0-13.23,10.77-24,24-24h32
                                c4.41,0,8,3.59,8,8s-3.59,8-8,8H24c-4.41,0-8,3.59-8,8v80c0,4.41,3.59,8,8,8h12c2.31,0,4.5,0.99,6.02,2.73l21.47,24.55l6.72-9.02
                                c2.64-3.54,7.66-4.27,11.2-1.63c3.54,2.64,4.27,7.65,1.63,11.2L70.4,172.79c-1.42,1.93-3.68,3.12-6.08,3.21H64z"
                />
                <path
                    class="st0"
                    d="M320,272c-17.65,0-32-14.35-32-32s14.35-32,32-32c17.65,0,32,14.35,32,32S337.65,272,320,272 M320,224
                                c-8.82,0-16,7.18-16,16c0,8.82,7.18,16,16,16c8.82,0,16-7.18,16-16C336,231.18,328.82,224,320,224"
                />
                <path
                    class="st0"
                    d="M376,352c-4.41,0-8-3.59-8-8v-16c0-13.23-10.77-24-24-24h-48c-4.41,0-8-3.59-8-8s3.59-8,8-8h48
                                c22.05,0,40,17.95,40,40v16C384,348.41,380.41,352,376,352"
                />
                <path
                    class="st0"
                    d="M64,272c-17.65,0-32-14.35-32-32s14.35-32,32-32s32,14.35,32,32S81.65,272,64,272 M64,224
                                c-8.82,0-16,7.18-16,16c0,8.82,7.18,16,16,16s16-7.18,16-16C80,231.18,72.82,224,64,224"
                />
                <path
                    class="st0"
                    d="M8,352c-4.41,0-8-3.59-8-8v-16c0-22.05,17.95-40,40-40h48c4.41,0,8,3.59,8,8s-3.59,8-8,8H40
                                c-13.23,0-24,10.77-24,24v16C16,348.41,12.41,352,8,352"
                />
                <path
                    class="st0"
                    d="M192,280c-26.47,0-48-21.54-48-48s21.53-48,48-48s48,21.54,48,48S218.46,280,192,280 M192,200
                                c-17.65,0-32,14.35-32,32s14.35,32,32,32s32-14.35,32-32S209.65,200,192,200"
                />
                <path
                    class="st0"
                    d="M272,384c-4.41,0-8-3.59-8-8v-32c0-13.23-10.77-24-24-24h-96c-13.23,0-24,10.77-24,24v32c0,4.41-3.59,8-8,8
                                s-8-3.59-8-8v-32c0-22.05,17.95-40,40-40h96c22.05,0,40,17.95,40,40v32C280,380.41,276.41,384,272,384"
                />
            </g>
        </svg>
        <svg
            v-if="name == 'mentorship'"
            version="1.1"
            fill="currentColor"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 372 372"
            style="enable-background:new 0 0 372 372;"
            xml:space="preserve"
        >
            <g>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <path
                    class="st0"
                    d="M240,156c-46.32,0-84,37.68-84,84c0,46.32,37.68,84,84,84s84-37.68,84-84C324,193.68,286.32,156,240,156
                                M311.69,234h-24.12c-2.71-21.67-19.91-38.86-41.59-41.59V168.3C280.91,171.2,308.8,199.09,311.69,234 M240,276
                                c-19.86,0-36-16.15-36-36c0-19.86,16.14-36,36-36c19.85,0,36,16.14,36,36C276,259.86,259.85,276,240,276 M234,168.31v24.12
                                c-21.67,2.71-38.86,19.91-41.59,41.59H168.3C171.2,199.09,199.09,171.2,234,168.31 M168.3,246h24.12
                                c2.71,21.67,19.91,38.86,41.59,41.59v24.11C199.09,308.8,171.2,280.91,168.3,246 M246,311.7v-24.12
                                c21.67-2.71,38.86-19.91,41.59-41.59h24.11C308.8,280.91,280.91,308.8,246,311.7"
                />
                <path
                    class="st0"
                    d="M240,216c-13.23,0-24,10.77-24,24c0,13.23,10.77,24,24,24c13.23,0,24-10.77,24-24
                                C264,226.77,253.23,216,240,216 M240,252c-6.62,0-12-5.38-12-12c0-6.62,5.38-12,12-12c6.62,0,12,5.38,12,12
                                C252,246.62,246.62,252,240,252"
                />
                <path
                    class="st0"
                    d="M356.54,210c-7.32,0-13.84-4.36-16.77-11.43c-2.85-6.81-1.33-14.56,3.85-19.75l10.95-10.94l-42.45-42.45
                                l-10.94,10.95c-5.18,5.19-12.94,6.7-20.06,3.73c-6.75-2.8-11.12-9.32-11.12-16.64V108h-60v15.46c0,7.32-4.37,13.84-11.43,16.77
                                c-6.82,2.85-14.56,1.33-19.75-3.85l-10.94-10.95l-7.6,7.6c-9.71-21.09-27.87-37.5-50.16-44.82C123.27,79.65,132,64.83,132,48
                                c0-26.46-21.54-48-48-48S36,21.54,36,48c0,16.83,8.73,31.65,21.88,40.21C24.32,99.23,0,130.81,0,168v24h52.36
                                c-10,8.8-16.36,21.66-16.36,36c0,16.83,8.73,31.65,21.88,40.21C24.32,279.23,0,310.8,0,348v24h168v-17.55l10.82-10.83
                                c5.19-5.18,12.95-6.69,20.06-3.73c6.75,2.8,11.12,9.33,11.12,16.64V372h60v-15.46c0-7.32,4.36-13.84,11.43-16.77
                                c6.81-2.86,14.55-1.33,19.75,3.85l10.94,10.95l42.45-42.45l-10.95-10.95c-5.18-5.19-6.7-12.94-3.73-20.06
                                c2.8-6.75,9.33-11.12,16.64-11.12H372v-60H356.54z M156,168v12H94.34l37.51-65.64C146.63,127.55,156,146.68,156,168 M115.65,192
                                h25.94c-0.07,2.27-0.52,4.57-1.48,6.88c-2.15,5.19-6.6,8.98-11.89,10.43C125.38,202.62,121.03,196.74,115.65,192 M60.41,100.05
                                c1.09,6.35,4.66,11.84,9.7,15.45l-5.6,24.29l-18.7-32.72C50.36,104.21,55.25,101.85,60.41,100.05 M84,96
                                c4.06,0,8.02,0.42,11.89,1.07C95.34,103.18,90.25,108,84,108s-11.34-4.82-11.89-10.93C75.98,96.42,79.94,96,84,96 M122.19,107.07
                                l-18.7,32.72l-5.6-24.29c5.05-3.6,8.61-9.1,9.7-15.45C112.75,101.85,117.64,104.21,122.19,107.07 M81.45,119.74
                                C82.3,119.83,83.13,120,84,120s1.7-0.17,2.55-0.26l8.16,35.41L84,173.91l-10.72-18.76L81.45,119.74z M48,48c0-19.85,16.14-36,36-36
                                s36,16.15,36,36c0,19.86-16.14,36-36,36S48,67.86,48,48 M12,168c0-21.32,9.37-40.45,24.15-53.64L73.66,180H12V168z M48,228
                                c0-19.85,16.14-36,36-36s36,16.15,36,36s-16.14,36-36,36S48,247.86,48,228 M115,270h8.46c7.32,0,13.84,4.36,16.77,11.43
                                c0.74,1.77,1.09,3.62,1.24,5.48C133.84,279.73,124.9,273.95,115,270 M60.41,280.05c1.09,6.35,4.66,11.84,9.7,15.45l-5.6,24.29
                                l-18.7-32.72C50.36,284.21,55.25,281.85,60.41,280.05 M84,276c4.06,0,8.02,0.42,11.89,1.07C95.34,283.18,90.25,288,84,288
                                s-11.34-4.82-11.89-10.93C75.98,276.42,79.94,276,84,276 M81.45,299.74C82.3,299.83,83.13,300,84,300s1.7-0.17,2.55-0.26
                                l8.16,35.41L84,353.91l-10.72-18.76L81.45,299.74z M103.49,319.79l-5.6-24.29c5.05-3.6,8.61-9.1,9.7-15.45
                                c5.16,1.8,10.04,4.16,14.6,7.02L103.49,319.79z M12,348c0-21.32,9.37-40.45,24.15-53.64L73.66,360H12V348z M156,360H94.34
                                l37.51-65.64C146.63,307.55,156,326.68,156,348V360z M360,258h-3.46c-12.18,0-23.07,7.27-27.85,18.8
                                c-4.73,11.32-2.2,24.22,6.45,32.86l2.46,2.46l-25.48,25.48l-2.46-2.46c-8.64-8.65-21.55-11.18-33.14-6.32
                                c-11.25,4.66-18.52,15.55-18.52,27.73V360h-36v-3.46c0-12.18-7.27-23.07-18.8-27.85c-11.34-4.73-24.23-2.2-32.86,6.45l-2.46,2.46
                                l-0.7-0.7c-1.89-14.17-7.3-27.2-15.34-38.23c2.55-7.05,2.39-14.87-0.64-22.13c-4.67-11.25-15.55-18.53-27.73-18.53h-2.05
                                c6.61-8.23,10.59-18.65,10.59-30c0-2.44-0.24-4.81-0.59-7.15c8.8-2.44,16.22-8.79,19.9-17.65c1.5-3.6,2.19-7.39,2.25-11.2H168v-24
                                c0-7.82-1.16-15.36-3.16-22.55l3.04-3.04l2.46,2.46c8.64,8.64,21.54,11.17,33.14,6.32c11.25-4.66,18.52-15.55,18.52-27.73V120h36
                                v3.46c0,12.18,7.27,23.07,18.8,27.85c11.33,4.72,24.22,2.2,32.86-6.45l2.46-2.46l25.48,25.48l-2.46,2.46
                                c-8.64,8.64-11.17,21.54-6.32,33.14c4.66,11.25,15.55,18.52,27.73,18.52H360V258z"
                />
                <path
                    class="st0"
                    d="M228,84h60v10.59c0,7.39,6.02,13.41,13.41,13.41c5.11,0,9.71-2.84,12-7.41L321.71,84H336c6.62,0,12-5.38,12-12
                                V12c0-6.62-5.38-12-12-12H228c-6.62,0-12,5.38-12,12v60C216,78.62,221.38,84,228,84 M228,12h108v60h-21.71l-11.61,23.21
                                c-0.59,1.18-2.69,0.68-2.69-0.63V72h-72V12L228,12z"
                />
                <rect x="300" y="36" class="st0" width="12" height="12" />
                <rect x="276" y="36" class="st0" width="12" height="12" />
                <rect x="252" y="36" class="st0" width="12" height="12" />
            </g>
        </svg>
        <svg
            v-if="name == 'idea-pitch-event'"
            version="1.1"
            fill="currentColor"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 480 400"
            style="enable-background:new 0 0 480 400;"
            xml:space="preserve"
        >
            <g>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <path
                    class="st0"
                    d="M87.7,200h0.61c24.68,0.44,45.3-18.69,46.72-43.34l0.98-21.54c0.54-12.12-3.96-23.93-12.42-32.62
    c-9-9.3-21.41-14.54-34.35-14.5h-2.46c-12.93-0.03-25.32,5.21-34.31,14.5c-8.46,8.7-12.95,20.5-12.42,32.62l0.94,21.54
    C42.39,181.31,63.02,200.44,87.7,200 M88.3,184H87.7c-16.09,0.41-29.65-11.94-30.74-28l-0.28-6.54
    c15.25-0.68,29.89-6.17,41.82-15.7c4.92,7.28,12.35,12.49,20.86,14.63l-0.33,7.6C117.96,172.06,104.4,184.41,88.3,184 M86.77,104
    h2.46c8.58-0.02,16.79,3.45,22.77,9.6c4.67,4.86,7.44,11.24,7.81,17.97c-5.54-2.47-9.55-7.45-10.8-13.38
    c-0.45-3.41-3.02-6.16-6.4-6.82c-3.36-0.7-6.8,0.85-8.5,3.84c-0.1,0.17-10.03,15.87-38.05,18.27C56,126.07,58.85,118.93,64,113.6
    C69.97,107.45,78.19,103.98,86.77,104"
                />
                <path
                    class="st0"
                    d="M399.7,392h0.61c24.68,0.44,45.3-18.69,46.72-43.34l0.98-21.54c0.54-12.12-3.96-23.93-12.42-32.62
    c-9-9.3-21.4-14.54-34.35-14.5h-2.46c-12.93-0.03-25.32,5.21-34.31,14.5c-8.46,8.7-12.95,20.5-12.42,32.62l0.94,21.54
    C354.39,373.31,375.02,392.44,399.7,392 M376,305.6c5.97-6.15,14.19-9.62,22.77-9.6h2.46c8.58-0.02,16.79,3.45,22.77,9.6
    c5.43,5.56,8.32,13.12,8,20.88L431.04,348c-1.08,16.06-14.64,28.41-30.74,28h-0.61c-16.09,0.41-29.65-11.94-30.74-28L368,326.44
    C367.69,318.69,370.58,311.15,376,305.6"
                />
                <path
                    class="st0"
                    d="M24,384c2.73,0,5.43-0.46,8-1.37V392c0,4.42,3.58,8,8,8h96c4.42,0,8-3.58,8-8V262.29
    c0-2.02,0.76-3.98,2.14-5.46l101.51-109.18c8.5-8.79,8.27-22.81-0.52-31.31c-8.58-8.3-22.21-8.3-30.79,0l-89.31,89.31
    c-1.5,1.5-3.54,2.35-5.66,2.34H40c-22.08,0.03-39.97,17.92-40,40v112C0,373.26,10.74,384,24,384 M16,248c0-13.25,10.74-24,24-24
    h81.37c6.37,0.02,12.49-2.51,16.98-7.03l89.31-89.31c2.43-2.31,6.25-2.31,8.69,0c2.4,2.46,2.35,6.41-0.11,8.81
    c-0.03,0.03-0.06,0.05-0.09,0.08L134.4,245.94c-4.12,4.45-6.41,10.28-6.4,16.34V384H48V264c0-4.42-3.58-8-8-8s-8,3.58-8,8v96
    c0,4.42-3.58,8-8,8s-8-3.58-8-8V248z"
                />
                <path
                    class="st0"
                    d="M88,344c4.42,0,8-3.58,8-8v-96c0-4.42-3.58-8-8-8c-4.42,0-8,3.58-8,8v96C80,340.42,83.58,344,88,344"
                />
                <path
                    class="st0"
                    d="M223.7,392h0.61c24.68,0.44,45.3-18.69,46.72-43.34l0.94-21.54c0.54-12.12-3.96-23.93-12.42-32.62
    c-8.99-9.29-21.38-14.53-34.31-14.5h-2.46c-12.93-0.03-25.32,5.21-34.31,14.5c-8.46,8.7-12.95,20.5-12.42,32.62l0.94,21.54
    C178.39,373.31,199.02,392.44,223.7,392 M200,305.6c5.97-6.15,14.19-9.62,22.77-9.6h2.46c8.58-0.02,16.79,3.45,22.77,9.6
    c5.43,5.56,8.32,13.12,8,20.88L255.04,348c-1.08,16.06-14.64,28.41-30.74,28h-0.61c-16.09,0.41-29.65-11.94-30.74-28L192,326.44
    C191.69,318.69,194.58,311.15,200,305.6"
                />
                <path
                    class="st0"
                    d="M456.4,0H175.6c-13.14,0.13-23.69,10.86-23.6,24v120c0,4.42,3.58,8,8,8c4.42,0,8-3.58,8-8V48h296v192
    c0.1,4.3-3.3,7.88-7.6,8h-273c-4.42,0-8,3.58-8,8s3.58,8,8,8h273c13.14-0.13,23.69-10.86,23.6-24V24
    C480.09,10.86,469.54,0.13,456.4,0 M168,32v-8c-0.1-4.3,3.29-7.88,7.6-8h280.8c4.3,0.12,7.7,3.7,7.6,8v8H168z"
                />
                <path
                    class="st0"
                    d="M440,102c-4.42,0-8,3.58-8,8v114c0,4.42,3.58,8,8,8c4.42,0,8-3.58,8-8V110C448,105.58,444.42,102,440,102"
                />
                <path
                    class="st0"
                    d="M408,152c-4.42,0-8,3.58-8,8v64c0,4.42,3.58,8,8,8c4.42,0,8-3.58,8-8v-64C416,155.58,412.42,152,408,152"
                />
                <path
                    class="st0"
                    d="M368,144v80c0,4.42,3.58,8,8,8c4.42,0,8-3.58,8-8v-80c0-4.42-3.58-8-8-8C371.58,136,368,139.58,368,144"
                />
                <path
                    class="st0"
                    d="M344,184c-4.42,0-8,3.58-8,8v32c0,4.42,3.58,8,8,8c4.42,0,8-3.58,8-8v-32C352,187.58,348.42,184,344,184"
                />
                <path
                    class="st0"
                    d="M305.6,188.8c2.65,3.54,7.67,4.25,11.2,1.6l32-24c1.01-0.74,1.83-1.71,2.4-2.82l27.7-55.42l24.3,18.25
    c3.53,2.65,8.55,1.94,11.2-1.6c0.09-0.12,0.17-0.24,0.26-0.36l32-48c2.45-3.68,1.46-8.64-2.22-11.1c-3.68-2.45-8.65-1.46-11.1,2.22
    l-27.31,40.96L380.8,89.6c-3.52-2.67-8.54-1.98-11.21,1.54c-0.3,0.4-0.57,0.83-0.79,1.28l-31.15,62.31L307.2,177.6
    C303.66,180.25,302.95,185.27,305.6,188.8"
                />
                <path
                    class="st0"
                    d="M288,80c4.42,0,8-3.58,8-8c0-4.42-3.58-8-8-8h-96c-4.42,0-8,3.58-8,8c0,4.42,3.58,8,8,8H288z"
                />
                <path
                    class="st0"
                    d="M336,80c4.42,0,8-3.58,8-8c0-4.42-3.58-8-8-8h-16c-4.42,0-8,3.58-8,8c0,4.42,3.58,8,8,8H336z"
                />
            </g>
        </svg>
        <svg
            v-if="name == 'all-zainers'"
            version="1.1"
            fill="currentColor"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 510.24 421.69"
            style="enable-background:new 0 0 510.24 421.69;"
            xml:space="preserve"
        >
            <g>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <path
                    class="st0"
                    d="M301.26,47.13C301.26,21.1,322.36,0,348.37,0c26.04,0,47.13,21.1,47.14,47.13c0,26.02-21.1,47.12-47.14,47.12
    C322.36,94.25,301.26,73.16,301.26,47.13 M310.43,47.13c0.04,20.95,17.01,37.9,37.94,37.94c20.97-0.04,37.93-16.99,37.96-37.94
    c-0.04-20.97-16.99-37.92-37.96-37.96C327.44,9.21,310.47,26.17,310.43,47.13"
                />
                <path
                    class="st0"
                    d="M285.85,126.4c-1.92-1.65-2.14-4.54-0.49-6.46c7.97-9.28,19.84-15.17,33.04-15.17h61.67
    c12.18,0,23.23,5.01,31.12,13.07v0c1.77,1.81,1.74,4.71-0.07,6.49c-1.81,1.77-4.71,1.74-6.49-0.07
    c-6.25-6.38-14.92-10.31-24.56-10.31h-61.67c-10.45,0-19.77,4.63-26.08,11.97c-0.9,1.05-2.18,1.59-3.48,1.59
    C287.79,127.51,286.72,127.15,285.85,126.4"
                />
                <path
                    class="st0"
                    d="M217.9,125.92c-6.32-7.34-15.64-11.97-26.09-11.97h-61.68c-9.63,0-18.3,3.93-24.55,10.31v0
    c-1.78,1.81-4.68,1.84-6.49,0.07c-1.81-1.77-1.84-4.68-0.07-6.49c7.89-8.07,18.94-13.07,31.11-13.07h61.68
    c13.21,0,25.07,5.89,33.05,15.17c1.65,1.92,1.43,4.82-0.49,6.47c-0.86,0.74-1.93,1.11-2.99,1.11
    C220.09,127.51,218.81,126.97,217.9,125.92"
                />
                <path
                    class="st0"
                    d="M114.72,47.13C114.72,21.1,135.82,0,161.84,0c26.02,0,47.12,21.1,47.13,47.13
    c-0.01,26.02-21.11,47.11-47.13,47.12C135.82,94.25,114.72,73.16,114.72,47.13 M123.9,47.13c0.04,20.95,16.99,37.9,37.94,37.94
    c20.95-0.04,37.92-16.99,37.96-37.94c-0.04-20.97-17-37.92-37.96-37.96C140.89,9.21,123.93,26.17,123.9,47.13"
                />
                <path
                    class="st0"
                    d="M183.68,220.07c0.01-39.46,31.99-71.43,71.43-71.43c39.45,0,71.43,31.97,71.43,71.43
    c-0.01,39.45-31.98,71.42-71.43,71.42C215.67,291.49,183.69,259.52,183.68,220.07 M211.1,176.05
    c-11.27,11.28-18.24,26.81-18.24,44.02c0,17.2,6.96,32.74,18.24,44.02c11.28,11.28,26.82,18.23,44.01,18.23
    c17.2,0,32.74-6.96,44.02-18.23c11.27-11.28,18.23-26.82,18.23-44.02c0-17.21-6.96-32.74-18.23-44.02
    c-11.28-11.27-26.82-18.23-44.02-18.23C237.91,157.82,222.38,164.77,211.1,176.05"
                />
                <path
                    class="st0"
                    d="M501.06,337.45v-21.99c-0.04-21.76-17.65-39.36-39.39-39.39h-69.63c-16.32,0-30.31,9.92-36.3,24.06
    c-0.99,2.33-3.68,3.42-6.01,2.43c-2.34-0.99-3.43-3.68-2.43-6.02c7.37-17.43,24.62-29.65,44.74-29.66h69.63
    c26.81,0,48.56,21.74,48.56,48.57v21.99c0,2.53-2.05,4.58-4.58,4.58C503.11,342.04,501.06,339.98,501.06,337.45"
                />
                <path
                    class="st0"
                    d="M374.25,201.25c0-29.06,23.56-52.61,52.61-52.61c29.05,0,52.6,23.55,52.6,52.61
    c-0.01,29.05-23.55,52.59-52.6,52.6C397.8,253.84,374.25,230.29,374.25,201.25 M426.86,244.67c23.97-0.04,43.38-19.45,43.43-43.42
    c-0.05-23.98-19.45-43.39-43.43-43.43c-23.98,0.04-43.39,19.45-43.43,43.43C383.47,225.22,402.87,244.63,426.86,244.67"
                />
                <path
                    class="st0"
                    d="M0,337.45v-21.99c0-26.83,21.74-48.57,48.56-48.57h69.63c20.12,0.01,37.38,12.23,44.75,29.66
    c0.98,2.33-0.11,5.02-2.44,6.01c-2.34,0.98-5.03-0.11-6.01-2.44c-5.99-14.15-19.98-24.06-36.3-24.06H48.56
    C26.82,276.1,9.21,293.7,9.18,315.46v21.99c0,2.53-2.06,4.58-4.59,4.58C2.06,342.04,0,339.98,0,337.45"
                />
                <path
                    class="st0"
                    d="M30.77,201.25c0.01-29.06,23.56-52.61,52.61-52.61c29.05,0,52.6,23.55,52.61,52.61
    c0,29.05-23.56,52.6-52.61,52.6C54.33,253.84,30.78,230.29,30.77,201.25 M39.95,201.25c0.04,23.98,19.45,43.38,43.43,43.43
    c23.98-0.04,43.39-19.45,43.43-43.43c-0.04-23.98-19.46-43.39-43.43-43.43C59.4,157.85,40,177.26,39.95,201.25"
                />
                <path
                    class="st0"
                    d="M361.39,417.1v-31.48c0-18.08-7.38-34.49-19.32-46.43c-11.93-11.93-28.34-19.32-46.43-19.32h-80.32
    c-18.08,0-34.5,7.39-46.43,19.32c-11.93,11.94-19.32,28.35-19.32,46.43v31.48c0,2.54-2.05,4.59-4.59,4.59
    c-2.53,0-4.59-2.06-4.59-4.59v-31.48c0.02-41.24,33.68-74.91,74.93-74.92h80.32c41.24,0.01,74.9,33.68,74.92,74.92v31.48
    c0,2.54-2.05,4.59-4.59,4.59C363.44,421.69,361.39,419.64,361.39,417.1"
                />
            </g>
        </svg>
        <svg
            v-if="name == 'activity-feeds'"
            version="1.1"
            fill="currentColor"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 361 361"
            style="enable-background:new 0 0 361 361;"
            xml:space="preserve"
        >
            <g>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <path
                    class="st0"
                    d="M207.21,12H258V0h-54c-2,0-3.88,1-4.99,2.67l-12,18l9.98,6.66L207.21,12z"
                />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_3_" width="361" height="361" />
                </defs>
                <clipPath id="SVGID_4_">
                    <use xlink:href="#SVGID_3_" style="overflow:visible;" />
                </clipPath>
                <path
                    class="st1"
                    d="M152.79,348H102v12h54c2,0,3.88-1,4.99-2.67l12-18l-9.98-6.66L152.79,348z"
                />
                <path
                    class="st1"
                    d="M246,132c-20.88-0.03-41.36,5.75-59.14,16.68l-3.12-4.36c1.32-2.75,2.48-5.57,3.49-8.44l23.76-3.96
    c2.89-0.48,5.01-2.98,5.01-5.91V90c0-2.93-2.12-5.43-5.01-5.91l-23.76-3.96c-1.01-2.88-2.17-5.7-3.49-8.44l13.99-19.59
    c1.7-2.39,1.43-5.65-0.64-7.73l-25.46-25.46c-2.07-2.07-5.34-2.34-7.73-0.64l-19.59,13.99c-2.75-1.3-5.57-2.46-8.44-3.46
    l-3.96-23.76C131.44,2.14,128.94,0,126,0H90c-2.93,0-5.43,2.12-5.91,5.01l-3.96,23.76c-2.88,1.01-5.7,2.17-8.44,3.49L52.09,18.27
    c-2.39-1.7-5.66-1.43-7.73,0.64L18.91,44.36c-2.07,2.08-2.34,5.35-0.64,7.73l14.05,19.68c-1.3,2.71-2.45,5.49-3.44,8.33L5.01,84.09
    C2.12,84.57,0,87.07,0,90v36c0,2.93,2.12,5.43,5.01,5.91l23.87,3.98c0.98,2.85,2.11,5.66,3.38,8.4l-13.99,19.61
    c-1.7,2.39-1.43,5.66,0.64,7.73l25.46,25.46c2.07,2.08,5.34,2.35,7.73,0.64l19.59-13.99c2.75,1.32,5.57,2.48,8.44,3.49l3.96,23.76
    c0.48,2.89,2.98,5.01,5.91,5.01h36c2.93,0,5.43-2.12,5.91-5.01l3.96-23.76c2.88-1.01,5.69-2.17,8.44-3.49l4.36,3.12
    C137.75,204.64,131.97,225.12,132,246c0,46.11,27.77,87.67,70.38,105.32c42.6,17.64,91.63,7.89,124.23-24.71
    c32.61-32.6,42.36-81.64,24.71-124.23C333.68,159.77,292.11,132,246,132 M142.01,171.46c-4.21,2.25-8.63,4.08-13.2,5.45
    c-2.2,0.66-3.82,2.5-4.2,4.76L120.92,204H95.08l-3.72-22.33c-0.38-2.26-2.01-4.1-4.2-4.76c-4.57-1.37-8.99-3.2-13.2-5.45
    c-2.01-1.08-4.46-0.93-6.32,0.4l-18.42,13.15l-18.24-18.26l13.15-18.42c1.33-1.86,1.48-4.31,0.4-6.32
    c-2.25-4.32-4.1-8.82-5.54-13.47c-0.73-2.05-2.52-3.54-4.66-3.9L12,120.92V95.08l22.33-3.72c2.15-0.36,3.93-1.85,4.66-3.9
    c1.55-4.71,3.45-9.3,5.68-13.73c0.93-1.97,0.73-4.29-0.54-6.06L30.98,49.25l18.26-18.26l18.42,13.15c1.86,1.33,4.31,1.48,6.32,0.4
    c4.21-2.25,8.63-4.08,13.2-5.45c2.2-0.66,3.83-2.5,4.2-4.76L95.08,12h25.84l3.72,22.33c0.38,2.26,2.01,4.1,4.2,4.76
    c4.57,1.37,8.99,3.2,13.2,5.45c2.01,1.08,4.46,0.93,6.32-0.4l18.42-13.15l18.27,18.26l-13.15,18.42c-1.33,1.86-1.48,4.31-0.4,6.32
    c2.25,4.21,4.07,8.63,5.45,13.2c0.65,2.2,2.5,3.83,4.76,4.2l22.33,3.72v25.84l-22.33,3.72c-2.26,0.38-4.1,2.01-4.76,4.2
    c-1.38,4.57-3.2,8.99-5.45,13.2c-1.08,2.02-0.93,4.46,0.4,6.32l13.15,18.42l-18.27,18.26l-18.42-13.15
    C146.51,170.57,144.04,170.39,142.01,171.46 M252,347.7V330h-12v17.7c-51.52-3.08-92.61-44.18-95.7-95.7H162v-12h-17.78
    c0.92-16.31,5.8-32.16,14.23-46.16l5.45,3.89c2.39,1.7,5.66,1.43,7.73-0.64l25.46-25.46c2.07-2.07,2.34-5.34,0.64-7.73l-3.89-5.45
    c14-8.43,29.84-13.31,46.16-14.23V162h12v-17.69c51.52,3.08,92.61,44.18,95.69,95.69H330v12h17.69
    C344.61,303.52,303.52,344.61,252,347.7"
                />
            </g>
            <g>
                <defs>
                    <rect id="SVGID_5_" width="361" height="361" />
                </defs>
                <clipPath id="SVGID_6_">
                    <use xlink:href="#SVGID_5_" style="overflow:visible;" />
                </clipPath>
                <path
                    class="st2"
                    d="M252,174h-12v72c0,3.31,2.69,6,6,6h48v-12h-42V174z"
                />
                <path
                    class="st2"
                    d="M138,90h-24V54c0-2.82-1.97-5.26-4.73-5.86c-2.76-0.6-5.56,0.81-6.73,3.38l-30,66
    c-0.84,1.85-0.69,4.01,0.42,5.73c1.1,1.71,3,2.75,5.04,2.75h24v36c0,2.82,1.97,5.27,4.73,5.87c0.42,0.09,0.85,0.13,1.27,0.13
    c2.35,0,4.49-1.38,5.46-3.52l30-66c0.84-1.86,0.69-4.01-0.42-5.73C141.94,91.04,140.04,90,138,90 M114,134.3V120c0-3.31-2.69-6-6-6
    H87.32L102,81.7V96c0,3.31,2.69,6,6,6h20.68L114,134.3z"
                />
                <rect x="102" y="24" class="st2" width="12" height="12" />

                <rect
                    x="46.85"
                    y="46.85"
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -21.8904 52.8476)"
                    class="st2"
                    width="12"
                    height="12"
                />
                <rect x="24" y="102" class="st2" width="12" height="12" />

                <rect
                    x="46.84"
                    y="157.15"
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -99.881 85.1421)"
                    class="st2"
                    width="12"
                    height="12"
                />
                <rect x="102" y="180" class="st2" width="12" height="12" />

                <rect
                    x="157.15"
                    y="157.15"
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -67.5807 163.1502)"
                    class="st2"
                    width="12"
                    height="12"
                />
                <rect x="180" y="102" class="st2" width="12" height="12" />

                <rect
                    x="157.15"
                    y="46.85"
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 10.4191 130.8557)"
                    class="st2"
                    width="12"
                    height="12"
                />
                <path
                    class="st2"
                    d="M306,30c-9.71,0-18.46,5.85-22.17,14.82c-3.71,8.97-1.66,19.29,5.2,26.15c6.86,6.87,17.18,8.92,26.15,5.2
    C324.15,72.46,330,63.71,330,54C330,40.75,319.25,30,306,30 M306,66c-4.86,0-9.23-2.92-11.09-7.41c-1.86-4.49-0.83-9.65,2.6-13.08
    c3.43-3.43,8.59-4.46,13.08-2.6C315.08,44.77,318,49.15,318,54C318,60.63,312.63,66,306,66"
                />
                <path
                    class="st2"
                    d="M355.46,36.18l-4.01-1l2.13-3.55c1.42-2.36,1.04-5.39-0.91-7.33L335.7,7.33c-1.94-1.95-4.97-2.32-7.33-0.91
    l-3.55,2.13l-1-4.01C323.15,1.87,320.75,0,318,0h-24c-2.75,0-5.15,1.87-5.82,4.54l-1,4.01l-3.55-2.13
    c-2.36-1.42-5.39-1.04-7.33,0.91L259.33,24.3c-1.95,1.95-2.32,4.97-0.91,7.33l2.13,3.55l-4.01,1C253.87,36.85,252,39.25,252,42v24
    c0,2.75,1.87,5.15,4.54,5.82l4.01,1l-2.13,3.55c-1.42,2.36-1.04,5.39,0.91,7.33l16.97,16.97c1.95,1.95,4.97,2.32,7.33,0.91
    l3.55-2.13l1,4.01c0.67,2.67,3.07,4.54,5.82,4.54h24c2.75,0,5.15-1.87,5.82-4.54l1-4.01l3.55,2.13c2.36,1.42,5.39,1.04,7.33-0.91
    l16.97-16.97c1.95-1.95,2.32-4.97,0.91-7.33l-2.13-3.55l4.01-1c2.67-0.67,4.54-3.07,4.54-5.82V42
    C360,39.25,358.13,36.85,355.46,36.18 M348,61.31l-7.32,1.8c-1.84,0.46-3.36,1.77-4.09,3.53c-0.73,1.76-0.58,3.75,0.4,5.38
    l3.88,6.47l-10.34,10.35l-6.47-3.88c-1.63-0.98-3.63-1.13-5.38-0.4c-1.76,0.73-3.07,2.24-3.53,4.09L313.31,96h-14.63l-1.8-7.32
    c-0.46-1.84-1.77-3.36-3.53-4.09c-1.76-0.73-3.75-0.58-5.38,0.4l-6.47,3.88l-10.35-10.34l3.88-6.47c0.98-1.63,1.13-3.63,0.4-5.38
    c-0.73-1.76-2.24-3.07-4.09-3.53L264,61.31V46.69l7.32-1.8c1.84-0.46,3.36-1.77,4.09-3.53c0.73-1.76,0.58-3.75-0.4-5.38l-3.88-6.47
    l10.34-10.35l6.47,3.88c1.63,0.98,3.63,1.13,5.39,0.4c1.75-0.73,3.06-2.25,3.52-4.09l1.8-7.32h14.63l1.8,7.32
    c0.46,1.84,1.77,3.36,3.52,4.09c1.76,0.73,3.75,0.58,5.38-0.4l6.48-3.88l10.34,10.35l-3.88,6.47c-0.98,1.63-1.13,3.63-0.4,5.38
    c0.73,1.76,2.24,3.06,4.09,3.53l7.33,1.8L348,61.31z"
                />
                <path
                    class="st2"
                    d="M54,282c-9.71,0-18.46,5.85-22.17,14.82c-3.71,8.97-1.66,19.29,5.2,26.15c6.86,6.87,17.18,8.92,26.15,5.2
    C72.15,324.46,78,315.71,78,306C78,292.75,67.25,282,54,282 M54,318c-4.86,0-9.23-2.92-11.09-7.41c-1.86-4.48-0.83-9.65,2.6-13.08
    c3.43-3.43,8.59-4.46,13.08-2.6C63.08,296.77,66,301.14,66,306C66,312.63,60.63,318,54,318"
                />
                <path
                    class="st2"
                    d="M101.57,328.37l-2.13-3.55l4.01-1c2.67-0.67,4.54-3.07,4.54-5.82v-24c0-2.75-1.87-5.15-4.54-5.82l-4.01-1
    l2.13-3.55c1.42-2.36,1.04-5.39-0.91-7.33L83.7,259.33c-1.94-1.95-4.97-2.33-7.33-0.91l-3.55,2.13l-1-4.01
    C71.15,253.87,68.75,252,66,252H42c-2.75,0-5.15,1.87-5.82,4.54l-1,4.01l-3.55-2.13c-2.36-1.42-5.39-1.04-7.33,0.91L7.33,276.3
    c-1.95,1.95-2.32,4.97-0.91,7.33l2.13,3.55l-4.01,1C1.87,288.85,0,291.25,0,294v24c0,2.75,1.87,5.15,4.54,5.82l4.01,1l-2.13,3.55
    c-1.42,2.36-1.04,5.39,0.91,7.33l16.97,16.97c1.94,1.95,4.97,2.32,7.33,0.91l3.55-2.13l1,4.01c0.67,2.67,3.07,4.54,5.82,4.54h24
    c2.75,0,5.15-1.87,5.82-4.54l1-4.01l3.55,2.13c2.36,1.42,5.39,1.04,7.33-0.91l16.97-16.97
    C102.62,333.75,102.99,330.73,101.57,328.37 M96,313.31l-7.32,1.8c-1.84,0.46-3.36,1.77-4.09,3.53c-0.73,1.76-0.58,3.75,0.4,5.38
    l3.88,6.47l-10.34,10.35l-6.47-3.88c-1.63-0.98-3.63-1.13-5.38-0.4c-1.76,0.73-3.07,2.24-3.53,4.09L61.31,348H46.69l-1.8-7.32
    c-0.47-1.84-1.77-3.36-3.53-4.09c-1.76-0.73-3.75-0.58-5.38,0.4l-6.47,3.88l-10.38-10.34l3.88-6.47c0.98-1.63,1.13-3.63,0.4-5.38
    c-0.73-1.76-2.24-3.07-4.09-3.53L12,313.31v-14.63l7.32-1.8c1.84-0.46,3.36-1.77,4.09-3.53c0.73-1.76,0.58-3.75-0.4-5.38
    l-3.88-6.47l10.34-10.35l6.47,3.88c1.63,0.98,3.63,1.13,5.39,0.4c1.75-0.73,3.06-2.25,3.52-4.09l1.8-7.32h14.63l1.8,7.32
    c0.46,1.84,1.77,3.36,3.52,4.09c1.76,0.73,3.75,0.58,5.38-0.4l6.48-3.88l10.34,10.35l-3.88,6.47c-0.98,1.63-1.13,3.63-0.4,5.38
    c0.73,1.76,2.24,3.06,4.09,3.53l7.33,1.8L96,313.31z"
                />
            </g>
        </svg>
        <svg
            v-if="name == 'messages'"
            version="1.1"
            fill="currentColor"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 409.6 368"
            style="enable-background:new 0 0 409.6 368;"
            xml:space="preserve"
        >
            <g>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <path
                    class="st0"
                    d="M204.8,80c-4.42,0-8,3.58-8,8c0,4.41,3.58,8,8,8c4.41,0,8-3.59,8-8C212.8,83.58,209.21,80,204.8,80"
                />
                <path
                    class="st0"
                    d="M72,224c4.41,0,8-3.59,8-8c0-4.42-3.59-8-8-8c-4.41,0-8,3.58-8,8C64,220.41,67.59,224,72,224"
                />
                <path
                    class="st0"
                    d="M268.8,0c-72.02,0-131.14,49.66-135.71,112.2C64.5,115.64,0,165.1,0,232c0,27.96,11.06,55.04,31.2,76.5
    c3.98,16.42-0.85,33.84-12.86,45.84c-2.29,2.29-2.97,5.73-1.73,8.72c1.24,2.99,4.15,4.94,7.39,4.94c22.82,0,44.8-8.95,61.14-24.72
    c15.91,5.42,36.68,8.72,55.66,8.72c72.01,0,131.12-49.65,135.7-112.18c16.75-0.74,34.17-3.84,47.96-8.54
    C340.8,247.05,362.79,256,385.6,256c3.24,0,6.15-1.95,7.39-4.94c1.23-2.99,0.55-6.43-1.73-8.72c-12-12-16.84-29.41-12.86-45.84
    c20.14-21.46,31.2-48.55,31.2-76.5C409.6,50.49,340.13,0,268.8,0 M140.8,336c-18.88,0-40.4-3.71-54.81-9.44
    c-3.09-1.23-6.62-0.42-8.86,2.03c-9.66,10.56-22.22,17.92-35.9,21.3c7.54-14.45,9.46-31.48,4.88-47.61
    c-0.36-1.27-1.03-2.43-1.95-3.38C26,280.21,16,256.45,16,232c0-56.38,57.15-104,124.8-104c63.88,0,120,44.42,120,104
    C260.8,289.35,206.97,336,140.8,336 M365.45,186.9c-0.92,0.95-1.59,2.11-1.96,3.38c-4.59,16.14-2.66,33.17,4.88,47.61
    c-13.68-3.38-26.24-10.75-35.9-21.3c-2.25-2.45-5.78-3.26-8.86-2.03c-12.49,4.97-30.31,8.41-47.13,9.24
    c-2.34-30.25-17.42-58.69-43.23-79.8H337.6c4.42,0,8-3.58,8-8s-3.58-8-8-8H208.67c-18.16-9.25-38.55-14.64-59.54-15.77
    C153.73,58.5,205.65,16,268.8,16c67.65,0,124.8,47.63,124.8,104C393.6,144.44,383.6,168.21,365.45,186.9"
                />
                <path
                    class="st0"
                    d="M204.8,208H104c-4.42,0-8,3.58-8,8c0,4.42,3.58,8,8,8h100.8c4.42,0,8-3.58,8-8
    C212.8,211.58,209.22,208,204.8,208"
                />
                <path
                    class="st0"
                    d="M204.8,256H72c-4.42,0-8,3.58-8,8s3.58,8,8,8h132.8c4.42,0,8-3.58,8-8S209.22,256,204.8,256"
                />
                <path
                    class="st0"
                    d="M337.6,80H236.8c-4.42,0-8,3.58-8,8c0,4.42,3.58,8,8,8h100.8c4.42,0,8-3.58,8-8
    C345.6,83.58,342.02,80,337.6,80"
                />
            </g>
        </svg>
        <svg
            v-if="name == 'logout'"
            version="1.1"
            fill="currentColor"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 363.76 391.92"
            style="enable-background:new 0 0 363.76 391.92;"
            xml:space="preserve"
        >
            <g>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <path
                    class="st0"
                    d="M360.56,204.64l0.16-0.16c0.24-0.32,0.48-0.56,0.64-0.88c0.08-0.08,0.08-0.16,0.16-0.24
    c0.16-0.32,0.4-0.64,0.56-0.96c0-0.08,0.08-0.16,0.08-0.16c0.16-0.32,0.32-0.64,0.48-1.04c0-0.08,0-0.08,0.08-0.16
    c0.16-0.32,0.24-0.72,0.4-1.12c0-0.08,0-0.16,0.08-0.16c0.08-0.4,0.24-0.72,0.24-1.12c0-0.16,0-0.24,0.08-0.4
    c0.08-0.32,0.08-0.64,0.16-0.96c0.08-0.48,0.08-0.88,0.08-1.36c0-0.48,0-0.88-0.08-1.36c0-0.32-0.08-0.64-0.16-0.96
    c0-0.16,0-0.24-0.08-0.4c-0.08-0.4-0.16-0.72-0.24-1.12c0-0.08,0-0.16-0.08-0.16c-0.08-0.4-0.24-0.72-0.4-1.12
    c0-0.08,0-0.08-0.08-0.16c-0.16-0.32-0.32-0.72-0.48-1.04c0-0.08-0.08-0.16-0.08-0.16c-0.16-0.32-0.32-0.64-0.56-0.96
    c-0.08-0.08-0.08-0.16-0.16-0.24c-0.24-0.32-0.4-0.64-0.64-0.88l-0.16-0.16c-0.32-0.32-0.56-0.72-0.96-1.04l-79.12-79.04
    c-5.36-5.36-14.08-5.36-19.44,0c-5.36,5.36-5.36,14.08,0,19.44l55.68,55.68H95.36c-7.6,0-13.76,6.16-13.76,13.68
    c0,7.6,6.16,13.76,13.76,13.76H316.8l-55.28,55.28c-5.36,5.36-5.36,14.08,0,19.44c2.64,2.64,6.16,4,9.68,4c3.52,0,7.04-1.36,9.68-4
    l78.64-78.64C359.92,205.28,360.24,204.96,360.56,204.64"
                />
                <path
                    class="st0"
                    d="M74.48,27.44h102.4c7.6,0,13.76-6.16,13.76-13.68c0-7.6-6.16-13.76-13.76-13.76H74.48C33.44,0,0,33.44,0,74.48
    v242.96c0,41.04,33.44,74.48,74.48,74.48H175.2c7.6,0,13.76-6.16,13.76-13.68c0-7.6-6.16-13.76-13.76-13.76H74.48
    c-25.92,0-47.04-21.12-47.04-47.04V74.48C27.52,48.48,48.56,27.44,74.48,27.44"
                />
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        name: String,
    },
    components: {},
    data: () => ({}),
    computed: {},
    methods: {},
}
</script>
<style lang="scss" scoped>
.icon {
    color: #00f6ff;
    margin-right: rem(40px);
    svg {
        width: rem(50px);
        height: auto;
    }
}
@media screen and (max-width: 991px) {
    .icon {
        svg {
            width: rem(40px);
        }
    }
    button {
        svg {
            transform: rotate(180deg);
        }
    }
}

@media screen and (max-width: 991px) {
    button {
        svg {
            transform: rotate(0deg);
        }
    }
}
</style>
