export const MENTORSHIP = {
    LIST: 'getMentorshipList',
    GET: 'getMentorship',
    GET_PROFILE: 'getProfile',
    SET_PROFILE: 'setProfile',
    SET_OBJECT: 'setMentorship',
    SET_LIST: 'setMentorshipList',
    CREATE_TASK: 'createTask',
    LIST_TASK: 'listTask',
    LIST_MY_TASK: 'listMyTask',
    UPDATE_TASK: 'updateTask',
    SET_LIST_TASK: 'setListTask',
    SET_LIST_MY_TASK: 'setListMyTask',
    LIST_NOTE: 'listNote',
    SET_LIST_NOTE: 'setListNote',
    ADD_NOTE: 'addNote',
    UPDATE_MY_MENTORSHIP: 'updateMyMentorship',
}
