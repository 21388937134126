<template>
    <div>
        <scrollbar :ops="scrollSettings" @handle-scroll="handleScroll">
            <ul style="max-height: 300px;">
                <li
                    v-for="(notification, key) in notifications"
                    :key="key"
                    :class="[notification.seen ? 'visited' : '']"
                >
                    <div class="upper_section">
                        <a
                            :href="`/${$i18n.locale}${notification.href}`"
                            class="text-dark font-weight-bold mb-3"
                        >
                            {{ notification.message }}
                        </a>
                    </div>
                    <div class="lower_section p-relative">
                        <span
                            >{{ notification.created | dateDayMonthYear }}
                            {{ notification.created | dateTime }}</span
                        >
                    </div>
                </li>
                <li
                    v-if="!notifications.length"
                    class="text-secondary font-weight-bold h4 visited"
                >
                    {{ $t('no new notifications') }}
                </li>
                <base-loader v-if="loadding" />
            </ul>
        </scrollbar>

        <!-- Badge Earned popup -->
        <b-modal id="BadgesModal" hide-footer @hidden="closeBadgeModal()">
            <div class="BadgesModal">
                <div class="BadgesModal__title" v-html="badge.message"></div>
                <div class="BadgesModal__image">
                    <div class="flip-container">
                        <div :key="badge.id" class="flipper">
                            <div class="front">
                                <img
                                    loading="lazy"
                                    class="img-fluid"
                                    :src="badge.badge_img_url"
                                    alt=""
                                    @load="isBadgeLoaded = true"
                                />
                                <base-loader
                                    class="base-loader"
                                    v-show="!isBadgeLoaded"
                                />
                            </div>
                            <div class="back">
                                <img
                                    loading="lazy"
                                    class="img-fluid"
                                    :src="badge.badge_img_url"
                                    alt=""
                                    @load="isBadgeLoaded = true"
                                />
                                <base-loader
                                    class="base-loader"
                                    v-show="!isBadgeLoaded"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="inline-buttons" v-if="!isMobileScreen()">
                    <a
                        :href="shareLinkedIn(badge.badge_img_url)"
                        target="_blank"
                        class="btn btn-primary"
                        >{{ $t('Share on LinkedIn') }}</a
                    >
                </div>
                <div class="inline-buttons">
                    <a class="btn btn-primary" @click="goToViewBadges()">
                        {{ $t('View My Badges') }}
                    </a>
                    <a
                        v-if="badgesLimit > 1"
                        class="btn btn-primary"
                        @click="nextBadges"
                    >
                        {{ $t('Next') }}
                    </a>
                    <a
                        v-else
                        class="btn btn-primary"
                        @click="$bvModal.hide('BadgesModal')"
                    >
                        {{ $t('Close') }}
                    </a>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
// import _ from 'lodash'
import { NotificationHelper } from '@/common/crud-helpers/notification'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    components: {},
    data() {
        return {
            notifications: [],
            scrollSettings: {
                maxHeight: 300,
            },
            filters: {
                limit: 10,
                offset: 0,
            },
            loadding: true,
            badge: {
                message: '',
                badge_img_url: '',
            },
            badgesList: [],
            popup: false,
            badgesLimit: 0,
            isBadgeLoaded: false,
        }
    },
    methods: {
        getNotifications() {
            this.loadding = true
            NotificationHelper.getNotification(this.filters)
        },
        handleScroll() {},
        // handleScroll(vertical) {
        //     if (vertical.process === 1) {
        //         let next = this.getNextOffset(this.notificationList.next)
        //         if (next) {
        //             this.zainersLoadding = true
        //             this.filters.offset = next
        //             this.getNotifications()
        //         }
        //     }
        // },
        async goToViewBadges() {
            if (this.$route.name === 'user-badges') {
                await NotificationHelper.seenNotification({
                    id: this.badge.id,
                })
                this.$router.go(this.$router.currentRoute)
            } else {
                await this.$bvModal.hide('BadgesModal')
                this.$router.push({
                    name: 'user-badges',
                })
            }
        },
        closeBadgeModal() {
            this.badgesList.forEach(async badge => {
                await NotificationHelper.seenNotification({
                    id: badge.id,
                })
            })
            this.badgesList = []
            this.badge = {}
            this.popup = false
            this.badgesLimit = 0
        },
        shareLinkedIn(badgeLink) {
            return `https://www.linkedin.com/feed/?shareActive&mini=true&text=I just received a new badge on %23Zainiac ${badgeLink}`
        },
        nextBadges() {
            this.isBadgeLoaded = false
            NotificationHelper.seenNotification({
                id: this.badge.id,
            })
            this.badgesList.pop()
            this.badge = this.badgesList.length
                ? this.badgesList[this.badgesList.length - 1]
                : {}
            this.badgesLimit--
        },
        isMobileScreen() {
            return window.innerWidth < 768
        },
    },
    created() {
        if (this.userAuth && this.userAuth.id) {
            NotificationHelper.getNotificationCount()
            this.getNotifications()
        }
    },
    computed: {
        ...mapGetters(['notificationList', 'userAuth']),
    },
    watch: {
        userAuth() {
            if (this.userAuth && this.userAuth.id) {
                NotificationHelper.getNotificationCount()
                this.getNotifications()
            }
        },
        notificationList() {
            // let allNotifications = _.concat(
            //     this.notifications,
            //     this.notificationList.results,
            // )
            // this.notifications = cloneDeep(allNotifications)
            this.notifications = cloneDeep(this.notificationList.results)
            if (!this.popup) {
                this.badgesList = this.notifications.filter(
                    notification =>
                        notification.href === '/user-badges' &&
                        !notification.seen,
                )
                if (this.badgesList.length > 5) this.badgesLimit = 5
                else this.badgesLimit = this.badgesList.length

                this.badge = this.badgesList.length
                    ? this.badgesList[this.badgesList.length - 1]
                    : {}
                if (this.badge && this.badge.href) {
                    this.$bvModal.show('BadgesModal')
                    this.popup = true
                }
            }
            this.loadding = false
        },
    },
}
</script>
<style scoped lang="scss">
.ar .p-relative {
    direction: ltr;
}

// Badge Earned popup CSS
.BadgesModal {
    max-width: 720px;
    margin: auto;
    text-align: center;
    &__title {
        font-size: rem(52px);
        font-weight: 900;
        color: var(--primary);
        text-align: center;
        margin-bottom: rem(50px);
        text-transform: uppercase;
        @media screen and (max-width: 767px) {
            font-size: rem(30px);
        }
    }
    &__image {
        .flip-container {
            perspective: 1000;
            .flipper {
                display: flex;
                justify-content: center;
                animation: flipY 2s ease-in-out;
                transition: 0.6s;
                transform-style: preserve-3d;
                position: relative;
                height: 300px;
                .front,
                .back {
                    position: absolute;
                    backface-visibility: hidden;
                    width: 300px;
                    @media screen and (max-width: 767px) {
                        padding: rem(25px) 0;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .back {
                    transform: rotateY(180deg);
                }
                .front {
                    z-index: 2;
                }
                .base-loader {
                    transition: none;
                    transform: none;
                    width: 80%;
                    height: 80%;
                }
            }
        }
    }
    .inline-buttons {
        padding-top: rem(20px);
        a {
            margin-left: rem(20px);
            margin-right: rem(20px);
            padding: 12px 23px;
            font-size: 18px !important;
        }
        .btn-primary {
            border: solid 2px transparent;
            min-width: 10.5625rem !important;
            &:hover {
                background: transparent;
                border-color: var(--primary);
                color: var(--primary);
            }
        }
        @media screen and (max-width: 575px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            a {
                font-size: 16px !important;
                &:not(:last-child) {
                    margin: 0;
                    margin-bottom: rem(15px);
                }
            }
        }
    }
}

/* Flip Animation */
@keyframes flipY {
    from {
        -webkit-transform: rotateY(180deg);
    }

    to {
        -webkit-transform: rotateY(-180deg);
    }
}
</style>
