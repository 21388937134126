import { ActivitiesService } from '@/common/api_services/activities'
import { API_BASE } from '@/conf'
import { USERS } from '@/modules/users/store/actions.js'
import { ACTIVITIES } from '@/store/actions/activities'
import { FETCH_START, FETCH_SUCCESS, FETCH_ERROR } from '@/store/common'
import axios from 'axios'

const state = {
    activitiesList: [],
}

const getters = {
    activitiesList: state => state.activitiesList,
}

const mutations = {
    [ACTIVITIES.SET_LIST]: (state, resp) => {
        state.activitiesList = resp.data
    },
}

const actions = {
    [ACTIVITIES.LIST]: ({ commit }, payload) => {
        return ActivitiesService.query(payload)
            .then(resp => {
                commit(ACTIVITIES.SET_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [ACTIVITIES.CMS_REDIRECT_URL]: ({ commit }, payload) => {
        return ActivitiesService.post(payload)
            .then(resp => {
                // commit(FETCH_SUCCESS)
                return resp
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [ACTIVITIES.CHECK_USER_LOGIN]: ({ commit, dispatch }) => {
        commit(FETCH_START)
        return fetch(API_BASE + '/en/api/get-token/', {
            credentials: 'include',
        })
            .then(response => response.json())
            .then(async data => {
                if (data.csrf_token) {
                    localStorage.setItem(
                        'csrf_token',
                        JSON.stringify(data.csrf_token),
                    )
                    // axios.defaults.headers.credentials = true
                    // axios.defaults.headers.common['X-CSRFToken'] = JSON.parse(
                    //     localStorage.getItem('csrf_token'),
                    // )
                }
                if (data && data.access && data.refresh) {
                    axios.defaults.headers.common['Authorization'] =
                        'Bearer ' + data.access
                    localStorage.setItem('token', JSON.stringify(data.access))
                    localStorage.setItem(
                        'refresh-token',
                        JSON.stringify(data.refresh),
                    )
                    let resp = {}
                    resp.data = { ...data }
                    commit(USERS.SET_TOKENS, resp)
                    await dispatch(USERS.GET_AUTH_USER)
                }
                commit(FETCH_SUCCESS)
                return data
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
