import Vue from 'vue'

import ampm from './ampm'
import dateDay from './dateDay'
import dateDayMonthYear from './dateDayMonthYear'
import dataMonth from './dateMonth'
import dateMonthYear from './dateMonthYear'
import dateTime from './dateTime'
import dataYear from './dateYear'
import secondhhmmss from './secondhhmmss'
import time from './time'

Vue.filter('dateDay', dateDay)
Vue.filter('dateMonthYear', dateMonthYear)
Vue.filter('dateDayMonthYear', dateDayMonthYear)
Vue.filter('dateTime', dateTime)
Vue.filter('dataMonth', dataMonth)
Vue.filter('dateYear', dataYear)
Vue.filter('time', time)
Vue.filter('ampm', ampm)
Vue.filter('secondhhmmss', secondhhmmss)
