<template>
    <div class="searchOverlay">
        <a href="#" @click.stop.prevent="search" class="searchClose">
            <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 409.81 409.81"
            >
                <use xlink:href="#menu-close-icon"></use>
            </svg>
        </a>
        <input
            name="query"
            v-model="searchWord"
            @keyup.enter="searchAction"
            type="text"
            class="form-control"
            :placeholder="`${$t('Search')}..`"
        />
        <button @click="searchAction">
            <svg
                fill="currentColor"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <use xlink:href="#header-search-icon"></use>
            </svg>
        </button>
    </div>
</template>

<script>
import { API_BASE } from '@/conf'
export default {
    props: {
        searchOpen: Boolean,
    },
    data() {
        return {
            searchWord: '',
        }
    },
    watch: {
        searchOpen() {
            this.search()
            document.querySelector('.form-control').focus()
        },
    },
    methods: {
        search() {
            document.body.classList.toggle('search-visible')
        },
        searchAction() {
            window.location.href =
                API_BASE +
                '/' +
                this.$i18n.locale +
                '/search' +
                `/?query=${this.searchWord}`
        },
    },
}
</script>

<style lang="scss" scoped>
.searchOverlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: #000;
    padding: rem(25px) rem(50px);
    z-index: 99;
    @include flex(center, space-between);
    flex-wrap: nowrap;
    transform: scale(0);
    transition: all 0.3s ease;
    z-index: -99;
    @media screen and (max-width: 767px) {
        padding: rem(25px) rem(20px);
    }
    // top: -100%;
    .searchClose {
        position: absolute;
        top: 2%;
        right: 2%;
        color: #fff;
        svg {
            transition: all 0.5s ease;
            width: 29px;
            height: auto;
        }
        @media screen and (min-width: 1025px) {
            &:hover {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .form-control {
        background: transparent;
        border: 0px;
        color: #fff;
        font-size: rem(25px);
        border-bottom: solid 1px #fff;
        @include input-placeholder {
            color: #fff;
        }
    }
    button {
        color: #fff;
        background: transparent;
        border: 0;
        svg {
            width: 40px;
            height: auto;
        }
    }
    .overlay {
        width: 100%;
        height: 100vh;
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        z-index: -999;
    }
}

// ar style
.ar .searchOverlay .searchClose {
    left: 2%;
    right: auto;
}
</style>
<style lang="scss">
.search-visible {
    .searchOverlay {
        transform: scale(1);
        z-index: 99;
    }
}
</style>
