<template>
    <div>
        <div
            class="comments"
            :style="
                heightCondition
                    ? { height: 'auto', margin: '2.5rem 0 10rem 0' }
                    : { height: '580px' }
            "
        >
            <div class="scrollWrapper">
                <scrollbar :ops="scrollSettings" @handle-scroll="handleScroll">
                    <comment-row
                        v-for="(comment, index) in comments"
                        :key="index"
                        :object="object"
                        :id="comment.pk"
                        :comment="comment"
                        @addComment="$emit('addComment', $event)"
                        @likeComment="$emit('likeComment', $event)"
                    ></comment-row>
                    <base-loader v-if="loadding" />
                </scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
import CommentRow from '@/common/components/base/comments/CommentRow.vue'

export default {
    props: {
        object: Object,
        comments: Array,
        loadding: Boolean,
        heightCondition: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        CommentRow,
    },
    data() {
        return {
            scrollSettings: {
                maxHeight: 250,
            },
        }
    },
    methods: {
        handleScroll(vertical) {
            if (vertical.process === 1) {
                this.$emit('loadMore')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
// .scrollWrapper {
//     height: 580px;
// }
// .comments {
//     box-shadow: -4px 6px 12px 0px rgba(0, 0, 0, 0.2);
// }
</style>
