<template>
    <validation-provider
        :rules="`${[required ? 'required' : '']}`"
        :name="name"
    >
        <b-form-group slot-scope="{ valid, errors }">
            <div>
                <label class="control-label" v-if="label">
                    <img :src="labelIcon" alt="" />
                    {{ label }}
                </label>
                <b-form-input
                    class="search-input"
                    type="search"
                    v-model="filterCriteria"
                    v-on:click="toggleDropDown()"
                    v-on:input="change()"
                    v-on:keyup.enter="selectItem()"
                    :placeholder="placeholder"
                    :required="required"
                    :state="errors[0] ? false : valid ? true : null"
                    :autofocus="autofocus"
                    :disabled="disabled"
                >
                </b-form-input>
            </div>
            <div>
                <b-collapse id="drop-down">
                    <b-table
                        no-border-collapse
                        ref="collapsibleTable"
                        responsive="sm"
                        selectable
                        select-mode="single"
                        sticky-header="200px"
                        thead-class="d-none"
                        v-model="filteredRows"
                        :fields="fields"
                        :items="newOptions"
                        @row-selected="rowSelected"
                        :filter="filterCriteria"
                    >
                        <template #cell(photo)="data">
                            <img :src="data.item.photo" alt="" />
                        </template>
                    </b-table>
                </b-collapse>
            </div>
            <b-form-invalid-feedback>
                {{ getError(errors[0]) }}
            </b-form-invalid-feedback>
            <base-multiple-row
                :selectedOptions="newSelectedOptions"
                :fields="fields"
                :options_value_key="options_value_key"
                @deleteOption="deleteOption"
                :disabled="disabled"
            />
        </b-form-group>
    </validation-provider>
</template>

<script>
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
export default {
    props: {
        name: String,
        value: {
            type: Array,
        },
        options: {
            required: true,
            type: Array,
        },
        fields: {
            required: true,
            type: Array,
        },
        placeholder: {
            required: false,
            default: 'Select',
        },
        required: {
            type: Boolean,
        },
        options_value_key: String,
        label: String,
        labelIcon: String,
        autofocus: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            filteredRows: [],
            newOptions: [],
            newSelectedOptions: [],
            selectedOptions: [],
            filterCriteria: '',
        }
    },
    created() {
        this.setOptions()
    },
    methods: {
        toggleDropDown() {
            this.$root.$emit('bv::toggle::collapse', 'drop-down')
        },
        selectItem() {
            if (this.filteredRows.length === 1) {
                this.$refs.collapsibleTable.selectRow(0)
            }
        },
        rowSelected(rowArray) {
            if (rowArray.length === 1) {
                if (!_.includes(this.value, rowArray[0])) {
                    this.value.push(rowArray[0])
                }
                this.$emit('input', this.value)
                this.toggleDropDown()
            }
        },
        setOptions() {
            if (!this.value) {
                this.value = []
                this.newOptions = cloneDeep(this.options)
            } else {
                this.newOptions = this.options.filter(
                    option => !_.some(this.value, option),
                )
                this.newSelectedOptions = cloneDeep(this.value)
            }
        },
        deleteOption(value) {
            let object = this.value.find(option => option.id == value)
            var index = this.value.indexOf(object)

            if (index !== -1) {
                this.value.splice(index, 1)
            }
            this.setOptions()
            this.$emit('deleteMember', value)
        },
        getError(error) {
            if (this.$te(error)) {
                return this.$t(error)
            } else {
                return error
            }
        },
        change() {
            this.$emit('change', this.filterCriteria)
        },
    },
    watch: {
        options: function() {
            this.setOptions()
        },
        value: function() {
            this.setOptions()
        },
    },
}
</script>
<style scoped>
#drop-down {
    background: #fff;
}
.form-control:disabled {
    border-color: transparent;
    color: #999;
    background-color: #f4f4f4;
    opacity: 0.6;
}
</style>
