import { WorkshopService } from '@/common/api_services/workshop'
import { FETCH_SUCCESS, FETCH_ERROR, FETCH_START } from '@/store/common'

import { WORKSHOP, SOCIAL } from './actions'

const state = {
    workshopList: [],
    workshopObject: {},
    workshopSettings: {},
    commentsList: [],
    likesList: [],
    followersList: [],
    userRegistrations: {},
}

const getters = {
    workshopList: state => state.workshopList,
    workshopObject: state => state.workshopObject,
    workshopSettings: state => state.workshopSettings,
    workshopCommentsList: state => state.commentsList,
    workshopLikesList: state => state.likesList,
    workshopFollowersList: state => state.followersList,
    userRegistrations: state => state.userRegistrations,
}

const mutations = {
    [WORKSHOP.SET_LIST]: (state, resp) => {
        state.workshopList = resp.data
    },
    [WORKSHOP.SET_OBJECT]: (state, resp) => {
        state.workshopObject = resp.data
    },
    [WORKSHOP.SET_SETTINGS]: (state, resp) => {
        state.workshopSettings = resp.data
    },
    [SOCIAL.SET_COMMENTS_LIST]: (state, resp) => {
        state.commentsList = resp.data
    },
    [SOCIAL.SET_LIKE_LIST]: (state, resp) => {
        state.likesList = resp.data
    },
    [SOCIAL.SET_FOLLOWER_LIST]: (state, resp) => {
        state.followersList = resp.data
    },
    [WORKSHOP.SET_USER_REGISTRATION]: (state, resp) => {
        state.userRegistrations = resp.data
    },
}

const actions = {
    // Workshop actions
    [WORKSHOP.LIST]: ({ commit }, payload) => {
        return WorkshopService.query(payload)
            .then(resp => {
                commit(WORKSHOP.SET_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [WORKSHOP.GET]: ({ commit }, payload) => {
        commit(FETCH_START)
        return WorkshopService.get(payload)
            .then(resp => {
                commit(WORKSHOP.SET_OBJECT, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [WORKSHOP.MY_LIST]: ({ commit }, payload) => {
        return WorkshopService.myWorkshopList(payload)
            .then(resp => {
                commit(WORKSHOP.SET_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [WORKSHOP.MY_GET]: ({ commit }, payload) => {
        commit(FETCH_START)
        return WorkshopService.myWorkshopGet(payload)
            .then(resp => {
                commit(WORKSHOP.SET_OBJECT, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [WORKSHOP.GET_SETTINGS]: ({ commit }, payload) => {
        commit(FETCH_START)
        return WorkshopService.getWorkshopSettings(payload)
            .then(resp => {
                commit(WORKSHOP.SET_SETTINGS, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [WORKSHOP.USER_REGISTRATION]: ({ commit }, payload) => {
        commit(FETCH_START)
        return WorkshopService.userRegistration(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [WORKSHOP.CANCEL_USER_REGISTRATION]: ({ commit }, payload) => {
        commit(FETCH_START)
        return WorkshopService.cancelUserRegistration(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                return err
            })
    },
    [WORKSHOP.GET_USER_REGISTRATION]: ({ commit }, payload) => {
        commit(FETCH_START)
        return WorkshopService.getUserRegistration(payload)
            .then(resp => {
                commit(WORKSHOP.SET_USER_REGISTRATION, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    // SOCIAL actions
    [SOCIAL.LIKE]: ({ commit }, payload) => {
        return WorkshopService.postWorkshopLike(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SOCIAL.COMMENT_LIKE]: ({ commit }, payload) => {
        return WorkshopService.postWorkshopCommentLike(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SOCIAL.GET_LIKE]: ({ commit }, payload) => {
        return WorkshopService.getWorkshopLikes(payload)
            .then(resp => {
                commit(SOCIAL.SET_LIKE_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [SOCIAL.FOLLOWER]: ({ commit }, payload) => {
        return WorkshopService.postWorkshopFollower(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SOCIAL.GET_FOLLOWER]: ({ commit }, payload) => {
        return WorkshopService.getWorkshopFollowers(payload)
            .then(resp => {
                commit(SOCIAL.SET_FOLLOWER_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [SOCIAL.CONTRIBUTOR]: ({ commit }, payload) => {
        return WorkshopService.postWorkshopContributor(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SOCIAL.COMMENT]: ({ commit }, payload) => {
        return WorkshopService.postWorkshopComment(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SOCIAL.GET_COMMENTS]: ({ commit }, payload) => {
        return WorkshopService.getWorkshopComments(payload)
            .then(resp => {
                commit(SOCIAL.SET_COMMENTS_LIST, resp)
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
