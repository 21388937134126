<template>
    <div>
        <div class="comment__row">
            <div class="comment__row--image img-hover">
                <a href="#">
                    <img
                        v-if="comment.user_detail.photo"
                        loading="lazy"
                        :src="comment.user_detail.photo"
                        alt=""/>
                    <img
                        v-else
                        loading="lazy"
                        src="@/assets/images/svg/person.svg"
                        alt=""
                /></a>
            </div>
            <div class="comment__row--detail">
                <div class="comment__row--user">
                    <div class="d-flex justify-content-between">
                        <p class="comment__row--name">
                            {{ comment.user_detail.display_name }}
                        </p>
                        <p class="comment__row--date">
                            {{ comment.created | dateDayMonthYear }}
                        </p>
                    </div>
                </div>
                <p class="comment__row--text">
                    <a
                        v-if="comment.attachment"
                        :href="comment.attachment"
                        target="_blank"
                    >
                        {{ getFileName(comment.attachment) }}
                    </a>
                </p>
                <p class="comment__row--text">{{ comment.comment }}</p>
                <comments-reviews
                    :object="object"
                    :id="id"
                    :comments="comment.children"
                    :likeCount="comment.likes_count"
                    :commentObject="comment"
                    @addComment="$emit('addComment', $event)"
                    @likeComment="$emit('likeComment', $event)"
                ></comments-reviews>
            </div>
        </div>
    </div>
</template>

<script>
import CommentsReviews from '@/common/components/base/comments/CommentsReviews.vue'
export default {
    props: {
        id: Number,
        comment: Object,
        object: Object,
    },
    components: {
        CommentsReviews,
    },
}
</script>

<style lang="scss" scoped>
.comment__row {
    @include flex(flex-start, flex-start);
    flex-wrap: nowrap;
    margin-bottom: rem(25px);
    padding-right: rem(40px);
    &--image {
        flex: 0 0 rem(88px);
        max-width: rem(88px);
        width: rem(88px);
        height: rem(88px);
        overflow: hidden;
        border-radius: 50%;
        margin-right: rem(20px);
        img {
            max-width: 100%;
        }
    }
    &--detail {
        border-bottom: solid 1px #e2e2e2;
        padding-bottom: rem(20px);
        width: 100%;
    }
    &--name {
        color: #323232;
        font-weight: 700;
        font-size: rem(17px);
        margin-bottom: 0px;
    }
    &--date {
        color: #8d8d8d;
        font-size: rem(15px);
        margin-bottom: 0px;
    }
    &--text {
        color: #333333;
        font-size: rem(15px);
        margin-bottom: rem(10px);
        padding-top: rem(3px);
        line-height: 1.4;
    }
    @media screen and (max-width: 1199px) and (min-width: 992px) {
        &--image {
            flex: 0 0 rem(65px);
            max-width: rem(65px);
            width: rem(65px);
            height: rem(65px);
            margin-right: rem(16px);
        }
    }
    @media screen and (max-width: 767px) {
        &--image {
            width: rem(60px);
            height: rem(60px);
            flex: 0 0 rem(60px);
            max-width: rem(60px);
            margin-right: rem(16px);
        }
    }
}

// ar stle

.ar {
    .comment__row {
        padding-left: rem(40px);
        padding-right: 0;
        &--text {
            text-align: right;
        }
        &--image {
            margin-left: rem(20px);
            margin-right: 0;
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
            &--image {
                margin-left: rem(16px);
                margin-right: 0;
            }
        }
        @media screen and (max-width: 767px) {
            &--image {
                margin-left: rem(16px);
                margin-right: 0;
            }
        }
    }
}
</style>
