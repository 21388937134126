export default [
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'ideas',
        name: 'ideas',
        component: () => import('../views/Ideas.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'submit-idea',
        name: 'submit-idea',
        component: () => import('../views/SubmitIdea.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'resubmit-idea/:uuid',
        name: 'resubmit-idea',
        component: () => import('../views/SubmitIdea.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'edit-idea/:uuid',
        name: 'edit-idea',
        component: () => import('../views/SubmitIdea.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'user-ideas',
        name: 'user-ideas',
        component: () => import('../views/UserIdeas.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'user-idea-details/:uuid',
        name: 'user-idea-details',
        component: () => import('../views/UserIdeaDetails.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'idea-details/:uuid',
        name: 'idea-details',
        component: () => import('../views/IdeaDetails.vue'),
    },
]
