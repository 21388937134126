<template>
    <validation-observer ref="observer">
        <b-form
            slot-scope="{ validate }"
            @submit.prevent="validate().then(handleSubmit)"
        >
            <slot></slot>
        </b-form>
    </validation-observer>
</template>
<script>
export default {
    methods: {
        handleSubmit() {
            /**
             * Submit event.
             * @event submit
             */
            this.$emit('submit')
        },
    },
}
</script>
