<template>
    <div class="accountNav" id="accnav">
        <div class="accountNav--wrapper">
            <ul>
                <li v-for="(tab, key) in displayTabs" :key="key">
                    <router-link
                        v-if="tab.page_url === `/${$i18n.locale}/messages`"
                        :to="{
                            name: 'messages',
                            params: {
                                username: 'all',
                            },
                        }"
                    >
                        <img loading="lazy" :src="tab.icon_url" alt="" />
                        <span>{{ tab.name }}</span>
                    </router-link>
                    <a
                        v-else-if="tab.page_url === `/${$i18n.locale}/logout`"
                        href="javascript:void(0)"
                        @click="logout"
                        class="logout"
                    >
                        <img loading="lazy" :src="tab.icon_url" alt="" />
                        <span>{{ tab.name }}</span></a
                    >
                    <router-link
                        v-else
                        :to="{
                            path: tab.page_url,
                        }"
                    >
                        <img loading="lazy" :src="tab.icon_url" alt="" />
                        <span>{{ tab.name }}</span>
                    </router-link>
                </li>
            </ul>
            <button id="toogleBtn" @click="toggleNav">
                <icon loading="lazy" name="toogle-btn" />
            </button>
        </div>
    </div>
</template>

<script>
import { SettingsHelper } from '@/common/crud-helpers/settings'
import Icon from '@/common/layout/Icon'
import { API_BASE } from '@/conf'
import { USERS } from '@/modules/users/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    components: {
        Icon,
    },
    data() {
        return {
            homeUrl: API_BASE + '/' + this.$i18n.locale + '/',
            tabs: [],
        }
    },
    async created() {
        if (!this.dashboardSettings || !this.dashboardSettings.dashboard_tabs) {
            await SettingsHelper.getDashboardSettings()
        } else if (
            this.dashboardSettings &&
            this.dashboardSettings.dashboard_tabs
        ) {
            this.setDashboardTabs()
        }
    },
    computed: {
        ...mapGetters(['dashboardSettings', 'userAuth']),
        displayTabs() {
            return this.tabs.filter(tab => tab.active === true)
        },
    },
    methods: {
        toggleNav: function() {
            let body = document.body
            if (body.classList.contains('accNavClose')) {
                body.classList.remove('accNavClose')
            } else {
                body.classList.add('accNavClose')
            }
        },
        logout(e) {
            e.preventDefault()
            this.$store.dispatch(USERS.AUTH_LOGOUT).then(() => {
                window.location.replace(this.homeUrl + 'logout/')
            })
        },
        setDashboardTabs() {
            this.tabs = []
            let tabs = cloneDeep(this.dashboardSettings.dashboard_tabs)

            tabs.forEach(tab => {
                let url = tab.value.url
                url = url.replace(
                    url.substr(0, url.indexOf('/en/') + 4),
                    '/' + this.$i18n.locale + '/',
                )
                this.tabs.push({
                    name: tab.value[`name_${this.$i18n.locale}`],
                    page_url: url,
                    active: tab.value.active,
                    icon_url: tab.value.icon,
                })
            })
        },
    },
    watch: {
        dashboardSettings() {
            this.setDashboardTabs()
        },
    },
}
</script>

<style lang="scss" scoped>
.accountNav {
    height: 100%;
       background: linear-gradient(145deg, var(--primary) 50%, var(--green) 100%);
   /* background: linear-gradient(
        160deg,
        rgba(26, 26, 118, 1) 0%,
        rgba(104, 23, 142, 1) 100%
    );*/
    transition: 0.4s ease;
    .accountNav--wrapper {
        position: relative;
        ul {
            padding: rem(40px) rem(75px) rem(70px) rem(40px);
            list-style: none;
            li {
                a {
                    font-size: rem(20px);
                    font-weight: 700;
                    color: #bdbbd7;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #FFF;
                    padding: rem(30px) 0;
                    width: 100%;
                    white-space: nowrap;
                    height: 100px;
                    .icon {
                        color: #00f6ff;
                        margin-right: rem(40px);
                        svg {
                            width: rem(50px);
                            height: auto;
                        }
                    }
                    img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7471%) hue-rotate(33deg) brightness(107%) contrast(97%);
                        margin-right: rem(40px);
                        width: rem(50px);
                        height: auto;
                    }
                    @media screen and (min-width: 1025px) {
                        &:hover {
                            color: var(--secondary);
                            text-decoration: none;
                            .icon {
                                color: var(--secondary);
                            }
                            img {
                               /* filter: invert(100%) sepia(100%) saturate(0%)
                                    hue-rotate(93deg) brightness(125%)
                                    contrast(103%);*/
                                    filter: brightness(0) saturate(100%) invert(72%) sepia(73%) saturate(707%) hue-rotate(9deg) brightness(102%) contrast(101%);
                            }
                        }
                    }
                    &.logout {
                        /deep/ svg {
                            transform: rotate(180deg);
                        }
                        /deep/ img {
                            transform: rotate(180deg);
                        }
                    }
                }
                &:last-child {
                    a {
                        border-bottom: 0px;
                    }
                }
                a.router-link-active {
                    color: #fff;
                    .icon {
                        color: #fff;
                    }
                    img {
                        filter: brightness(0) saturate(100%) invert(78%) sepia(53%) saturate(2603%) hue-rotate(13deg) brightness(104%) contrast(108%);
                    }
                }
            }
        }
        button {
            position: absolute;
            top: 0;
            right: 0;
            background-color: var(--secondary);
            width: rem(60px);
            height: rem(60px);
            border: none;
            @media screen and (min-width: 1025px) {
                &:hover {
                    background: var(--green);
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        .accountNav--wrapper {
            ul {
                padding-left: rem(20px) !important;
                padding-right: rem(20px) !important;
                li {
                    a {
                        height: 65px;
                        .icon {
                            svg {
                                width: rem(40px);
                            }
                        }
                        img {
                            width: rem(40px);
                            min-height: rem(40px);
                        }
                    }
                    span {
                        opacity: 0;
                    }
                }
            }
            button {
                width: rem(45px);
                height: rem(45px);
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

// ar style

.ar .accountNav .accountNav--wrapper button {
    left: 0;
    right: auto;
    svg {
        transform: rotate(180deg);
    }
}

.ar .accountNav .accountNav--wrapper ul {
    padding: 2.5rem 2.5rem 4.375rem 4.6875rem;
}
.ar .accountNav .accountNav--wrapper ul li a .icon {
    margin-left: 2.5rem;
    margin-right: 0;
}
.ar .accountNav .accountNav--wrapper ul li a img {
    margin-left: 2.5rem;
    margin-right: 0;
}

.ar .accountNav .accountNav--wrapper ul li a img {
    transform: rotate(0deg);
}
.ar .accNavClose .accountNav--wrapper ul {
    padding-left: 0.625rem !important;
    padding-right: 2.5rem !important;
}
@media screen and (max-width: 991px) {
    .ar .accNavClose .accountNav--wrapper ul {
        padding-left: rem(20px) !important;
        padding-right: rem(20px) !important;
    }
    .ar {
        .accountNav--wrapper {
            button {
                svg {
                    transform: rotate(0deg) !important;
                }
            }
        }
    }
}
</style>
