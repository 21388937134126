<template>
    <validation-provider
        :rules="`${[required ? 'required' : '']}`"
        :name="name"
    >
        <b-form-group slot-scope="{ valid, errors }">
            <div class="multiple-dropdown">
                <b-dropdown
                    v-model="selectedOptions"
                    toggle-class="search-dropdown"
                >
                    <template #button-content>
                        <span v-if="!selectedOptions.length">{{
                            placeholder
                        }}</span>
                        <div class="selected-options">
                            <span
                                v-for="option in selectedOptions"
                                :key="option"
                                class="selected-option"
                            >
                                {{ option }}
                                <span
                                    class="remove-option"
                                    @click.stop="toggleOption(option)"
                                    >&times;</span
                                >
                            </span>
                        </div>
                    </template>
                    <b-dropdown-item
                        v-for="option in newOptions"
                        :key="option"
                        :value="option"
                        @click.stop="toggleOption(option)"
                    >
                        {{ option }}
                    </b-dropdown-item>
                </b-dropdown>
                <span
                    class="invalid-feedback"
                    v-if="errors.length && !selectedOptions.length"
                    >{{ errors[0] }}</span
                >
            </div>
        </b-form-group>
    </validation-provider>
</template>

<script>
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'

export default {
    props: {
        name: String,
        value: [Number, String, Array],
        options: {
            required: true,
            type: Array,
        },
        placeholder: {
            required: false,
            default: 'Select',
        },
        required: {
            type: Boolean,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            newOptions: [],
            selectedOptions: [],
            values: null,
        }
    },
    created() {
        this.values = this.value
        this.setOptions()
    },
    methods: {
        toggleOption(option) {
            if (_.includes(this.selectedOptions, option)) {
                this.selectedOptions = this.selectedOptions.filter(
                    item => item !== option,
                )
            } else {
                this.selectedOptions.push(option)
            }
            if (_.includes(this.newOptions, option)) {
                this.newOptions = this.newOptions.filter(
                    item => item !== option,
                )
            } else {
                this.newOptions.push(option)
            }

            this.$emit('input', this.selectedOptions, this.name)
        },

        setOptions() {
            if (!this.values) {
                this.values = []
                this.newOptions = cloneDeep(this.options)
            } else {
                this.newOptions = this.options.filter(
                    option => !_.includes(this.values, option),
                )
                this.selectedOptions = cloneDeep(this.values)
            }
        },
        getError(error) {
            if (this.$te(error)) {
                return this.$t(error)
            } else {
                return error
            }
        },
    },
    watch: {
        options() {
            this.setOptions()
        },
        value() {
            this.values = this.value
            this.setOptions()
        },
    },
}
</script>

<style scoped lang="scss">
.multiple-dropdown {
    /deep/ .dropdown {
        background: white !important;
        width: 100%;
        min-height: 60px;
        height: auto;
        .btn-secondary.dropdown-toggle {
            text-align: start;
            color: black;
            @media screen and (max-width: 575px) {
                font-size: 14px;
            }
        }
        .dropdown-menu {
            width: 100%;
            .dropdown-item {
                height: 40px;
                color: #000;
                font-size: 1.125rem;
                font-weight: 600;
                padding: 1rem 1.375rem;
                &:hover {
                    background-color: var(--primary) !important;
                }
            }
        }
        .dropdown-toggle:after {
            right: 25px;
            width: 27px;
            height: 17px;
        }
    }
    .selected-options {
        display: flex;
        flex-wrap: wrap;
        padding-right: 30px;
        .selected-option {
            display: inline-flex;
            align-items: center;
            padding: 4px 8px;
            margin: 2px;
            background-color: var(--primary);
            color: black;
            border-radius: 4px;
            .remove-option {
                cursor: pointer;
                margin-left: 4px;
            }
        }
    }
    .invalid-feedback {
        display: block;
    }
}
.ar {
    .multiple-dropdown {
        /deep/ .dropdown {
            .dropdown-toggle:after {
                left: 25px;
                right: auto;
            }
        }
        .selected-options {
            padding-right: 0px;
            padding-left: 30px;
        }
    }
}
</style>
