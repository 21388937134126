var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{style:(_vm.isNewsLetterModalOpen ? { 'z-index': '999' } : { 'z-index': '5' })},[_c('div',{staticClass:"footer--wrapper"},[(_vm.footerSettings && _vm.footerSettings.show_social_media)?_c('div',{staticClass:"footer--social"},[_c('ul',_vm._l((_vm.socials),function(social,key){return _c('li',{key:key,style:({
                        display: ("" + (social.value.enable ? 'list-item' : 'none')),
                    })},[(social.value.text == 'Rss')?_c('a',{attrs:{"href":social.value.url}},[_c('i',{staticClass:"fa",class:[social.value.font_awesome_class],attrs:{"loading":"lazy"}})]):_c('a',{attrs:{"href":social.value.url}},[_c('i',{staticClass:"fab",class:[social.value.font_awesome_class],attrs:{"loading":"lazy"}})])])}),0)]):_vm._e(),(_vm.footerSettings && _vm.footerSettings.show_link_section)?_c('div',{staticClass:"footer--links"},[_c('ul',_vm._l((_vm.footersList),function(page,key){return _c('li',{key:key},[(
                            page.login_requird &&
                                page.active &&
                                _vm.userAuth &&
                                _vm.userAuth.id
                        )?_c('a',{on:{"click":function($event){return _vm.redirectToCMS(page.url)}}},[_vm._v(" "+_vm._s(page.name)+" ")]):(!page.login_requird && page.active)?_c('a',{on:{"click":function($event){return _vm.redirectToCMS(page.url)}}},[_vm._v(" "+_vm._s(page.name)+" ")]):_vm._e()])}),0)]):_vm._e()]),(_vm.footerSettings && _vm.footerSettings.show_copyright)?_c('div',{staticClass:"copyright"},[_c('p',[_vm._v(" © "+_vm._s(_vm.currentYear)+" "+_vm._s(_vm.$t('Copyright Zain All Rights Reserved'))+" ")])]):_vm._e(),(_vm.isNewsLetterModalOpen)?_c('div',{staticClass:"newsletter"},[_c('a',{staticClass:"newsletterClose",on:{"click":function($event){_vm.isNewsLetterModalOpen = !_vm.isNewsLetterModalOpen}}},[_c('svg',{attrs:{"fill":"currentColor","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","viewBox":"0 0 409.81 409.81"}},[_c('use',{attrs:{"xlink:href":"#menu-close-icon"}})])]),_c('h6',[_vm._v("SIGN UP FOR UPDATES")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.subscribeNewsLetter()}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.subscriptionEmail),expression:"subscriptionEmail"}],staticClass:"form-control is-invalid",attrs:{"type":"email","name":"email_field","placeholder":"youremail@domain.com"},domProps:{"value":(_vm.subscriptionEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.subscriptionEmail=$event.target.value}}}),(_vm.subscriptionResponse.length)?_c('div',{staticClass:"newsletter-message"},[_c('h2',[_c('b',[_vm._v(_vm._s(_vm.subscriptionResponse))])])]):_vm._e(),_c('p'),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("SUBSCRIBE")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }