import { IdeasService } from '@/common/api_services/ideas'
import { FETCH_SUCCESS, FETCH_ERROR, FETCH_START } from '@/store/common'

import { IDEAS, SOCIAL } from './actions'

const state = {
    ideasList: [],
    opcoIdeaCategories: [],
    activeIdeaCategories: [],
    activeOpcoChoices: [],
    ideaObject: {},
    ideasTrendList: [],
    commentsList: [],
    likesList: [],
    followersList: [],
    contributorsList: [],
    ideaFormFields: [],
    bucketCategoriesList: {},
    ideasStatusList: {
        submitted: {
            text: 'Submitted',
        },
        planned: {
            text: 'Planned',
        },
        new: {
            text: 'New',
        },
        workshop: {
            text: 'Idea Workshop',
        },
        approved: {
            text: 'Approved',
        },
        pitching: {
            text: 'Pitching',
        },
        rejected: {
            text: 'Rejected',
        },
        funded: {
            text: 'Funded',
        },
        resubmit: {
            text: 'Resubmit',
        },
        mentorship: {
            text: 'Idea Mentorship',
        },
        implemented: {
            text: 'Implemented',
        },
        dismissed: {
            text: 'Dismissed',
        },
        resubmitted: {
            text: 'Resubmitted',
        },
        attempted: {
            text: 'Attempted',
        },
        reviewed: {
            text: 'Reviewed',
        },
    },
}

const getters = {
    ideasList: state => state.ideasList,
    opcoIdeaCategories: state => state.opcoIdeaCategories,
    ideaObject: state => state.ideaObject,
    ideasTrendList: state => state.ideasTrendList,
    commentsList: state => state.commentsList,
    likesList: state => state.likesList,
    followersList: state => state.followersList,
    contributorsList: state => state.contributorsList,
    ideasStatusList: state => state.ideasStatusList,
    activeIdeaCategories: state => state.activeIdeaCategories,
    activeOpcoChoices: state => state.activeOpcoChoices,
    ideaFormFields: state => state.ideaFormFields,
    bucketCategoriesList: state => state.bucketCategoriesList,
}

const mutations = {
    [IDEAS.SET_LIST]: (state, resp) => {
        state.ideasList = resp.data
    },
    [IDEAS.SET_IDEA_FORM_FIELDS]: (state, resp) => {
        state.ideaFormFields = resp.data
    },
    [IDEAS.SET_OPCO_IDEA_CATEGORY]: (state, resp) => {
        state.opcoIdeaCategories = resp.data
    },
    [IDEAS.SET_ACTIVE_IDEA_CATEGORIES]: (state, resp) => {
        state.activeIdeaCategories = resp.data
    },
    [IDEAS.SET_ACTIVE_OPCO_CHOICES]: (state, resp) => {
        state.activeOpcoChoices = resp.data
    },
    [IDEAS.SET_OBJECT]: (state, resp) => {
        state.ideaObject = resp.data
    },
    [IDEAS.SET_TREND_LIST]: (state, resp) => {
        state.ideasTrendList = resp.data
    },
    [IDEAS.SET_BUCKET_CATEGORIES_LIST]: (state, resp) => {
        state.bucketCategoriesList = resp.data
    },
    [SOCIAL.SET_COMMENTS_LIST]: (state, resp) => {
        state.commentsList = resp.data
    },
    [SOCIAL.SET_LIKE_LIST]: (state, resp) => {
        state.likesList = resp.data
    },
    [SOCIAL.SET_FOLLOWER_LIST]: (state, resp) => {
        state.followersList = resp.data
    },
    [SOCIAL.SET_CONTRIBUTOR_LIST]: (state, resp) => {
        state.contributorsList = resp.data
    },
}

const actions = {
    // My ideas actions
    [IDEAS.LIST]: ({ commit }, payload) => {
        // commit(FETCH_START);
        return IdeasService.query(payload)
            .then(resp => {
                commit(IDEAS.SET_LIST, resp)
                commit(FETCH_SUCCESS)
                return
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [IDEAS.GET]: ({ commit }, payload) => {
        return IdeasService.get(payload)
            .then(resp => {
                commit(IDEAS.SET_OBJECT, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [IDEAS.GET_IDEA_FORM_FIELDS]: ({ commit }) => {
        return IdeasService.getIdeaFormFields()
            .then(resp => {
                commit(IDEAS.SET_IDEA_FORM_FIELDS, resp)
                commit(FETCH_SUCCESS)
                return resp.data
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [IDEAS.CREATE]: ({ commit }, payload) => {
        // commit(FETCH_START);
        return IdeasService.create(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [IDEAS.PUT]: ({ commit }, payload) => {
        commit(FETCH_START)
        return IdeasService.put(payload.uuid, payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [IDEAS.DELETE]: ({ commit }, payload) => {
        commit(FETCH_START)
        return IdeasService.destroy(payload)
            .then(() => {
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    // Ideas Trend actions
    [IDEAS.LIST_TREND]: ({ commit }, payload) => {
        commit(FETCH_START)
        return IdeasService.queryTrendIdea(payload)
            .then(resp => {
                commit(IDEAS.SET_TREND_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [IDEAS.GET_TREND]: ({ commit }, payload) => {
        commit(FETCH_START)
        return IdeasService.getTrendIdea(payload)
            .then(resp => {
                commit(IDEAS.SET_OBJECT, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },

    // Ideas actions
    [IDEAS.LIST_IDEA]: ({ commit }, payload) => {
        // commit(FETCH_START);
        return IdeasService.queryIdea(payload)
            .then(resp => {
                commit(IDEAS.SET_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [IDEAS.OPCO_IDEA_CATEGORY]: ({ commit }, payload) => {
        return IdeasService.getOpcoIdeaCategory(payload)
            .then(resp => {
                commit(IDEAS.SET_OPCO_IDEA_CATEGORY, resp)
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [IDEAS.ACTIVE_IDEA_CATEGORIES]: ({ commit }, payload) => {
        return IdeasService.getActiveIdeaCategories(payload)
            .then(resp => {
                commit(IDEAS.SET_ACTIVE_IDEA_CATEGORIES, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [IDEAS.GET_ACTIVE_OPCO_CHOICES]: ({ commit }, payload) => {
        return IdeasService.getActiveOpcoChoices(payload)
            .then(resp => {
                commit(IDEAS.SET_ACTIVE_OPCO_CHOICES, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [IDEAS.GET_IDEA]: ({ commit }, payload) => {
        return IdeasService.getIdea(payload)
            .then(resp => {
                commit(IDEAS.SET_OBJECT, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [IDEAS.GET_BY_ID_IDEA]: ({ commit }, payload) => {
        return IdeasService.getByIdIdea(payload)
            .then(resp => {
                commit(IDEAS.SET_OBJECT, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [IDEAS.GET_BUCKET_CATEGORIES_LIST]: ({ commit }, payload) => {
        return IdeasService.getBucketCategoriesList(payload)
            .then(resp => {
                commit(IDEAS.SET_BUCKET_CATEGORIES_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [IDEAS.DELETE_IDEA_DOCUMENT]: ({ commit }, payload) => {
        return IdeasService.deleteIdeaDocument(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [IDEAS.UPDATE_IDEA_DOCUMENT]: ({ commit }, payload) => {
        return IdeasService.updateIdeaDocument(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err.response.data
            })
    },
    [IDEAS.UPDATE_IDEA_MEDIA]: ({ commit }, payload) => {
        return IdeasService.updateIdeaMedia(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err.response.data
            })
    },
    // SOCIAL actions
    [SOCIAL.LIKE]: ({ commit }, payload) => {
        return IdeasService.postLike(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SOCIAL.COMMENT_LIKE]: ({ commit }, payload) => {
        return IdeasService.postCommentLike(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SOCIAL.GET_LIKE]: ({ commit }, payload) => {
        return IdeasService.getLikes(payload)
            .then(resp => {
                commit(SOCIAL.SET_LIKE_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [SOCIAL.FOLLOWER]: ({ commit }, payload) => {
        return IdeasService.postFollower(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SOCIAL.GET_FOLLOWER]: ({ commit }, payload) => {
        return IdeasService.getFollowers(payload)
            .then(resp => {
                commit(SOCIAL.SET_FOLLOWER_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [SOCIAL.CONTRIBUTOR]: ({ commit }, payload) => {
        return IdeasService.postContributor(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SOCIAL.GET_CONTRIBUTOR]: ({ commit }, payload) => {
        return IdeasService.getContributors(payload)
            .then(resp => {
                commit(SOCIAL.SET_CONTRIBUTOR_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [SOCIAL.COMMENT]: ({ commit }, payload) => {
        return IdeasService.postComment(payload)
            .then(resp => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SOCIAL.GET_COMMENTS]: ({ commit }, payload) => {
        return IdeasService.getComments(payload)
            .then(resp => {
                commit(SOCIAL.SET_COMMENTS_LIST, resp)
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(err => {
                commit(FETCH_ERROR)
                throw err
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
