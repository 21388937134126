export const USERS = {
    SIGNUP_NEW_USER: 'signupNewUser',
    LOGIN_USER: 'loginUser',
    REFRESH_TOKEN: 'refreshToken',
    RESET_PASSWORD: 'resetPassword',
    AUTH_LOGOUT: 'authLogout',
    SET_AUTH_LOGOUT: 'setAuthLogout',
    SET_TOKENS: 'setTokens',
    GET_AUTH_USER: 'getAuthUser',
    SET_AUTH_USER: 'setAuthUser',
    LIST: 'getUsersList',
    SET_LIST: 'setUsersList',
    GET_USER: 'getUser',
    SET_USER: 'setUser',
    GET_ZAINERS: 'getZainers',
    SET_ZAINERS: 'setZainers',
    UPDATE_USER: 'updateUser',
    GET_ZAINER: 'getZainer',
    SET_ZAINER: 'setZainer',
    SUBSCRIBE: 'subscribe',
    SUBMIT_FEEDBACK: 'submitFeedBack',
    GET_BADGES: 'getBadges',
    SET_BADGES: 'setBadges',
    GET_OPCO_RANKING: 'getOpcoRanking',
    SET_OPCO_RANKING: 'setOpcoRanking',
    GET_CAMPAIGNS: 'getCampaigns',
    SET_CAMPAIGNS: 'setCampaigns',
}
