var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"drop-zone"},[_c('div',[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.label))])]),(_vm.dropzonBox)?_c('vue-dropzone',{ref:"vueDropzone",attrs:{"id":"dropzone","options":_vm.dropzoneOptions,"useCustomSlot":true},on:{"vdropzone-file-added":_vm.someFileAdde,"vdropzone-success":_vm.someSuccessMethod,"vdropzone-thumbnail":_vm.thumbnail}},[_c('div',{staticClass:"dropzone-custom-content",class:{ disabled: _vm.disabled }},[_c('img',{attrs:{"loading":"lazy","src":require("@/assets/images/svg/upload-icon.svg"),"alt":""}}),_c('h3',{staticClass:"dropzone-custom-title"},[_vm._v(" "+_vm._s(_vm.$t('Drag and Drop your file in this area or'))+" ")]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t('Browse Files')))])])]):_vm._e()],1),_c('span',{staticClass:"invalid-feedback",style:({ display: _vm.error ? 'block' : '' })},[_vm._v(_vm._s(_vm.error))]),(_vm.sortable)?_c('draggable',{staticClass:"filesList sortable",attrs:{"list":_vm.uploadedFiles,"disabled":_vm.isWorkingOnDocument},on:{"change":_vm.updateListSortOrder}},_vm._l((_vm.uploadedFiles),function(uploadedFile,k){return _c('div',{key:k,staticClass:"filesList__items"},[_c('div',{staticClass:"filesList__items--image"},[(uploadedFile.filename.indexOf('pdf') > -1)?_c('img',{attrs:{"loading":"lazy","src":require("@/assets/images/svg/pdf2.svg"),"alt":""}}):(
                        uploadedFile.filename.indexOf('mp4') > -1 ||
                            uploadedFile.filename.indexOf('webm') > -1
                    )?_c('img',{attrs:{"loading":"lazy","src":require("@/assets/images/svg/video.svg"),"alt":""}}):_c('img',{attrs:{"loading":"lazy","src":_vm.thumbnails[k],"alt":""}}),_c('p',[_vm._v(_vm._s(uploadedFile.filename))])]),(_vm.showChangeName)?_c('base-input',{attrs:{"name":"name","placeholder":_vm.$t('Please Enter file name'),"maxlength":"50","infolabel":("50 " + (_vm.$t('characters'))),"value":uploadedFile.name},on:{"input":function($event){return _vm.$emit('changeFileName', $event, k)},"onBlur":function($event){return _vm.$emit(
                        'onBlur',
                        $event && $event.target && $event.target.value
                            ? $event.target.value
                            : '',
                        k
                    )}},model:{value:(uploadedFile.name),callback:function ($$v) {_vm.$set(uploadedFile, "name", $$v)},expression:"uploadedFile.name"}}):_vm._e(),(!_vm.hideDeleteButton || !uploadedFile.created)?_c('div',{staticClass:"filesList__items--delete",class:{
                    'is-working-on-document': _vm.isWorkingOnDocument,
                }},[_c('a',{on:{"click":function($event){return _vm.deleteFile(k)}}},[_vm._v(_vm._s(_vm.$t('Delete')))])]):_vm._e()],1)}),0):_c('div',{staticClass:"filesList"},[_c('div',_vm._l((_vm.uploadedFiles),function(uploadedFile,k){return _c('div',{key:k,staticClass:"filesList__items"},[_c('div',{staticClass:"filesList__items--image"},[(uploadedFile.filename.indexOf('pdf') > -1)?_c('img',{attrs:{"loading":"lazy","src":require("@/assets/images/svg/pdf2.svg"),"alt":""}}):(
                            uploadedFile.filename.indexOf('mp4') > -1 ||
                                uploadedFile.filename.indexOf('webm') > -1
                        )?_c('img',{attrs:{"loading":"lazy","src":require("@/assets/images/svg/video.svg"),"alt":""}}):_c('img',{attrs:{"loading":"lazy","src":_vm.thumbnails[k],"alt":""}}),_c('p',[_vm._v(_vm._s(uploadedFile.filename))])]),(_vm.showChangeName)?_c('base-input',{attrs:{"name":"name","placeholder":_vm.$t('Please Enter file name'),"maxlength":"50","infolabel":("50 " + (_vm.$t('characters'))),"value":uploadedFile.name},on:{"input":function($event){return _vm.$emit('changeFileName', $event, k)},"onBlur":function($event){return _vm.$emit(
                            'onBlur',
                            $event && $event.target && $event.target.value
                                ? $event.target.value
                                : '',
                            k
                        )}}}):_vm._e(),(!_vm.hideDeleteButton || !uploadedFile.created)?_c('div',{staticClass:"filesList__items--delete",class:{
                        'is-working-on-document': _vm.isWorkingOnDocument,
                    }},[_c('a',{on:{"click":function($event){return _vm.deleteFile(k)}}},[_vm._v(_vm._s(_vm.$t('Delete')))])]):_vm._e()],1)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }