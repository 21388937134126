<template>
    <div>
        <div
            class="scrollWrapper likes"
            :style="
                heightCondition
                    ? { height: 'auto', margin: '2.5rem 0 10rem 0' }
                    : { height: '580px' }
            "
        >
            <scrollbar :ops="scrollSettings" @handle-scroll="handleScroll">
                <div class="users">
                    <div
                        class="users__item"
                        v-for="(follower, k) in followers"
                        :key="k"
                    >
                        <base-user-thumb
                            :user="follower.user_detail"
                        ></base-user-thumb>
                    </div>
                </div>
                <base-loader v-if="loadding" />
            </scrollbar>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        followers: Array,
        loadding: Boolean,
        heightCondition: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            scrollSettings: {
                maxHeight: 250,
            },
        }
    },
    methods: {
        handleScroll(vertical) {
            if (vertical.process === 1) {
                this.$emit('loadMore')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.users {
    @include flex(flex-start, flex-start);
    &__item {
        width: 33.3%;
        margin-bottom: rem(20px);
        &:not(:last-child) {
            padding-right: rem(70px);
        }
        &:last-child {
            padding-right: rem(70px);
        }
    }
    @media screen and (max-width: 767px) {
        &__item {
            width: 50%;
            padding-right: rem(40px);
        }
    }
    @media screen and (max-width: 575px) {
        &__item {
            width: 100%;
            padding-right: rem(20px);
        }
    }
}
.scrollWrapper {
    height: 580px;
}

// ar style

.ar {
    .users {
        &__item {
            &:not(:last-child) {
                padding-left: rem(70px);
                padding-right: 0;
            }
            &:last-child {
                padding-left: rem(70px);
                padding-right: 0;
            }
        }
        @media screen and (max-width: 767px) {
            &__item {
                padding-left: rem(40px);
                padding-right: 0;
            }
        }
        @media screen and (max-width: 575px) {
            &__item {
                padding-left: rem(20px);
                padding-right: 0;
            }
        }
    }
}
</style>
