// Array of country objects

// Each country array has the following information:
// [
//    Country name,
//    iso2 code,
//    text,
//    value
// ]
const allCountries = [
    ['Afghanistan', 'af', '+93', '+93'],
    ['Albania', 'al', '+355', '+355'],
    ['Algeria', 'dz', '+213', '+213'],
    // ['American Samoa', 'as', '+1-684', '+1-684'],
    ['Andorra', 'ad', '+376', '+376'],
    ['Angola', 'ao', '+244', '+244'],
    ['Anguilla', 'ai', '+1-264', '+1-264'],
    ['Antigua and Barbuda', 'ag', '+1-268', '+1-268'],
    ['Argentina', 'ar', '+54', '+54'],
    ['Armenia', 'am', '+374', '+374'],
    ['Aruba', 'aw', '+297', '+297'],
    ['Australia', 'au', '+61', '+61'],
    ['Austria', 'at', '+43', '+43'],
    ['Azerbaijan', 'az', '+994', '+994'],
    ['Bahamas', 'bs', '+1-242', '+1-242'],
    ['Bahrain', 'bh', '+973', '+973'],
    ['Bangladesh', 'bd', '+880', '+880'],
    ['Barbados', 'bb', '+1-246', '+1-246'],
    ['Belarus', 'by', '+375', '+375'],
    ['Belgium', 'be', '+32', '+32'],
    ['Belize', 'bz', '+501', '+501'],
    ['Benin', 'bj', '+229', '+229'],
    ['Bermuda', 'bm', '+1-441', '+1-441'],
    ['Bhutan', 'bt', '+975', '+975'],
    ['Bolivia', 'bo', '+591', '+591'],
    ['Bosnia and Herzegovina', 'ba', '+387', '+387'],
    ['Botswana', 'bw', '+267', '+267'],
    ['Brazil', 'br', '+55', '+55'],
    ['British Indian Ocean Territory', 'io', '+246', '+246'],
    ['British Virgin Islands', 'vg', '+1-284', '+1-284'],
    ['Brunei', 'bn', '+673', '+673'],
    ['Bulgaria', 'bg', '+359', '+359'],
    ['Burkina Faso', 'bf', '+226', '+226'],
    ['Burundi', 'bi', '+257', '+257'],
    ['Cambodia', 'kh', '+855', '+855'],
    ['Cameroon', 'cm', '+237', '+237'],
    ['Canada', 'ca', '+1', '+1'],
    ['Cape Verde', 'cv', '+238', '+238'],
    ['Caribbean Netherlands', 'bq', '+599', '+599'],
    ['Cayman Islands', 'ky', '+1-345', '+1-345'],
    ['Central African Republic', 'cf', '+236', '+236'],
    ['Chad', 'td', '+235', '+235'],
    ['Chile', 'cl', '+56', '+56'],
    ['China', 'cn', '+86', '+86'],
    ['Christmas Island', 'cx', '+61', '+61'],
    // ['Cocos (Keeling) Islands', 'cc', '61', 1],
    ['Colombia', 'co', '+57', '+57'],
    ['Comoros', 'km', '+269', '+269'],
    ['Congo', 'cd', '+243', '+243'],
    ['Congo (Republic) (Congo-Brazzaville)', 'cg', '+242', '+242'],
    ['Cook Islands', 'ck', '+682', '+682'],
    ['Costa Rica', 'cr', '+506', '+506'],
    ['Côte d’Ivoire', 'ci', '+225', '+225'],
    ['Croatia', 'hr', '+385', '+385'],
    ['Cuba', 'cu', '+53', '+53'],
    ['Curaçao', 'cw', '+599', '+599'],
    ['Cyprus', 'cy', '+357', '+357'],
    ['Czech Republic', 'cz', '+420', '+420'],
    ['Denmark', 'dk', '+45', '+45'],
    ['Djibouti', 'dj', '+253', '+253'],
    ['Dominica', 'dm', '+1-767', '+1-767'],
    ['Ecuador', 'ec', '+593', '+593'],
    ['Egypt ', 'eg', '+20', '+20'],
    ['El Salvador', 'sv', '+503', '+503'],
    ['Equatorial Guinea', 'gq', '+240', '+240'],
    ['Eritrea', 'er', '+291', '+291'],
    ['Estonia', 'ee', '+372', '+372'],
    ['Ethiopia', 'et', '+251', '+251'],
    ['Falkland Islands', 'fk', '+500', '+500'],
    ['Faroe Islands', 'fo', '+298', '+298'],
    ['Fiji', 'fj', '+679', '+679'],
    ['Finland', 'fi', '+358', '+358'],
    ['France', 'fr', '+33', '+33'],
    ['French Guiana', 'gf', '+594', '+594'],
    ['French Polynesia', 'pf', '+689', '+689'],
    ['Gabon', 'ga', '+241', '+241'],
    ['Gambia', 'gm', '+220', '+220'],
    ['Georgia', 'ge', '+995', '+995'],
    ['Germany', 'de', '+49', '+49'],
    ['Ghana', 'gh', '+233', '+233'],
    ['Gibraltar', 'gi', '+350', '+350'],
    ['Greece', 'gr', '+30', '+30'],
    ['Greenland', 'gl', '+299', '+299'],
    ['Grenada', 'gd', '+1-473', '+1-473'],
    ['Guadeloupe', 'gp', '+590', '+590'],
    ['Guam', 'gu', '+1-671', '+1-671'],
    ['Guatemala', 'gt', '+502', '+502'],
    ['Guernsey', 'gg', '+44', '+44'],
    ['Guinea', 'gn', '+224', '+224'],
    ['Guinea-Bissau', 'gw', '+245', '+245'],
    ['Guyana', 'gy', '+592', '+592'],
    ['Haiti', 'ht', '+509', '+509'],
    ['Honduras', 'hn', '+504', '+504'],
    ['Hong Kong', 'hk', '+852', '+852'],
    ['Hungary', 'hu', '+36', '+36'],
    ['Iceland', 'is', '+354', '+354'],
    ['India', 'in', '+91', '+91'],
    ['Indonesia', 'id', '+62', '+62'],
    ['Iran', 'ir', '+98', '+98'],
    ['Iraq', 'iq', '+964', '+964'],
    ['Ireland', 'ie', '+353', '+353'],
    ['Isle of Man', 'im', '+44', '+44'],
    ['Israel', 'il', '+972', '+972'],
    ['Italy', 'it', '+39', '+39'],
    ['Jamaica', 'jm', '+1-876', '+1-876'],
    ['Japan', 'jp', '+81', '+81'],
    ['Jersey', 'je', '+44', '+44'],
    ['Jordan', 'jo', '+962', '+962'],
    // ['Kazakhstan (Казахстан)', 'kz', '+7', '+7'],
    ['Kenya', 'ke', '+254', '+254'],
    ['Kiribati', 'ki', '+686', '+686'],
    ['Kosovo', 'xk', '+383', '+383'],
    ['Kuwait', 'kw', '+965', '+965'],
    ['Kyrgyzstan', 'kg', '+996', '+996'],
    ['Laos', 'la', '+856', '+856'],
    ['Latvia', 'lv', '+371', '+371'],
    ['Lebanon', 'lb', '+961', '+961'],
    ['Lesotho', 'ls', '+266', '+266'],
    ['Liberia', 'lr', '+231', '+231'],
    ['Libya', 'ly', '+218', '+218'],
    ['Liechtenstein', 'li', '+423', '+423'],
    ['Lithuania', 'lt', '+370', '+370'],
    ['Luxembourg', 'lu', '+352', '+352'],
    ['Macau', 'mo', '+853', '+853'],
    ['Macedonia', 'mk', '+389', '+389'],
    ['Madagascar', 'mg', '+261', '+261'],
    ['Malawi', 'mw', '+265', '+265'],
    ['Malaysia', 'my', '+60', '+60'],
    ['Maldives', 'mv', '+960', '+960'],
    ['Mali', 'ml', '+223', '+223'],
    ['Malta', 'mt', '+356', '+356'],
    ['Marshall Islands', 'mh', '+692', '+692'],
    ['Martinique', 'mq', '+596', '+596'],
    ['Mauritania', 'mr', '+222', '+222'],
    ['Mauritius', 'mu', '+230', '+230'],
    ['Mayotte', 'yt', '+262', '+262'],
    ['Mexico', 'mx', '+52', '+52'],
    ['Micronesia', 'fm', '+691', '+691'],
    ['Moldova', 'md', '+373', '+373'],
    ['Monaco', 'mc', '+377', '+377'],
    ['Mongolia', 'mn', '+976', '+976'],
    ['Montenegro', 'me', '+382', '+382'],
    ['Montserrat', 'ms', '+1-664', '+1-664'],
    ['Morocco', 'ma', '+212', '+212'],
    ['Mozambique', 'mz', '+258', '+258'],
    ['Myanmar', 'mm', '+95', '+95'],
    ['Namibia', 'na', '+264', '+264'],
    ['Nauru', 'nr', '+674', '+674'],
    ['Nepal', 'np', '+977', '+977'],
    ['Netherlands', 'nl', '+31', '+31'],
    ['New Caledonia', 'nc', '+687', '+687'],
    ['New Zealand', 'nz', '+64', '+64'],
    ['Nicaragua', 'ni', '+505', '+505'],
    ['Niger', 'ne', '+227', '+227'],
    ['Nigeria', 'ng', '+234', '+234'],
    ['Niue', 'nu', '+683', '+683'],
    ['Norfolk Island', 'nf', '+672', '+672'],
    ['North Korea', 'kp', '+850', '+850'],
    ['Northern Mariana Islands', 'mp', '+1-670', '+1-670'],
    ['Norway', 'no', '+47', '+47'],
    ['Oman', 'om', '+968', '+968'],
    ['Pakistan', 'pk', '+92', '+92'],
    ['Palau', 'pw', '+680', '+680'],
    ['Palestine', 'ps', '+970', '+970'],
    ['Panama', 'pa', '+507', '+507'],
    ['Papua New Guinea', 'pg', '+675', '+675'],
    ['Paraguay', 'py', '+595', '+595'],
    ['Peru', 'pe', '+51', '+51'],
    ['Philippines', 'ph', '+63', '+63'],
    ['Poland', 'pl', '+48', '+48'],
    ['Portugal', 'pt', '+351', '+351'],
    // ['Puerto Rico', 'pr', '1', 3, ['+787', '+939']],
    ['Qatar', 'qa', '+974', '+974'],
    ['Réunion', 're', '+262', '+262'],
    ['Romania', 'ro', '+40', '+40'],
    ['Russia', 'ru', '+7', '+7'],
    ['Rwanda', 'rw', '+250', '+250'],
    ['Saint Barthélemy', 'bl', '+590', '+590'],
    ['Saint Helena', 'sh', '+290', '+290'],
    ['Saint Kitts and Nevis', 'kn', '+1-869', '+1-869'],
    ['Saint Lucia', 'lc', '+1-758', '+1-758'],
    ['Saint Martin', 'mf', '+590', '+590'],
    ['Saint Pierre and Miquelon', 'pm', '+508', '+508'],
    ['Saint Vincent and the Grenadines', 'vc', '+1-784', '+1-784'],
    ['Samoa', 'ws', '+685', '+685'],
    ['San Marino', 'sm', '+378', '+378'],
    ['São Tomé and Príncipe ', 'st', '+239', '+239'],
    ['Saudi Arabia', 'sa', '+966', '+966'],
    ['Senegal', 'sn', '+221', '+221'],
    ['Serbia', 'rs', '+381', '+381'],
    ['Seychelles', 'sc', '+248', '+248'],
    ['Sierra Leone', 'sl', '+232', '+232'],
    ['Singapore', 'sg', '+65', '+65'],
    ['Sint Maarten', 'sx', '+1-721', '+1-721'],
    ['Slovakia', 'sk', '+421', '+421'],
    ['Slovenia', 'si', '+386', '+386'],
    ['Solomon Islands', 'sb', '+677', '+677'],
    ['Somalia', 'so', '+252', '+252'],
    ['South Africa', 'za', '+27', '+27'],
    ['South Korea', 'kr', '+82', '+82'],
    ['South Sudan', 'ss', '+211', '+211'],
    ['Spain', 'es', '+34', '+34'],
    ['Sri Lanka', 'lk', '+94', '+94'],
    ['Sudan', 'sd', '+249', '+249'],
    ['Suriname', 'sr', '+597', '+597'],
    ['Svalbard and Jan Mayen', 'sj', '+47', '+47'],
    ['Swaziland', 'sz', '+268', '+268'],
    ['Sweden', 'se', '+46', '+46'],
    ['Switzerland', 'ch', '+41', '+41'],
    ['Syria', 'sy', '+963', '+963'],
    ['Taiwan', 'tw', '+886', '+886'],
    ['Tajikistan', 'tj', '+992', '+992'],
    ['Tanzania', 'tz', '+255', '+255'],
    ['Thailand', 'th', '+66', '+66'],
    ['Timor-Leste', 'tl', '+670', '+670'],
    ['Togo', 'tg', '+228', '+228'],
    ['Tokelau', 'tk', '+690', '+690'],
    ['Tonga', 'to', '+676', '+676'],
    ['Trinidad and Tobago', 'tt', '+1-868', '+1-868'],
    ['Tunisia', 'tn', '+216', '+216'],
    ['Turkey', 'tr', '+90', '+90'],
    ['Turkmenistan', 'tm', '+993', '+993'],
    ['Turks and Caicos Islands', 'tc', '+1-649', '+1-649'],
    ['Tuvalu', 'tv', '+688', '+688'],
    ['U.S. Virgin Islands', 'vi', '+1-340', '+1-340'],
    ['Uganda', 'ug', '+256', '+256'],
    ['Ukraine', 'ua', '+380', '+380'],
    ['United Arab Emirates', 'ae', '+971', '+971'],
    ['United Kingdom', 'gb', '+44', '+44'],
    // ['United States', 'us', '+1', '+1'],
    ['Uruguay', 'uy', '+598', '+598'],
    ['Uzbekistan', 'uz', '+998', '+998'],
    ['Vanuatu', 'vu', '+678', '+678'],
    ['Vatican City', 'va', '+39', '+39'],
    ['Venezuela', 've', '+58', '+58'],
    ['Vietnam', 'vn', '+84', '+84'],
    ['Wallis and Futuna', 'wf', '+681', '+681'],
    ['Western Sahara', 'eh', '+212', '+212'],
    ['Yemen', 'ye', '+967', '+967'],
    ['Zambia', 'zm', '+260', '+260'],
    ['Zimbabwe', 'zw', '+263', '+263'],
    ['Åland Islands', 'ax', '+358', '+358'],
]

export default allCountries.map(([name, iso2, value, text]) => ({
    name,
    iso2: iso2.toUpperCase(),
    value,
    text,
}))
