<template>
    <validation-provider
        :rules="`${[required ? 'required' : '']}|${rules}`"
        :name="name"
    >
        <b-form-group slot-scope="{ valid, errors }">
            <label class="control-label" v-if="label">
                {{ label }}<span v-if="required">* </span>
            </label>
            <b-form-textarea
                :name="name"
                :placeholder="placeholder"
                :required="required"
                :maxlength="maxlength"
                :minlength="minlength"
                :value="value"
                :state="errors[0] ? false : valid ? true : null"
                @input="input"
                :autofocus="autofocus"
                :disabled="disabled"
            ></b-form-textarea>
            <span class="info-label" v-if="infolabel">{{ infolabel }}</span>
            <b-form-invalid-feedback>
                {{ getError(errors[0]) }}
            </b-form-invalid-feedback>
        </b-form-group>
    </validation-provider>
</template>

<script>
export default {
    props: {
        name: String,
        placeholder: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        label: {
            type: String,
        },
        value: {
            type: String,
        },
        maxlength: {
            type: String,
        },
        minlength: {
            type: String,
        },
        infolabel: {
            type: String,
        },
        rules: {
            type: String,
            default: '',
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        input(value) {
            this.$emit('input', value)
        },
        getError(error) {
            if (this.$te(error)) {
                return this.$t(error)
            } else {
                return error
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.form-control {
    &:disabled {
        border-color: transparent;
        color: #999;
        background-color: #f4f4f4;
        opacity: 0.6;
    }
}
</style>
