<template>
    <div>
        <div
            v-if="feedback_form_obj && feedback_form_obj.form_fields"
            class="feedback"
            :class="[formClass]"
        >
            <a
                class="feedback__link btn btn-primary"
                href="#"
                @click="showFeedBackForm = true"
            >
                {{ feedback_form_obj[`title_${$i18n.locale}`] }}
            </a>
            <div class="feedback__modal modal-wrapper">
                <a
                    href="#"
                    class="close-modal"
                    @click="showFeedBackForm = false"
                    >X</a
                >
                <span
                    v-html="feedback_form_obj[`intro_${$i18n.locale}`]"
                ></span>
                <form id="feedback-form" @submit.prevent="submitFeedBackForm()">
                    <div
                        v-for="(field, key) in feedback_form_obj.form_fields"
                        :key="key"
                        class="form-group"
                    >
                        <label :class="{ 'delete-padding': field.rating }"
                            >{{ field[`label_${$i18n.locale}`] }}
                        </label>
                        <b-form-rating
                            v-if="field.rating"
                            v-model="field.value"
                            class="rating"
                            inline
                        ></b-form-rating>
                        <base-textarea
                            v-else
                            name="Description"
                            :placeholder="field[`help_text_${$i18n.locale}`]"
                            maxlength="100"
                            v-model="field.value"
                            :required="true"
                        />
                    </div>
                    <span v-if="error" style="color: crimson;">{{
                        error
                    }}</span>
                    <div class="button-row">
                        <button type="submit" class="btn btn-primary">
                            {{ $t('Submit') }}
                        </button>
                    </div>
                </form>
            </div>
            <div class="feedback__submit-modal modal-wrapper">
                <a
                    href="#"
                    class="close-modal"
                    @click="showFeedBackForm = false"
                    >X</a
                >
                <div class="feedback__submit-modal--image">
                    <img
                        loading="lazy"
                        src="@/assets/images/check-mark.png"
                        class="img-fluid"
                        alt=""
                    />
                </div>
                <div
                    class="thank-you-text"
                    v-html="feedback_form_obj[`thank_you_text_${$i18n.locale}`]"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import { SettingsHelper } from '@/common/crud-helpers/settings'
import { UsersHelper } from '@/common/crud-helpers/users'
import { API_BASE } from '@/conf'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            showFeedBackForm: false,
            isFeedbackSubmitted: false,
            feedback_form_obj: null,
            error: '',
            API_BASE,
            csrfToken: JSON.parse(localStorage.getItem('csrf_token')),
        }
    },
    computed: {
        ...mapGetters(['feedbackSettings', 'userAuth']),
        formClass() {
            return this.showFeedBackForm
                ? this.isFeedbackSubmitted
                    ? 'submit-modal'
                    : 'feedback-modal'
                : ''
        },
    },
    created() {
        if (this.feedbackSettings && this.feedbackSettings.form_fields) {
            this.feedback_form_obj = cloneDeep(this.feedbackSettings)
        } else {
            SettingsHelper.getFeedbackSettings()
        }
        if (JSON.parse(localStorage.getItem('feedback_posted')) === true)
            this.isFeedbackSubmitted = true
    },
    watch: {
        feedbackSettings() {
            this.feedback_form_obj = cloneDeep(this.feedbackSettings)
        },
        feedback_form_obj: {
            handler() {
                if (this.error.length) this.error = ''
            },
            deep: true,
        },
    },
    methods: {
        openForm() {
            this.showFeedBackForm = true
        },
        submitFeedBackForm() {
            let submitData = {}
            // submitData.csrfmiddlewaretoken = this.csrfToken
            this.feedback_form_obj.form_fields.forEach(field => {
                submitData[`${field.clean_name}`] = field.value
            })
            UsersHelper.submitFeedBack({
                submitData,
            })
                .then(() => {
                    localStorage.setItem('feedback_posted', 'true')
                    this.isFeedbackSubmitted = true
                })
                .catch(err => {
                    console.log(err)
                    this.error = 'Please fill out all the fields'
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.rating {
    /deep/ span.b-rating-star {
        span.b-rating-icon {
            background-image: url('https://zainiacdev.s3.amazonaws.com/static/images/lightbulb-off.png');
            background-size: cover;
            height: 25px;
            width: 25px;
            svg {
                height: 0;
                width: 0;
            }
        }
    }
    /deep/ span.b-rating-star-full {
        span.b-rating-icon {
            background-image: url('https://zainiacdev.s3.amazonaws.com/static/images/lightbulb-on.png');
            background-size: cover;
            &:hover {
                background: url('https://zainiacdev.s3.amazonaws.com/static/images/lightbulb-on.png');
                background-size: cover;
            }
            height: 25px;
            width: 25px;
            svg {
                height: 0;
                width: 0;
            }
        }
    }

    &:hover {
        /deep/ span.b-rating-star {
            span.b-rating-icon {
                background-image: url('https://zainiacdev.s3.amazonaws.com/static/images/lightbulb-on.png');
                background-size: cover;
                height: 25px;
                width: 25px;
                svg {
                    height: 0;
                    width: 0;
                }
            }
        }
        /deep/ span.b-rating-star:hover ~ span {
            span.b-rating-icon {
                background-image: url('https://zainiacdev.s3.amazonaws.com/static/images/lightbulb-off.png');
                background-size: cover;
                height: 25px;
                width: 25px;
                svg {
                    height: 0;
                    width: 0;
                }
            }
        }
    }
}

// /deep/ span.b-rating-star-empty {
//     span.b-rating-icon {
//         background-image: url('https://zainiacdev.s3.amazonaws.com/static/images/lightbulb-off.png');
//         background-size: cover;
//         &:hover {
//             background: url('https://zainiacdev.s3.amazonaws.com/static/images/lightbulb-on.png');
//             background-size: cover;
//         }
//         height: 25px;
//         width: 25px;
//         svg {
//             height: 0;
//             width: 0;
//         }
//     }
// }
// /deep/ span.b-rating-star-full {
//     span.b-rating-icon {
//         background-image: url('https://zainiacdev.s3.amazonaws.com/static/images/lightbulb-on.png');
//         background-size: cover;
//         &:hover {
//             background: url('https://zainiacdev.s3.amazonaws.com/static/images/lightbulb-on.png');
//             background-size: cover;
//         }
//         height: 25px;
//         width: 25px;
//         svg {
//             height: 0;
//             width: 0;
//         }
//     }
// }

form {
    margin-bottom: 0;
}
.feedback {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 9999;
    .btn {
        background: linear-gradient(
            100deg,
            var(--green) 0%,
            var(--secondary) 100%
        ) !important;
        border-radius: 30px;
        min-width: unset !important;
        font-size: 1.0625rem;
    }
    .close-modal {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        -o-border-radius: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background: var(--primary);
        color: #fff;
        font-size: 22px;
        position: absolute;
        left: -50px;
        top: 0;
        text-decoration: none !important;
    }
    .modal-wrapper {
        height: 0;
        opacity: 0;
        visibility: hidden;
        display: none !important;
        position: relative;
        width: 375px;
        background: #fff;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border: 5px solid var(--primary);
        padding: 1.25rem;
        transition: 0.4s ease all;
        min-height: 531px;
        /deep/ p {
            font-weight: 700;
        }
        .form-group {
            margin-bottom: 1.875rem;
            .delete-padding {
                margin-bottom: -10px;
            }
            .rating {
                background-color: transparent;
                padding: 0;
                font-size: 0;
                height: auto;
                /deep/ .b-rating-star:hover .b-rating-icon {
                    transform: none;
                }
            }
        }
        label {
            display: block;
            font-weight: 700;
            margin-bottom: 0.625rem;
        }
        /deep/ .form-control {
            background: #d3d3d3;
            font-size: 1rem;
            color: #000;
            box-shadow: none;
            padding: 0.4375rem;
            border: none;
            &::placeholder {
                color: #000;
                // font-size: rem(18px);
            }
            &:focus {
                border: 2px solid var(--primary);
            }
        }
        .btn {
            padding: 0.6875rem 1.6875rem;
        }
    }
    @media screen and (max-width: 575px) {
        .modal-wrapper {
            width: 80%;
            margin-left: auto;
        }
    }
    @media screen and (max-width: 991px) {
        .modal-wrapper {
            min-height: 450px;
        }
    }
    .button-row {
        justify-content: flex-end;
    }
    &__submit-modal {
        height: 100%;
        opacity: 1;
        visibility: visible;
        display: flex !important;
    }
    @media screen and (max-width: 767px) {
        .feedback__link {
            display: none;
        }
    }
}
.feedback-modal {
    .feedback__modal {
        height: 100%;
        opacity: 1;
        visibility: visible;
        display: block !important;
    }
    .feedback__link {
        opacity: 0;
        visibility: hidden;
        display: none;
    }
    @media screen and (min-width: 768px) {
        .feedback__link {
            border-radius: 30px;
            min-width: unset !important;
            font-size: 1.0625rem;
            position: fixed;
            right: 20px;
            bottom: 20px;
            z-index: 9999;
            display: block;
        }
    }
}
.submit-modal {
    .feedback__submit-modal {
        height: 100%;
        opacity: 1;
        visibility: visible;
        display: flex !important;
        &--image {
            text-align: center;
        }
        /deep/ p {
            font-size: 1.5625rem;
            text-align: center;
            margin-top: 2.1875rem;
        }
    }
    .feedback__link {
        opacity: 0;
        visibility: hidden;
        display: none;
    }
}
.feedback__submit-modal {
    display: flex !important;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.ar {
    .feedback {
        right: auto;
        left: 20px;
        .close-modal {
            left: auto;
            right: -50px;
        }
    }
    .rating {
        direction: rtl;
    }
}
</style>
