import { ApiService } from '@/common/api_services'

export const ChatService = {
    getChat(params) {
        return ApiService.query(`chat/${params.uuid}/`, {})
    },
    getMoreChat(params) {
        return ApiService.query(`chat/${params.uuid}/messages/`, { params })
    },
    fileUpload(params) {
        return ApiService.file(`/chat/file-upload/`, params)
    },
    getChatList(params) {
        return ApiService.query(`/chat/list/`, { params })
    },
    createChat(params) {
        return ApiService.post(`/chat/create/`, params)
    },
    chatSatistics(params) {
        return ApiService.query('/chat/statistics/', params)
    },
}
