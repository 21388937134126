<template>
    <vuejsDatepicker
        :value="display_value"
        :bootstrap-styling="true"
        :clear-button="true"
        @input="input"
        @cleared="clear"
    ></vuejsDatepicker>
</template>

<script>
import vuejsDatepicker from 'vuejs-datepicker'
export default {
    components: {
        vuejsDatepicker,
    },
    props: {
        value: String,
    },
    data() {
        return {
            display_value: null,
        }
    },
    created() {
        this.display_value = this.value
    },
    watch: {
        value() {
            this.display_value = this.value
        },
    },

    methods: {
        input(value) {
            const date = new Date(value)
            var mm = date.getMonth() + 1
            var dd = date.getDate()
            var yy = date.getFullYear()
            this.$emit('input', `${yy}-${mm}-${dd}`)
        },
        clear() {
            this.$emit('input', null)
        },
    },
}
</script>

<style lang="scss" scoped></style>
