<template>
    <div>
        <div class="idea--date">
            <span>{{ created | dateDay }}</span> {{ created | dateMonthYear
            }}<i> -</i>
            <span class="status" :class="statusClass">{{ ideaStatus }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        created: String,
        ideaStatus: String,
        statusClass: String,
    },
}
</script>

<style lang="scss" scoped>
.idea--date {
    font-size: rem(14px);
    font-weight: 700;
    color: var(--dark);
    span {
        font-size: rem(25px);
        font-weight: 900;
        display: inline-block;
        padding-right: rem(5px);
        &.status {
            color: var(--secondary);
            font-size: rem(20px);
            font-weight: 600;
            text-transform: capitalize;
            &.approved {
                color: var(--green);
            }
            &.implemented {
                color: var(--primary);
            }
        }
    }
}

// ar style

.ar {
    .idea--date {
        direction: ltr;
    }
}
</style>
