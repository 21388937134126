import { ApiService } from '@/common/api_services'
import i18n from '@/common/i18n'
import axios from 'axios'

export const NotificationService = {
    getNotification(params) {
        if (i18n.locale == 'ar') {
            return axios.get(
                process.env.VUE_APP_ZAINIAC_API_BASE +
                    '/ar/api/v1/notification/notifications/',
                { params },
            )
        } else {
            return ApiService.query('notification/notifications/', { params })
        }
    },
    getNotificationCount(params) {
        return ApiService.query('notification/new-notifications/', { params })
    },
    seenNotification(params) {
        if (params) {
            return ApiService.post(`notification/seen/?id=${params.id}`)
        } else return ApiService.post('notification/seen/')
    },
}
