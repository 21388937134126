export default [
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'user-leaderboard',
        name: 'user-leaderboard',
        component: () => import('../views/Leaderboard.vue'),
    },
]
