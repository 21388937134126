export default [
    {
        path: '/',
        name: 'home',
        redirect: {
            name: 'login',
        },
    },
    {
        meta: {
            isAuthenticated: false,
        },
        path: 'login',
        name: 'login',
        component: () => import('../views/Login.vue'),
    },
    {
        meta: {
            isAuthenticated: false,
        },
        path: 'signup',
        name: 'signup',
        component: () => import('../views/SignUp.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'profile',
        name: 'profile',
        component: () => import('../views/Profile.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'profile-settings',
        name: 'profile-settings',
        component: () => import('../views/ProfileSettings.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'user-events',
        name: 'user-events',
        component: () => import('../views/events/Events.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'activity-logs',
        name: 'activity-logs',
        component: () => import('../views/ActivityLogs.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'messages/:username',
        name: 'messages',
        component: () => import('../views/Messages.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'zainers',
        name: 'zainers',
        component: () => import('../views/Zainers.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'view-zainers/:username',
        name: 'view-zainers',
        component: () => import('../views/Profile.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'user-badges',
        name: 'user-badges',
        component: () => import('../views/Badges.vue'),
    },
]
