const API_BASE = process.env.VUE_APP_ZAINIAC_API_BASE || 'http://localhost:8000'
const VUE_APP_WS_NOTIFICATIONS = process.env.VUE_APP_WS_NOTIFICATIONS
const VUE_APP_WS_CHAT = process.env.VUE_APP_WS_CHAT
const VUE_APP_WS_SENTRY = process.env.VUE_APP_WS_SENTRY
const BASE_URL_DOMAIN = process.env.VUE_APP_BASE_URL_DOMAIN

let apiBase = function() {
    return API_BASE + '/en/api/v1/'
}
export {
    apiBase,
    API_BASE,
    VUE_APP_WS_NOTIFICATIONS,
    VUE_APP_WS_CHAT,
    VUE_APP_WS_SENTRY,
    BASE_URL_DOMAIN,
}
