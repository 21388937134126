export default [
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'sprints',
        name: 'sprints',
        component: () => import('../views/Workshops.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'workshops',
        name: 'workshops',
        redirect: {
            name: 'sprints',
        },
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'sprints/details/:uuid',
        name: 'sprints-detail',
        component: () => import('../views/WorkshopsDetail.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'workshops/details/:uuid',
        name: 'workshops-detail',
        redirect: to => {
            return 'sprints/details/' + to.params.uuid
        },
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'user-sprints',
        name: 'user-sprints',
        component: () => import('../views/UserWorkshops.vue'),
    },
]
