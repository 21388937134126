<template>
    <validation-provider
        :rules="`${[required ? 'required' : '']}`"
        :name="name"
        v-slot="{ errors }"
    >
        <v-select
            v-model="selectedValue"
            :options="options_arr"
            :name="name"
            :required="required"
            :disabled="disabled"
        ></v-select>
        <span class="invalid-feedback" v-if="errors.length">{{
            errors[0]
        }}</span>
    </validation-provider>
</template>

<script>
export default {
    props: {
        name: String,
        value: [Number, String],
        title: String,
        options: Array,
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: function() {
        return {
            selectedValueData: null,
            selectedValue: null,
            options_arr: [],
            change: false,
        }
    },
    methods: {
        setOption() {
            this.options_arr = this.options
        },
        setSelectedValue(value) {
            if (this.options.length) {
                let valueObj = this.options.find(
                    option => option.value == value,
                )
                if (valueObj) {
                    this.selectedValue = valueObj
                } else {
                    this.selectedValue = null
                }
            } else {
                this.selectedValue = value
            }
        },
    },
    created() {
        this.setOption()
        this.setSelectedValue(this.value)
    },
    computed: {},
    watch: {
        value: function(value) {
            this.setSelectedValue(value)
        },
        options: function() {
            this.setOption()
            this.setSelectedValue(this.value)
        },
        selectedValue: function() {
            if (this.change && this.selectedValue) {
                this.$emit('input', this.selectedValue.value)
            } else {
                this.change = true
                this.$emit('input', this.selectedValue.value)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.invalid-feedback {
    display: block;
}
</style>
<style>
.v-select-toggle {
    border: 0;
}
.error {
    color: red !important;
}
.error-border,
.error-border:hover,
.error-border:focus {
    border: 1px solid red !important;
}
.sucess-border,
.sucess-border:hover,
.sucess-border:focus {
    border: 1px solid green !important;
    border-radius: 4px !important;
}
.sucess {
    color: green !important;
}

.error-border-radius {
    border-radius: 4px !important;
}
.vs__dropdown-toggle {
    height: 54px;
    font-size: 1rem;
    color: #666666;
    background-color: #fff;
    outline: none;
    border: 1px solid #e7e7e9;
    border-radius: 4px;
    font-weight: 400;
    line-height: 54px;
    padding-bottom: 0;
}

.vs-dropdown-option {
    font-size: 1rem;
}
.arrow-down {
    margin-top: 10px !important;
    font-size: 1rem;
}

.no_icon .vs__actions {
    display: none;
}
</style>
<style scoped>
.v-select.disabled {
    border-color: transparent;
    color: #999;
    background-color: #f4f4f4;
    opacity: 0.6;
    pointer-events: none;
}
</style>
