<template>
    <div>
        <div class="page__head">
            <div class="row align-items-start" data-aos="fade-up">
                <div :class="[showProfileDetails ? 'col-md-4' : 'col-md-6']">
                    <h1 class="page__head--title">{{ pageHead.title }}</h1>
                    <p
                        class="page__head--subtitle"
                        v-if="pageHead.subtitle != null"
                    >
                        {{ pageHead.subtitle }}
                    </p>
                    <base-user-level
                        :userData="userData"
                        v-if="showUserLevel"
                    />
                </div>
                <div :class="[showProfileDetails ? 'col-md-8' : 'col-md-6']">
                    <div
                        v-if="pageHead.shortdes && !showProfileDetails"
                        class="page__head--shortdesc"
                    >
                        <p>{{ pageHead.shortdes }}</p>
                    </div>
                    <div v-if="showProfileDetails" class="row profile-details">
                        <div class="col-6">
                            <ul class="list-inline list-unstyled">
                                <li>
                                    {{ $t('Approved ideas') }}
                                </li>
                                <li>
                                    {{ getCount(userData.my_approved_idea) }}
                                </li>
                            </ul>
                            <ul class="list-inline list-unstyled">
                                <li>
                                    {{ $t('Planned ideas') }}
                                </li>
                                <li>
                                    {{ getCount(userData.my_planned_idea) }}
                                </li>
                            </ul>
                            <ul class="list-inline list-unstyled">
                                <li>
                                    {{ $t('Implemented ideas') }}
                                </li>
                                <li>
                                    {{ getCount(userData.my_implemented_idea) }}
                                </li>
                            </ul>
                        </div>

                        <div class="col-6">
                            <ul class="list-inline list-unstyled">
                                <li>
                                    {{ $t('Dismissed ideas') }}
                                </li>
                                <li>
                                    {{ getCount(userData.my_dismissed_idea) }}
                                </li>
                            </ul>
                            <!-- <ul class="list-inline list-unstyled">
                                <li>
                                    {{ $t('Rejected ideas') }}
                                </li>
                                <li>
                                    {{ getCount(userData.my_rejected_idea) }}
                                </li>
                            </ul> -->
                            <ul class="list-inline list-unstyled">
                                <li>
                                    {{ $t('Resubmit ideas') }}
                                </li>
                                <li>
                                    {{ getCount(userData.my_resubmit_idea) }}
                                </li>
                            </ul>

                            <ul class="list-inline list-unstyled">
                                <li>
                                    {{ $t('Submitted ideas') }}
                                </li>
                                <li>
                                    {{ getCount(userData.my_submitted_idea) }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pageHead: Object,
        userData: {
            type: Object,
            default: () => null,
        },
        userType: {
            type: String,
            default: '',
        },
        showProfileDetails: {
            type: Boolean,
            default: false,
        },
        showUserLevel: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getCount(ideaCount) {
            return ideaCount ? ideaCount : 0
        },
    },
}
</script>

<style lang="scss" scoped>
.page__head {
    @media screen and (max-width: 768px) {
        text-align: center;
    }
}
.profile-details {
    @media screen and (max-width: 500px) {
        flex-direction: column;
        max-width: 320px;
        margin: auto;
        > div {
            flex: 0 0 100%;
            max-width: 100%;
            &:first-child {
                margin-bottom: rem(20px);
            }
        }
    }
    ul {
        @media screen and (max-width: 500px) {
            padding-bottom: 0;
        }
        padding-bottom: 0.5rem;
        li {
            font-size: 1.125rem;
            color: #fff;
            text-align: left;
            flex: 0 0 160px;
            max-width: 160px;
            &:last-child {
                font-weight: 900;
                font-size: 1.25rem;
                color: var(--secondary);
                flex: 1;
                max-width: none;
                text-align: revert;
            }
        }
    }
}
.ar {
    .profile-details {
        ul {
            li {
                text-align: right;
            }
        }
    }
}
</style>
