import { SETTINGS } from '@/store/actions/settings'

import { ApiServiceHelper } from '../crud-helpers'

export const SettingsHelper = {
    getNavMenu(params) {
        return ApiServiceHelper.execAction(SETTINGS.GET_NAV_MENU, params)
    },
    getFooterSettings(params) {
        return ApiServiceHelper.execAction(SETTINGS.GET_FOOTER, params)
    },
    getHeaderSettings(params) {
        return ApiServiceHelper.execAction(SETTINGS.GET_HEADER, params)
    },
    getDashboardSettings(params) {
        return ApiServiceHelper.execAction(SETTINGS.GET_DASHBOARD, params)
    },
    getFeedbackSettings(params) {
        return ApiServiceHelper.execAction(SETTINGS.GET_FEEDBACK, params)
    },
    getSocialSettings(params) {
        return ApiServiceHelper.execAction(SETTINGS.GET_SOCIAL, params)
    },
    getCountry(params) {
        return ApiServiceHelper.execAction(SETTINGS.GET_COUNTRY, params)
    },
}
