export const IDEAS = {
    LIST: 'getMyIdeasList',
    GET: 'getMyIdeas',
    GET_IDEA_FORM_FIELDS: 'getIdeaFormFields',
    SET_IDEA_FORM_FIELDS: 'setIdeaFormFields',
    CREATE: 'createMyIdeas',
    PUT: 'putMyIdeas',
    DELETE: 'deleteMyIdeas',
    SET_OBJECT: 'setIdeas',
    SET_LIST: 'setIdeasList',
    LIST_TREND: 'getTrendIdeasList',
    GET_TREND: 'getTrendIdeas',
    SET_TREND_LIST: 'setTrendIdeasList',
    LIST_IDEA: 'getIdeasList',
    OPCO_IDEA_CATEGORY: 'getOpcoIdeaCategory',
    SET_OPCO_IDEA_CATEGORY: 'setOpcoIdeaCategory',
    ACTIVE_IDEA_CATEGORIES: 'getActiveIdeaCategories',
    SET_ACTIVE_IDEA_CATEGORIES: 'setActiveIdeaCategories',
    GET_IDEA: 'getIdeas',
    GET_BY_ID_IDEA: 'getByIdIdea',
    GET_BUCKET_CATEGORIES_LIST: 'getBucketCategoriesList',
    SET_BUCKET_CATEGORIES_LIST: 'setBucketCategoriesList',
    DELETE_IDEA_DOCUMENT: 'deleteIdeaDocument',
    UPDATE_IDEA_DOCUMENT: 'updateIdeaDocument',
    UPDATE_IDEA_MEDIA: 'updateIdeaMedia',
}

export const SOCIAL = {
    COMMENT_LIKE: 'postCommentLike',
    LIKE: 'postLike',
    GET_LIKE: 'getLikes',
    SET_LIKE_LIST: 'setLikesList',
    FOLLOWER: 'postFollower',
    GET_FOLLOWER: 'getFollowers',
    SET_FOLLOWER_LIST: 'setFollowersList',
    COMMENT: 'postComment',
    GET_COMMENTS: 'getComments',
    SET_COMMENTS_LIST: 'setCommentsList',
    CONTRIBUTOR: 'postContributor',
    GET_CONTRIBUTOR: 'getContributors',
    SET_CONTRIBUTOR_LIST: 'setContributorsList',
}
