export const SETTINGS = {
    GET_NAV_MENU: 'getNavMenu',
    SET_NAV_MENU: 'setNavMenu',
    GET_FOOTER: 'getFooterSettings',
    SET_FOOTER: 'setFooterSettings',
    GET_SOCIAL: 'getSocialSettings',
    SET_SOCIAL: 'setSocialSettings',
    GET_HEADER: 'getHeaderSettings',
    SET_HEADER: 'setHeaderSettings',
    GET_DASHBOARD: 'getDashboardSettings',
    GET_FEEDBACK: 'getFeedbackSettings',
    SET_FEEDBACK: 'setFeedbackSettings',
    SET_DASHBOARD: 'setDashboardSettings',
    GET_COUNTRY: 'getCountry',
    SET_COUNTRY: 'setCountry',
}
