import { ApiService } from '@/common/api_services'

export const WorkshopService = {
    query(params) {
        return ApiService.query('/workshop/workshop/', { params })
    },
    get(pk) {
        return ApiService.get('/workshop/workshop', pk)
    },
    myWorkshopList(params) {
        return ApiService.query('/workshop/my-workshop/', { params })
    },
    myWorkshopGet(pk) {
        return ApiService.get('/workshop/my-workshop', pk)
    },
    getWorkshopSettings() {
        return ApiService.query(`workshop/workshop-settings/`)
    },
    postWorkshopCommentLike(id) {
        return ApiService.post(`workshop/comment-likes/${id}/`)
    },
    postWorkshopLike(uuid) {
        return ApiService.post(`workshop/likes/${uuid}/`)
    },
    getWorkshopLikes(params) {
        return ApiService.query(`workshop/likes/${params.uuid}/`, { params })
    },
    postWorkshopFollower(uuid) {
        return ApiService.post(`workshop/followers/${uuid}/`)
    },
    getWorkshopFollowers(params) {
        return ApiService.query(`workshop/followers/${params.uuid}/`, {
            params,
        })
    },
    postWorkshopComment(params) {
        return ApiService.post(`workshop/comments/${params.uuid}/`, params)
    },
    getWorkshopComments(params) {
        return ApiService.query(`workshop/comments/${params.uuid}/`, { params })
    },
    userRegistration(params) {
        return ApiService.post(
            `workshop/user-registration/${params.uuid}/`,
            params,
        )
    },
    cancelUserRegistration(params) {
        return ApiService.delete(
            `workshop/user-registration/${params.uuid}/${params.id}`,
        )
    },
    getUserRegistration(params) {
        return ApiService.query(`workshop/user-registration/${params.uuid}/`, {
            params,
        })
    },
}
