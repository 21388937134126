import { ApiService } from '@/common/api_services'
export const SettingService = {
    getNavMenu(params) {
        return ApiService.query('settings/nav-menu/', { params })
    },
    getFooterSettings(params) {
        return ApiService.query('settings/footer-setting/', { params })
    },
    getSocialSettings(params) {
        return ApiService.query('settings/social-setting/', { params })
    },
    getHeaderSettings(params) {
        return ApiService.query('settings/setting/', { params })
    },
    getDashboardSettings(params) {
        return ApiService.query('settings/dashboard-setting/', { params })
    },
    getFeedbackSettings(params) {
        return ApiService.query('settings/submit-feedback/', { params })
    },
    getCountry(params) {
        return ApiService.query('settings/country/', { params })
    },
}
