<template>
    <div>
        <div class="likes-wrapper">
            <ul class="list-inline">
                <li>
                    <a
                        @click="social('like')"
                        :class="[object.i_like ? 'activ' : '']"
                    >
                        <svg
                            fill="currentColor"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 450.89 512"
                        >
                            <use xlink:href="#likes-icon"></use>
                        </svg>
                        <span>
                            {{ object.likes_count }}
                        </span>
                    </a>
                </li>
                <li>
                    <router-link :to="to">
                        <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 384 338.09"
                        >
                            <use xlink:href="#comments-icon"></use>
                        </svg>
                        <span>{{ object.comments_count }}</span>
                    </router-link>
                </li>
                <li>
                    <a
                        @click="social('follower')"
                        :class="[object.i_follow ? 'activ' : '']"
                    >
                        <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 384 335.8"
                        >
                            <use xlink:href="#followers-icon"></use>
                        </svg>
                        <span>{{ object.followers_count }}</span>
                    </a>
                </li>
                <!-- <li>
          <a
            @click="social('contributor')"
            :class="[object.i_contribute ? 'activ' : '']"
          >
            <svg
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 429.28 489.6"
            >
              <use xlink:href="#contributors-icon"></use>
            </svg>
            <span>{{ idea.contributor_count }}</span>
          </a>
        </li> -->
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        object: Object,
        to: {
            type: Object,
            default: function() {
                return {}
            },
        },
    },
    data: function() {
        return {}
    },
    created() {},
    methods: {
        social(type) {
            this.$emit('social', {
                type: type,
                object: this.object,
            })
        },
    },
    watch: {},
}
</script>

<style lang="scss" scoped>
.likes-wrapper {
    ul {
        li {
            a {
                color: #009b74;
                font-size: rem(23px);
                font-weight: 600;
                @include flex(flex-end, flex-start);
                @media screen and (max-width: 1200px) {
                    font-size: rem(21px);
                }
                svg {
                    height: rem(35px);
                    width: auto;
                    color: #009b74;
                }
                span {
                    display: inline-block;
                    padding-left: rem(15px);
                }
                @media screen and (min-width: 1025px) {
                    &:hover {
                        text-decoration: none;
                        color: #009b74;
                        svg {
                            color: #009b74;
                        }
                    }
                }
            }
            &:not(:last-child) {
                margin-right: rem(50px);
            }
            a.activ {
                text-decoration: none;
                color: #009b74;
                svg {
                    color: #009b74;
                }
            }
        }
    }
    @media screen and (max-width: 1199px) and (min-width: 992px) {
        ul {
            li {
                &:not(:last-child) {
                    margin-right: rem(20px);
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        ul {
            li {
                a {
                    font-size: rem(20px);
                    svg {
                        height: rem(25px);
                    }
                }
                &:not(:last-child) {
                    margin-right: rem(20px);
                }
            }
        }
    }
    @media screen and (max-width: 500px) {
        ul {
            li {
                a {
                    font-size: rem(16px);
                    svg {
                        height: 20px;
                    }
                }
                &:not(:last-child) {
                    margin-right: rem(16px);
                }
                margin-bottom: rem(12px);
            }
        }
    }
}

// ar style

.ar {
    .likes-wrapper {
        ul {
            li {
                a {
                    span {
                        padding-right: rem(15px);
                        padding-left: 0;
                        direction: ltr;
                    }
                }
                &:not(:last-child) {
                    margin-left: rem(50px);
                    margin-right: 0;
                }
            }
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
            ul {
                li {
                    &:not(:last-child) {
                        margin-left: rem(20px);
                        margin-right: 0;
                    }
                }
            }
        }
        @media screen and (max-width: 767px) {
            ul {
                li {
                    &:not(:last-child) {
                        margin-left: rem(20px);
                        margin-right: 0;
                    }
                }
            }
        }
        @media screen and (max-width: 500px) {
            ul {
                li {
                    &:not(:last-child) {
                        margin-left: rem(16px);
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
</style>
