var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"users__profile"},[_c('div',{staticClass:"users__profile--image img-hover"},[_c('router-link',{attrs:{"to":{
                    name: 'view-zainers',
                    params: {
                        username: _vm.user.id,
                    },
                }}},[(_vm.user.photo)?_c('img',{attrs:{"loading":"lazy","src":_vm.user.photo,"alt":""}}):_c('img',{attrs:{"loading":"lazy","src":require("@/assets/images/svg/person.svg"),"alt":""}})])],1),_c('div',{staticClass:"users__info"},[_c('p',{staticClass:"users__info--name"},[_c('router-link',{attrs:{"to":{
                        name: 'view-zainers',
                        params: {
                            username: _vm.user.id,
                        },
                    }}},[_vm._v(" "+_vm._s(_vm.user.display_name))])],1),_c('p',{staticClass:"users__info--country"},[_vm._v(_vm._s(_vm.user.country))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }