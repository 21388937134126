import { SettingService } from '@/common/api_services/settings'
import { SETTINGS } from '@/store/actions/settings'

import { FETCH_SUCCESS, FETCH_ERROR, FETCH_START } from '../common'

const state = {
    navMenu: {},
    footerSettings: {},
    socialSettings: {},
    headerSettings: {},
    dashboardSettings: {},
    country: {},
    feedbackSettings: {},
}

const getters = {
    navMenu: state => state.navMenu,
    footerSettings: state => state.footerSettings,
    socialSettings: state => state.socialSettings,
    headerSettings: state => state.headerSettings,
    dashboardSettings: state => state.dashboardSettings,
    country: state => state.country,
    feedbackSettings: state => state.feedbackSettings,
}

const mutations = {
    [SETTINGS.SET_NAV_MENU]: (state, resp) => {
        state.navMenu = resp.data
    },
    [SETTINGS.SET_FOOTER]: (state, resp) => {
        state.footerSettings = resp.data
    },
    [SETTINGS.SET_SOCIAL]: (state, resp) => {
        state.socialSettings = resp.data
    },
    [SETTINGS.SET_HEADER]: (state, resp) => {
        state.headerSettings = resp.data
    },
    [SETTINGS.SET_DASHBOARD]: (state, resp) => {
        state.dashboardSettings = resp.data
    },
    [SETTINGS.SET_FEEDBACK]: (state, resp) => {
        state.feedbackSettings = resp.data
    },
    [SETTINGS.GET_SOCIAL]: (state, resp) => {
        state.country = resp.data
    },
}

const actions = {
    [SETTINGS.GET_NAV_MENU]: ({ commit }, payload) => {
        commit(FETCH_START)
        SettingService.getNavMenu(payload)
            .then(resp => {
                commit(SETTINGS.SET_NAV_MENU, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [SETTINGS.GET_FOOTER]: ({ commit }, payload) => {
        commit(FETCH_START)
        SettingService.getFooterSettings(payload)
            .then(resp => {
                commit(SETTINGS.SET_FOOTER, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [SETTINGS.GET_SOCIAL]: ({ commit }, payload) => {
        commit(FETCH_START)
        SettingService.getSocialSettings(payload)
            .then(resp => {
                commit(SETTINGS.SET_SOCIAL, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [SETTINGS.GET_HEADER]: ({ commit }, payload) => {
        commit(FETCH_START)
        SettingService.getHeaderSettings(payload)
            .then(resp => {
                commit(SETTINGS.SET_HEADER, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [SETTINGS.GET_DASHBOARD]: ({ commit }, payload) => {
        commit(FETCH_START)
        return SettingService.getDashboardSettings(payload)
            .then(resp => {
                commit(SETTINGS.SET_DASHBOARD, resp)
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [SETTINGS.GET_FEEDBACK]: ({ commit }, payload) => {
        commit(FETCH_START)
        return SettingService.getFeedbackSettings(payload)
            .then(resp => {
                commit(SETTINGS.SET_FEEDBACK, resp)
                // commit(FETCH_SUCCESS)
                return resp
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
    [SETTINGS.GET_COUNTRY]: ({ commit }, payload) => {
        commit(FETCH_START)
        SettingService.getCountry(payload)
            .then(resp => {
                commit(SETTINGS.GET_SOCIAL, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
}
export default {
    state,
    getters,
    mutations,
    actions,
}
