export default [
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'mentorships',
        name: 'mentorships',
        component: () => import('../views/Mentorships.vue'),
    },
    {
        meta: {
            isAuthenticated: true,
        },
        path: 'mentorship-details/:uuid',
        name: 'mentorship-details',
        component: () => import('../views/MentorshipDetail.vue'),
    },
]
