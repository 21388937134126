<template>
    <div>
        <div class="page-banner">
            <img
                v-if="isCustomBanner"
                loading="lazy"
                class="custom-banner"
                :src="banner"
            />
            <img
                v-else
                loading="lazy"
                :src="bannerImage.imgBig"
                :srcset="
                    bannerImage.imgBig +
                        ' 1920w, ' +
                        bannerImage.imgLarge +
                        ' 1600w, ' +
                        bannerImage.imgMedium +
                        ' 1200w'
                "
                :alt="bannerImage.imgAlt"
            />
            <div class="overlay"></div>
            <span
                ><img
                    loading="lazy"
                    src="@/assets/images/svg/pattern.svg"
                    alt=""
            /></span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        bannerImage: Object,
        isCustomBanner: {
            type: Boolean,
            default: false,
        },
        banner: {
            type: String,
            default: '',
        },
    },
}
</script>

<style lang="scss">
.page-banner {
    --h: 560px;
    --boverlay: #f802a6;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: var(--h);
    overflow: hidden;
    // @media screen and (max-width: 1800px) {
    .custom-banner {
        height: 100%;
        object-fit: cover;
    }
    // }
    @media screen and (max-width: 1500px) {
        --h: 560px;
    }
    & > img {
        height: 100%;
        object-fit: cover;
        width: 100%;
        @media screen and (min-width: 1500px) and (max-width: 1800px) {
            width: unset;
            min-width: 100%;
            height: 100%;
        }
        // @media screen and (min-width: 991px) and (max-width: 1300px){
        //     width: unset;
        //     min-width: 100%;
        //     height: 100%;
        // }
        @media screen and (max-width: 1200px) {
            width: unset;
        }
    }
    @supports (object-fit: cover) {
        & > img {
            @media screen and (max-width: 1300px) {
                width: 100%;
                height: 100%;
                object-fit: cover;
                -o-object-fit: cover;
                object-position: left;
                -o-object-position: left;
            }
        }
    }

    &:after,
    &:before {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        content: '';
        // background: rgb(237,0,196);
        // background: linear-gradient(90deg, rgba(237,0,196,1) 0%, rgba(255,3,145,1) 100%);
        background: $primary;
        opacity: 0.2;
        z-index: 1;
        top: 0px;
    }
    &:before {
        background: #000;
        opacity: 0.2;
        z-index: 0;
    }
    .overlay {
        position: absolute;
        left: 0;
        bottom: 0px;
        opacity: 0.8;
        width: 100%;
        height: 100%;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,000000+100 */
        background: rgba(0, 0, 0, 0.5); /* Old browsers */
        background: -moz-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 1) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
    }
    span {
        position: absolute;
        width: 300px;
        height: 300px;
        right: 0;
        left: auto;
        overflow: hidden;
        img {
            min-width: 210px;
            width: 210px;
            transform: rotate(-52deg);
            position: absolute;
            right: -26px;
        }
        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    @media screen and (max-width: 1600px) {
        span {
            top: 85px;
            img {
                min-width: 150px;
                width: 150px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        span {
            img {
                min-width: 100px;
                width: 100px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        --h: 490px;
    }
}

// ar style

.ar {
    .page-banner {
        // --h: 570px;
        // @media screen and (max-width: 1500px) {
        //     --h: 543px;
        // }
        span {
            left: 0;
            right: auto;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            @media screen and (max-width: 767px) {
                display: none;
            }
        }
    }
}
</style>
