<template>
    <div>
        <div class="users__profile">
            <div class="users__profile--image img-hover">
                <router-link
                    :to="{
                        name: 'view-zainers',
                        params: {
                            username: user.id,
                        },
                    }"
                >
                    <img
                        v-if="user.photo"
                        loading="lazy"
                        :src="user.photo"
                        alt=""
                    />
                    <img
                        v-else
                        loading="lazy"
                        src="@/assets/images/svg/person.svg"
                        alt=""
                    />
                </router-link>
            </div>
            <div class="users__info">
                <p class="users__info--name">
                    <router-link
                        :to="{
                            name: 'view-zainers',
                            params: {
                                username: user.id,
                            },
                        }"
                    >
                        {{ user.display_name }}</router-link
                    >
                </p>
                <p class="users__info--country">{{ user.country }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        user: Object,
    },
}
</script>

<style lang="scss" scoped>
.users {
    &__profile {
        display: flex;
        align-items: center;
        // padding: 20px 0 20px 40px;
        padding-bottom: rem(20px);
        position: relative;
        &--image {
            width: rem(88px);
            height: rem(88px);
            overflow: hidden;
            border-radius: 50%;
            margin-right: rem(20px);
            img {
                max-width: 100%;
            }
        }
        &:after {
            content: '';
            position: absolute;
            right: 0px;
            bottom: 0;
            height: 1px;
            width: 90%;
            background-color: #e0e0e0;
        }
    }
    &__info {
        p {
            line-height: 1.4;
        }
        &--name {
            font-size: rem(17px);
            color: #323232;
            font-weight: 700;
            margin-bottom: 0;
            a {
                color: #323232;
            }
        }
        &--country {
            font-size: rem(15px);
            color: #a9a9a9;
            margin-bottom: 0px;
        }
    }
    @media screen and (max-width: 1199px) and (min-width: 992px) {
        &__profile {
            &--image {
                width: rem(65px);
                height: rem(65px);
                margin-right: rem(16px);
            }
        }
        &__info {
            &--name {
                font-size: rem(15px);
            }
            &--country {
                font-size: rem(14px);
            }
        }
    }
    @media screen and (max-width: 767px) {
        &__profile {
            &--image {
                width: rem(60px);
                height: rem(60px);
                margin-right: rem(16px);
            }
        }
    }
}

// ar style

.ar {
    .users {
        &__profile {
            position: relative;
            &--image {
                margin-left: rem(20px);
                margin-right: 0;
            }
            &:after {
                content: '';
                left: 0px;
                right: auto;
            }
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
            &__profile {
                &--image {
                    margin-left: rem(16px);
                    margin-right: 0;
                }
            }
        }
        @media screen and (max-width: 767px) {
            &__profile {
                &--image {
                    margin-left: rem(16px);
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
