<template>
    <div class="teamMembers">
        <div v-for="(option, i) in selectedOptions" :key="i">
            <div class="teamMembers__member" :class="{ disabled: disabled }">
                <div class="teamMembers__member--image">
                    <div class="image" v-if="photo">
                        <img :src="option.photo" />
                    </div>
                    <p v-for="(field, k) in newFields" :key="k">
                        {{ option[field] }}
                    </p>
                </div>
                <div class="teamMembers__member--delete">
                    <a
                        v-if="!disabled"
                        @click="click(option[options_value_key])"
                        >{{ $t('Delete') }}</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
export default {
    props: {
        selectedOptions: {
            type: Array,
        },
        fields: {
            type: Array,
        },
        options_value_key: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            newFields: [],
            photo: false,
        }
    },
    created() {
        this.newFields = cloneDeep(this.fields)
        if (this.fields.includes('photo')) {
            this.photo = true
            this.newFields = this.newFields.filter(function(field) {
                return field != 'photo'
            })
        }
    },
    methods: {
        click(value) {
            this.$emit('deleteOption', value)
        },
    },
}
</script>

<style lang="scss" scoped>
.teamMembers__member {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(20px) 0 rem(18px) 0;
    &:not(:last-child) {
        border-bottom: 1px solid #939393;
    }
    &.disabled {
        opacity: 0.5;
    }
    &--image {
        display: flex;
        align-items: center;
        .image {
            border-radius: 150px;
            width: 70px;
            height: auto;
        }
        p {
            font-size: rem(22px);
            font-weight: 700;
            color: var(--primary);
            margin: 0 0 0 rem(30px);
        }
    }
    &--name {
        p {
            font-size: rem(22px);
            font-weight: 700;
            color: var(--primary);
            margin: 0;
        }
    }
    &--delete {
        a {
            --color: #e4d700;
            font-size: rem(16px);
            font-weight: 600;
            color: var(--color);
            text-transform: uppercase;
            position: relative;
            &:after {
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                background: var(--color);
                left: 0;
                bottom: 2px;
            }
            @media screen and (min-width: 1025px) {
                &:hover {
                    --color: var(--primary);
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        &--image {
            p {
                font-size: rem(18px);
            }
        }
    }
    @media screen and (max-width: 575px) {
        flex-direction: column;
        align-items: flex-start;
        &--delete {
            width: 100%;
            text-align: right;
            margin-top: rem(10px);
        }
    }
}

// ar style

.ar .teamMembers__member--image p {
    margin: 0 1.875rem 0 0;
}
</style>
