<template>
    <validation-provider
        :rules="required ? 'required' : ''"
        :name="name"
        v-slot="{ errors }"
    >
        <div class="form-group">
            <div v-for="option in options" :key="option.id">
                <label
                    class="control-label"
                    :class="{ 'disabled-checkbox-label': disabled }"
                >
                    <input
                        type="checkbox"
                        :value="option.text"
                        v-model="selectedOptions"
                        @change="handleChange"
                        :required="
                            selectedOptions &&
                            selectedOptions[selectedOptions.length - 1]
                                ? false
                                : required
                        "
                        @focus="handleFocus"
                        :disabled="disabled"
                    />
                    {{ option.text }}
                    <span v-if="required && type === 'checkbox'">*</span>
                </label>
            </div>
            <span class="invalid-feedback">{{ errors[0] }}</span>
        </div>
    </validation-provider>
</template>

<script>
export default {
    props: {
        name: String,
        options: [String, Array],
        value: {
            type: String,
            default: '',
        },
        required: Boolean,
        type: String,
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedOptions: this.value.split(','),
        }
    },
    watch: {
        value(newValue) {
            this.selectedOptions = newValue.split(',')
        },
    },
    mounted() {
        if (this.value) this.selectedOptions = this.value.split(',')
    },
    methods: {
        handleFocus(event) {
            if (this.required) {
                event.target.click()
                event.target.click()
            }
        },
        handleChange() {
            this.$emit('input', this.selectedOptions, this.name)
        },
    },
}
</script>

<style scoped lang="scss">
.invalid-feedback {
    display: block !important;
}
.control-label {
    cursor: pointer;
}
.disabled-checkbox-label {
    border-color: transparent;
    color: #999;
    background-color: #f4f4f4;
    opacity: 0.6;
    pointer-events: none;
}
</style>
