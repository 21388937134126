<template>
    <b-form-group>
        <label class="control-label" v-if="label">
            {{ label }}<span v-if="required">* </span>
        </label>

        <div class="grid" data-grid-item-gap="15">
            <validation-provider
                :rules="`${[required ? 'required' : '']}`"
                name="country_code"
                v-slot="{ errors, valid }"
            >
                <v-select
                    ref="phoneDropdown"
                    id="phoneDropdown"
                    @input="getValue"
                    :labelNotFound="$t('no results matched')"
                    :searchable="searchable"
                    labelSearchPlaceholder=""
                    v-model="country_code"
                    :options="options"
                    tabindex="-98"
                    name="country_code"
                    :state="errors[0] ? false : valid ? true : null"
                >
                </v-select>
                <b-form-invalid-feedback>
                    {{ getError(errors[0]) }}
                </b-form-invalid-feedback>
            </validation-provider>
            <validation-provider
                :rules="`${[required ? 'required' : '']}|phoneNumber:${value}`"
                name="phone"
                v-slot="{ errors, valid }"
            >
                <b-input
                    name="phone"
                    :placeholder="$t('Phone')"
                    :required="required"
                    v-model="phone_number"
                    :state="errors[0] ? false : valid ? true : null"
                    @input="getValue"
                    type="text"
                ></b-input>
                <b-form-invalid-feedback>
                    {{ getError(errors[0]) }}
                </b-form-invalid-feedback>
            </validation-provider>
        </div>
    </b-form-group>
</template>
<style lang="scss" scoped>
#profile-settings-section .v-select {
    height: 3.875rem !important;
}
#profile-settings-section .v-select-toggle {
    height: 3.875rem !important;
    border: 1px solid red;
}
#profile-settings-section .dropdown-toggle.btn-light {
    background: #fff;
    padding: 0rem 1.125rem 0rem 1.875rem;
}
.form-group .grid {
    grid-template-columns: 7.5rem 1fr;
}
.form-group .bootstrap-select > .dropdown-toggle {
    height: 3.875rem;
}
@media screen and (max-width: 767px) {
    .form-group .grid {
        grid-template-columns: 6.25rem 1fr;
    }
    .form-group .bootstrap-select > .dropdown-toggle.btn-light {
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
    }
}
.button-row {
    justify-content: center;
    margin-bottom: 1.5625rem;
}
</style>
<script>
import allCountries from '@/common/allCountries'
import parsePhoneNumber from 'libphonenumber-js'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { localize } from 'vee-validate'

export default {
    props: {
        label: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        value: {
            type: String,
        },
    },
    data() {
        return {
            country_code:
                // '+965',
                {
                    name: 'Kuwait',
                    ios2: 'kw',
                    value: '+965',
                    text: '+965',
                },
            // country_code: null,
            phone_number: '',
            options: allCountries,
            searchable: true,
        }
    },
    methods: {
        getError(error) {
            if (this.$te(error)) {
                return this.$t(error)
            } else {
                return error
            }
        },
        getValue() {
            this.$emit('input', this.country_code.value + this.phone_number)
        },
        splitPhoneNumber() {
            this.phone_number = this.getNumber(this.value)
            this.country_code = allCountries.find(
                value => value.value == this.getCountryCode(this.value),
            )
        },
        getNumber(number) {
            if (!number) return this.phone_number
            let phoneNumber = parsePhoneNumber(number)
            if (!phoneNumber) return this.phone_number
            if (phoneNumber.formatNational().includes(')')) {
                return phoneNumber
                    .formatNational()
                    .split(' ')[1]
                    .replaceAll('-', '')
            } else {
                return phoneNumber.nationalNumber
            }
        },
        getCountryCode(number) {
            if (!number) {
                return this.country_code.value
            }
            let phoneNumber = parsePhoneNumber(number)
            if (!phoneNumber) return this.country_code.value
            if (phoneNumber.formatNational().includes(')')) {
                return (
                    phoneNumber.formatInternational().split(' ')[0] +
                    phoneNumber
                        .formatNational()
                        .split(')')[0]
                        .replaceAll('(', '-')
                )
            } else return `+${phoneNumber.countryCallingCode}`
        },
    },
    created() {
        this.splitPhoneNumber()
    },
    mounted() {
        localize(this.$i18n.locale, this.dictionary[this.$i18n.locale])
        let dropDownToogle = this.$refs.phoneDropdown.$el.getElementsByClassName(
            'v-select-toggle',
        )[0]
        dropDownToogle.addEventListener('click', function() {
            document
                .getElementById('phoneDropdown')
                .getElementsByTagName('input')[0]
                .focus()
        })
    },
    computed: {
        dictionary() {
            return {
                en: {
                    messages: {
                        phoneNumber: () => 'Please enter a valid phone number',
                    },
                },
                ar: {
                    messages: {
                        phoneNumber: () => 'يرجى إدخال رقم هاتف صالح',
                    },
                },
            }
        },
    },
    watch: {
        value() {
            if (this.value && isValidPhoneNumber(this.value)) {
                this.splitPhoneNumber()
            }
        },
        '$i18n.locale': function() {
            localize(this.$i18n.locale, this.dictionary[this.$i18n.locale])
        },
    },
}
</script>
