import { ACTIVITIES } from '@/store/actions/activities'

import { ApiServiceHelper } from '../crud-helpers'

export const ActivitiesHelper = {
    list(params) {
        return ApiServiceHelper.execAction(ACTIVITIES.LIST, params)
    },
    cmsRedirectUrl(params) {
        return ApiServiceHelper.execAction(ACTIVITIES.CMS_REDIRECT_URL, params)
    },
    checkUserLogin(params) {
        return ApiServiceHelper.execAction(ACTIVITIES.CHECK_USER_LOGIN, params)
    },
}
