<template>
    <footer
        :style="
            isNewsLetterModalOpen ? { 'z-index': '999' } : { 'z-index': '5' }
        "
    >
        <div class="footer--wrapper">
            <div
                class="footer--social"
                v-if="footerSettings && footerSettings.show_social_media"
            >
                <ul>
                    <li
                        v-for="(social, key) in socials"
                        :key="key"
                        :style="{
                            display: `${
                                social.value.enable ? 'list-item' : 'none'
                            }`,
                        }"
                    >
                        <a
                            :href="social.value.url"
                            v-if="social.value.text == 'Rss'"
                        >
                            <i
                                loading="lazy"
                                class="fa"
                                :class="[social.value.font_awesome_class]"
                            ></i>
                        </a>
                        <a :href="social.value.url" v-else>
                            <i
                                loading="lazy"
                                class="fab"
                                :class="[social.value.font_awesome_class]"
                            ></i>
                        </a>
                    </li>
                </ul>
            </div>
            <div
                class="footer--links"
                v-if="footerSettings && footerSettings.show_link_section"
            >
                <ul>
                    <li v-for="(page, key) in footersList" :key="key">
                        <a
                            v-if="
                                page.login_requird &&
                                    page.active &&
                                    userAuth &&
                                    userAuth.id
                            "
                            @click="redirectToCMS(page.url)"
                        >
                            {{ page.name }}
                        </a>
                        <a
                            v-else-if="!page.login_requird && page.active"
                            @click="redirectToCMS(page.url)"
                        >
                            {{ page.name }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div
            class="copyright"
            v-if="footerSettings && footerSettings.show_copyright"
        >
            <p>
                © {{ currentYear }}
                {{ $t('Copyright Zain All Rights Reserved') }}
            </p>
        </div>
        <div class="newsletter" v-if="isNewsLetterModalOpen">
            <a
                class="newsletterClose"
                @click="isNewsLetterModalOpen = !isNewsLetterModalOpen"
            >
                <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 409.81 409.81"
                >
                    <use xlink:href="#menu-close-icon"></use>
                </svg>
            </a>
            <h6>SIGN UP FOR UPDATES</h6>
            <form @submit.prevent="subscribeNewsLetter()">
                <input
                    type="email"
                    name="email_field"
                    class="form-control is-invalid"
                    placeholder="youremail@domain.com"
                    v-model="subscriptionEmail"
                />
                <div
                    v-if="subscriptionResponse.length"
                    class="newsletter-message"
                >
                    <h2>
                        <b>{{ subscriptionResponse }}</b>
                    </h2>
                </div>
                <p></p>
                <button type="submit" class="btn btn-primary">SUBSCRIBE</button>
            </form>
        </div>
    </footer>
</template>

<script>
import { ActivitiesHelper } from '@/common/crud-helpers/activities'
import { UsersHelper } from '@/common/crud-helpers/users'
import { API_BASE } from '@/conf'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        socials: [],
        pages: [],
        isNewsLetterModalOpen: false,
        subscriptionEmail: '',
        subscriptionResponse: '',
    }),
    computed: {
        currentYear() {
            return new Date().getFullYear()
        },
        footersList() {
            return this.pages.filter(page => {
                if (this.userAuth && this.userAuth.id) {
                    return page.active === true
                } else {
                    return page.login_requird === false && page.active === true
                }
            })
        },
        ...mapGetters(['footerSettings', 'socialSettings', 'userAuth']),
    },
    methods: {
        subscribeNewsLetter() {
            UsersHelper.subscribe({
                email_field: this.subscriptionEmail,
            })
                .then(resp => {
                    if (resp.data === 'Email already submitted') {
                        this.subscriptionResponse = 'Email already submitted'
                    } else {
                        this.subscriptionResponse =
                            'You have been successfully subscribed.'
                        this.subscriptionEmail = ''
                    }
                })
                .catch(err => {
                    this.subscriptionResponse =
                        err.response &&
                        err.response.data &&
                        err.response.data.email_field
                            ? err.response.data.email_field[0]
                            : 'An error occures'
                })
        },
        redirectToCMS(redirectUrl) {
            if (redirectUrl === 'subscribeNewsLetter') {
                // if (this.userAuth && this.userAuth.id && this.userAuth.email) {
                //     this.subscriptionEmail = this.userAuth.email
                //     this.subscribeNewsLetter()
                // } else {
                this.isNewsLetterModalOpen = !this.isNewsLetterModalOpen
                // }
                return
            }
            if (localStorage.getItem('token')) {
                ActivitiesHelper.cmsRedirectUrl({
                    API_BASE: API_BASE,
                    lang: this.$i18n.locale,
                    url: redirectUrl,
                })
                    .then(resp => {
                        window.location.href = JSON.parse(
                            resp.request.response,
                        ).url
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                window.location.href = redirectUrl
            }
        },
    },
    watch: {
        footerSettings() {
            this.pages = []
            let pages = cloneDeep(this.footerSettings.content)

            pages.forEach(page => {
                let url = page.value.page_url
                url = url.replace('/en/', '/' + this.$i18n.locale + '/')
                this.pages.push({
                    name: page.value[`name_${this.$i18n.locale}`],
                    url: API_BASE + url,
                    login_requird: page.value.login_requird,
                    active: page.value.active,
                })
            })

            let newsletter = cloneDeep(
                JSON.parse(this.footerSettings.newsletter),
            )

            this.pages.push({
                name: newsletter[0].value[`name_${this.$i18n.locale}`],
                url: 'subscribeNewsLetter',
                login_requird: false,
                active: newsletter[0].value.active,
            })
        },
        socialSettings() {
            this.socials = cloneDeep(JSON.parse(this.socialSettings.content))
        },
    },
}
</script>
<style lang="scss" scoped>
.newsletter {
    transform: scale(1);
    z-index: 9999;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: #000;
    padding: 1.5625rem 3.125rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
    }
    h6 {
        color: #fff;
    }
    .newsletterClose {
        position: absolute;
        top: 2%;
        right: 2%;
        color: #fff;
        svg {
            transition: all 0.5s ease;
            width: 29px;
            height: auto;
            overflow: hidden;
            vertical-align: middle;
        }
        @media screen and (min-width: 1025px) {
            svg:hover {
                transform: rotate(180deg);
            }
        }
    }
    a {
        text-decoration: none;
        background-color: transparent;
    }
    form {
        width: 100%;
        display: flex;
        @media screen and (max-width: 768px) {
            display: block;
        }
        .newsletter-message {
            color: rgb(139, 8, 139);
        }
        .form-control {
            background: transparent;
            border: 0px;
            color: #fff;
            font-size: 1.5625rem;
            border-bottom: solid 1px #fff;
            height: 3.875rem;
            padding: 0 1.375rem;
            outline: none;
            border-radius: 0;
            display: block;
            width: 100%;
            line-height: 1.5;
            &:focus {
                background: transparent;
            }
            &::placeholder {
                color: white;
                opacity: 1;
            }
            &:-ms-input-placeholder {
                color: white;
            }
            &::-ms-input-placeholder {
                color: white;
            }
        }
    }
}
footer {
    position: relative;
    margin-top: auto;
}
.footer--wrapper {
    /* background: rgb(62, 60, 160);
    background: linear-gradient(
        175deg,
        rgba(62, 60, 160, 1) 0%,
        rgba(44, 84, 147, 1) 100%
    );*/
    background: rgba(245, 225, 44, 0.2);
    padding: 45px 0;
    .footer--social {
        margin: auto;
        ul {
            li {
                &:first-child {
                    padding-left: 0 !important;
                }
                padding-left: 40px;
                a {
                    color: #009b74;
                    font-size: 28px;
                    @media screen and (min-width: 1025px) {
                        &:hover {
                            color: var(--green);
                        }
                    }
                }
            }
        }
    }
    .footer--links {
        margin-top: 35px;
        ul {
            li {
                &:first-child {
                    a {
                        padding-left: 0 !important;
                    }
                }
                a {
                    text-decoration: none;
                    /* color: #fff; */
                    padding-left: 30px;
                    /* font-size: rem(18px);*/
                    font-weight: 600;
                    color: #009b74;
                    font-size: 1.35rem;
                    @media screen and (min-width: 1025px) {
                        &:hover {
                            color: var(--green);
                        }
                    }
                }
            }
        }
    }
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        li {
            a {
                @media screen and (min-width: 1025px) {
                    &:hover {
                        color: var(--primary);
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        padding: 20px 0;
    }
    @media screen and (max-width: 575px) {
        .footer--social {
            ul {
                justify-content: space-around;
                li {
                    padding-left: 0;
                    a {
                        font-size: 22px;
                    }
                }
            }
        }
        .footer--links {
            margin-top: 25px;
            ul {
                flex-wrap: wrap;
                max-width: 350px;
                margin: auto;
                li {
                    text-align: center;
                    max-width: 50%;
                    flex: 0 0 50%;
                    margin-bottom: rem(12px);
                    a {
                        padding: 0;
                    }
                }
            }
        }
    }
}
.copyright {
    text-align: center;
    padding: 30px 0;
    background: #f6f6f6;
    p {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
    }
    @media screen and (max-width: 767px) {
        padding: 15px 0 60px 0;
        p {
            font-size: 16px;
        }
    }
    @media screen and (max-width: 414px) {
        p {
            font-size: 14px;
        }
    }
}

// ar style

.ar .footer--wrapper .footer--social ul li {
    padding-right: 40px;
    padding-left: 0;
}
.ar .footer--wrapper .footer--links ul li a {
    padding-right: 30px;
    padding-left: 0;
}
@media screen and (max-width: 575px) {
    .ar .footer--wrapper .footer--social ul li {
        padding-right: 0;
    }
}
</style>
