import { NOTIFICATION } from '@/store/actions/notification'

import { ApiServiceHelper } from '../crud-helpers'

export const NotificationHelper = {
    getNotification(params) {
        return ApiServiceHelper.execAction(NOTIFICATION.LIST, params)
    },
    getNotificationCount(params) {
        return ApiServiceHelper.execAction(NOTIFICATION.LIST_COUNT, params)
    },
    seenNotification(params) {
        return ApiServiceHelper.execAction(NOTIFICATION.SEEN, params)
    },
}
