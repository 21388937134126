import { ApiService } from '@/common/api_services'
import i18n from '@/common/i18n'
import axios from 'axios'

export const IdeasService = {
    query(params) {
        return ApiService.query('idea/my-ideas/', { params })
    },
    get(pk) {
        return ApiService.get('idea/my-ideas', pk)
    },
    getIdeaFormFields() {
        return ApiService.query('idea/idea-form')
    },
    create(params) {
        return ApiService.post('idea/my-ideas/', params)
    },
    put(pk, params) {
        return ApiService.put(`idea/my-ideas/${pk}/`, params)
    },
    destroy(pk) {
        return ApiService.delete(`idea/my-ideas/${pk}`)
    },
    queryTrendIdea(params) {
        return ApiService.query('idea/trend-ideas/', { params })
    },
    getTrendIdea(pk) {
        return ApiService.get('idea/trend-ideas', pk)
    },
    queryIdea(params) {
        return ApiService.query('idea/ideas/', { params })
    },
    getOpcoIdeaCategory(params) {
        if (i18n.locale == 'ar') {
            return axios.get(
                process.env.VUE_APP_ZAINIAC_API_BASE +
                    '/ar/api/v1/idea/opco-categories/',
                { params },
            )
        } else {
            return ApiService.query('idea/opco-categories/', { params })
        }
    },
    getActiveIdeaCategories(params) {
        if (i18n.locale == 'ar') {
            return axios.get(
                process.env.VUE_APP_ZAINIAC_API_BASE +
                    '/ar/api/v1/idea/active-idea-categories/',
                { params },
            )
        } else {
            return ApiService.query('idea/active-idea-categories/', { params })
        }
    },
    getIdea(pk) {
        return ApiService.get('idea/ideas', pk)
    },
    getByIdIdea(pk) {
        return ApiService.get('idea/ideas-id', pk)
    },
    getBucketCategoriesList(params) {
        return ApiService.query(`idea/category-buckets/`, { params })
    },
    deleteIdeaDocument(pk) {
        return ApiService.delete(`idea/document/${pk}`)
    },
    updateIdeaDocument(params) {
        return ApiService.update(`idea/document`, params.id, params)
    },
    updateIdeaMedia(params) {
        return ApiService.update(`idea/media`, params.id, params)
    },
    postCommentLike(id) {
        return ApiService.post(`idea/comment-likes/${id}/`)
    },
    postLike(uuid) {
        return ApiService.post(`idea/likes/${uuid}/`)
    },
    getLikes(params) {
        return ApiService.query(`idea/likes/${params.uuid}/`, { params })
    },
    postFollower(uuid) {
        return ApiService.post(`idea/followers/${uuid}/`)
    },
    getFollowers(params) {
        return ApiService.query(`idea/followers/${params.uuid}/`, { params })
    },
    postContributor(uuid) {
        return ApiService.post(`idea/contributors/${uuid}/`)
    },
    getContributors(params) {
        return ApiService.query(`idea/contributors/${params.uuid}/`, { params })
    },
    postComment(params) {
        return ApiService.post(`idea/comments/${params.uuid}/`, params)
    },
    getComments(params) {
        return ApiService.query(`idea/comments/${params.uuid}/`, { params })
    },
}
