export const WORKSHOP = {
    LIST: 'getWorkshopList',
    GET: 'getWorkshop',
    MY_LIST: 'getMyWorkshopList',
    MY_GET: 'getMyWorkshop',
    SET_OBJECT: 'setWorkshop',
    SET_LIST: 'setWorkshopList',
    GET_SETTINGS: 'getWorkshopSettings',
    SET_SETTINGS: 'setWorkshopSettings',
    USER_REGISTRATION: 'userRegistration',
    CANCEL_USER_REGISTRATION: 'cancelUserRegistration',
    GET_USER_REGISTRATION: 'getUserRegistration',
    SET_USER_REGISTRATION: 'setUserRegistration',
}

export const SOCIAL = {
    COMMENT_LIKE: 'postWorkshopCommentLike',
    LIKE: 'postWorkshopLike',
    GET_LIKE: 'getWorkshopLikes',
    SET_LIKE_LIST: 'setWorkshopLikesList',
    FOLLOWER: 'postWorkshopFollower',
    GET_FOLLOWER: 'getWorkshopFollowers',
    SET_FOLLOWER_LIST: 'setWorkshopFollowersList',
    COMMENT: 'postWorkshopComment',
    GET_COMMENTS: 'getWorkshopComments',
    SET_COMMENTS_LIST: 'setWorkshopCommentsList',
    CONTRIBUTOR: 'postWorkshopContributor',
}
