<template>
    <validation-provider
        :rules="required ? 'required' : ''"
        :name="name"
        v-slot="{ errors }"
    >
        <div class="form-group">
            <label class="control-label">
                {{ label }}
                <span v-if="required">*</span>
            </label>
            <div v-for="option in options" :key="option.id">
                <label class="control-label">
                    <input
                        type="radio"
                        :name="name"
                        :value="option.text"
                        v-model="selectedOption"
                        @change="handleChange"
                        :required="required"
                        :disabled="disabled"
                    />
                    {{ option.text }}
                </label>
            </div>
            <span class="invalid-feedback">{{ errors[0] }}</span>
        </div>
    </validation-provider>
</template>

<script>
export default {
    props: {
        name: String,
        label: String,
        options: [Array, String],
        value: String,
        required: Boolean,
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedOption: this.value,
        }
    },
    watch: {
        value(newValue) {
            this.selectedOption = newValue
        },
    },
    mounted() {
        this.selectedOption = this.value
    },
    methods: {
        handleChange() {
            this.$emit('input', this.selectedOption, this.name)
        },
    },
}
</script>

<style scoped lang="scss">
.invalid-feedback {
    display: block !important;
}
.control-label {
    cursor: pointer;
    input[type='radio'] {
        accent-color: #009b74;
    }
}
</style>
