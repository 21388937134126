import { EventsService } from '@/common/api_services/events'
import { EVENTS } from '@/store/actions/events'
import { FETCH_SUCCESS, FETCH_ERROR } from '@/store/common'

const state = {
    eventsList: [],
}

const getters = {
    eventsList: state => state.eventsList,
}

const mutations = {
    [EVENTS.SET_LIST]: (state, resp) => {
        state.eventsList = resp.data
    },
}

const actions = {
    [EVENTS.LIST]: ({ commit }, payload) => {
        return EventsService.query(payload)
            .then(resp => {
                commit(EVENTS.SET_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch(() => {
                commit(FETCH_ERROR)
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
