var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accountNav",attrs:{"id":"accnav"}},[_c('div',{staticClass:"accountNav--wrapper"},[_c('ul',_vm._l((_vm.displayTabs),function(tab,key){return _c('li',{key:key},[(tab.page_url === ("/" + (_vm.$i18n.locale) + "/messages"))?_c('router-link',{attrs:{"to":{
                        name: 'messages',
                        params: {
                            username: 'all',
                        },
                    }}},[_c('img',{attrs:{"loading":"lazy","src":tab.icon_url,"alt":""}}),_c('span',[_vm._v(_vm._s(tab.name))])]):(tab.page_url === ("/" + (_vm.$i18n.locale) + "/logout"))?_c('a',{staticClass:"logout",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.logout}},[_c('img',{attrs:{"loading":"lazy","src":tab.icon_url,"alt":""}}),_c('span',[_vm._v(_vm._s(tab.name))])]):_c('router-link',{attrs:{"to":{
                        path: tab.page_url,
                    }}},[_c('img',{attrs:{"loading":"lazy","src":tab.icon_url,"alt":""}}),_c('span',[_vm._v(_vm._s(tab.name))])])],1)}),0),_c('button',{attrs:{"id":"toogleBtn"},on:{"click":_vm.toggleNav}},[_c('icon',{attrs:{"loading":"lazy","name":"toogle-btn"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }