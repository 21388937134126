<template>
    <div class="wrapper">
        <div id="app">
            <svg-icons></svg-icons>
            <the-header></the-header>
            <router-view />
            <the-footer></the-footer>
            <Feedback v-if="userAuth && userAuth.id" />
        </div>
    </div>
</template>

<script>
import { SettingsHelper } from '@/common/crud-helpers/settings'
import { UsersHelper } from '@/common/crud-helpers/users'
import Feedback from '@/common/layout/Feedback.vue'
import SvgIcons from '@/common/layout/SvgIcons.vue'
import TheFooter from '@/common/layout/TheFooter.vue'
import TheHeader from '@/common/layout/TheHeader.vue'
import { VUE_APP_WS_NOTIFICATIONS } from '@/conf'
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'

export default {
    components: {
        TheHeader,
        TheFooter,
        SvgIcons,
        Feedback,
    },
    computed: {
        ...mapGetters(['token', 'userAuth']),
    },
    watch: {
        token() {
            if (this.token.length > 5) this.createSocketConnection()
        },
    },
    created() {
        let token = JSON.parse(localStorage.getItem('token'))
        if (token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
            // axios.defaults.headers.credentials = true
            // axios.defaults.headers.common['X-CSRFToken'] = JSON.parse(
            //     localStorage.getItem('csrf_token'),
            // )
            UsersHelper.getAuthUser()
            var resp = {
                data: {
                    access:
                        localStorage.getItem('token').length > 1
                            ? JSON.parse(localStorage.getItem('token'))
                            : '',
                    refresh:
                        localStorage.getItem('refresh-token').length > 1
                            ? JSON.parse(localStorage.getItem('refresh-token'))
                            : '',
                },
            }
            this.setTokens(resp)
        }
        // Set up the headers for axios requests
        axios.interceptors.response.use(
            response => {
                return response
            },
            error => {
                const { config, response } = error
                const originalRequest = config
                if (
                    (response.status === 403 || response.status === 401) &&
                    !originalRequest._retry
                ) {
                    originalRequest._retry = true
                    UsersHelper.refreshToken()
                }
                return Promise.reject(error)
            },
        )
        if (this.$i18n.locale == 'ar') {
            document.getElementsByTagName('html')[0].lang = 'ar'
            document.getElementsByTagName('html')[0].classList.add('ar')
        }
        setInterval(() => {
            UsersHelper.refreshToken()
        }, 120000)
        SettingsHelper.getNavMenu({
            limit: 9999999,
        })
        SettingsHelper.getSocialSettings()
        SettingsHelper.getFooterSettings()
        SettingsHelper.getHeaderSettings()
        SettingsHelper.getDashboardSettings()
        SettingsHelper.getFeedbackSettings()
    },
    methods: {
        ...mapMutations([
            'SocketConnected',
            'SocketDisconnected',
            'SocketError',
            'WebsocketMessageReceived',
            'setTokens',
        ]),
        createSocketConnection() {
            const socket = new WebSocket(
                `${VUE_APP_WS_NOTIFICATIONS}?token=${this.token}`,
            )

            socket.onopen = () => {
                this.SocketConnected()
            }

            socket.onclose = () => {
                this.SocketDisconnected()
            }

            socket.onerror = () => {
                this.SocketError()
            }

            socket.onmessage = event => {
                this.WebsocketMessageReceived(event.data)
            }
        },
    },
}
</script>
<style lang="scss">
#app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
</style>
