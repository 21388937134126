<template>
    <div>
        <fixed-header :threshold="150">
            <header class="header" role="Header">
                <div class="container">
                    <div class="d-flex">
                        <div class="header--logo">
                            <a @click="redirectToCMS(homeUrl)">
                                <img
                                    loading="lazy"
                                    src="@/assets/images/logo.png"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div class="header--links">
                            <ul class="list-inline">
                                <!-- <li>
                                    <a @click="redirectToCMS(homeUrl)">
                                        <svg
                                            fill="currentColor"
                                            height="511pt"
                                            viewBox="0 1 511 511.999"
                                            width="24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <use
                                                xlink:href="#header-home-icon"
                                            ></use>
                                        </svg>
                                    </a>
                                </li> -->
                                <li>
                                    <a
                                        href="#"
                                        class="search"
                                        @click.stop.prevent="
                                            searchOpen = !searchOpen
                                        "
                                    >
                                        <svg
                                            fill="currentColor"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <use
                                                xlink:href="#header-search-icon"
                                            ></use>
                                        </svg>
                                    </a>
                                </li>
                                <li
                                    v-if="userAuth && userAuth.id"
                                    class="notification_dropdown"
                                >
                                    <a
                                        href="#"
                                        class="notification_icon"
                                        @click.stop.prevent="
                                            notificationOpenClike
                                        "
                                    >
                                        <svg
                                            id="Capa_1"
                                            height="512"
                                            viewBox="0 0 512 512"
                                            width="512"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <use xlink:href="#bell"></use>
                                        </svg>
                                        <span v-if="notificationCount">{{
                                            notificationCount
                                        }}</span>
                                    </a>
                                    <div
                                        class="notification"
                                        id="notification-popup"
                                        :class="[
                                            notificationOpen ? 'active' : '',
                                        ]"
                                    >
                                        <div
                                            class="scrollWrapper"
                                            style="max-height: 300px;"
                                        >
                                            <notifications />
                                        </div>
                                    </div>
                                </li>
                                <li v-if="userAuth && userAuth.id">
                                    <router-link
                                        :to="{
                                            name: 'profile',
                                            params: {
                                                lang: $i18n.locale,
                                            },
                                        }"
                                        class="user-login"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 492.62 538.39"
                                        >
                                            <use
                                                xlink:href="#header-user-icon"
                                            ></use>
                                        </svg>
                                    </router-link>
                                </li>
                                <li
                                    v-for="(page, key) in headersList"
                                    :key="key"
                                >
                                    <a
                                        @click="
                                            redirectToCMS(
                                                page.url,
                                                page.new_tab,
                                            )
                                        "
                                    >
                                        <img :src="page.icon" />
                                    </a>
                                </li>
                                <li class="lang-selector">
                                    <a @click="changeLocale(otherLocale)">{{
                                        otherLangText
                                    }}</a>
                                </li>
                                <li>
                                    <a href="#" @click.stop.prevent="openMenu">
                                        <svg
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 512 459.49"
                                        >
                                            <use
                                                xlink:href="#header-menu-icon"
                                            ></use>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                            <!-- on-desktop -->
                            <router-link
                                v-if="
                                    $route.name !== 'login' &&
                                        $route.name !== 'submit-idea' &&
                                        $route.name !== 'resubmit-idea' &&
                                        $route.name !== 'edit-idea' &&
                                        !userAuth.is_mentor &&
                                        dashboardSettings &&
                                        dashboardSettings.dashboard_settings &&
                                        dashboardSettings.dashboard_settings
                                            .enable_idea_button
                                "
                                :to="{
                                    name: 'submit-idea',
                                    params: {
                                        lang: $i18n.locale,
                                    },
                                }"
                                class="btn-idea"
                            >
                                <svg
                                    fill="#fff"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 459.89 512"
                                >
                                    <use xlink:href="#header-idea-icon"></use>
                                </svg>
                                <span>{{ $t('ADD IDEA') }}</span>
                            </router-link>
                            <!-- on-mobile -->
                            <div
                                v-if="userAuth && userAuth.id"
                                class="btn-idea on-mobile header-button-main enable-idea-button"
                            >
                                <!-- :class="[
                                    dashboardSettings &&
                                    dashboardSettings.dashboard_settings &&
                                    dashboardSettings.dashboard_settings
                                        .enable_idea_button
                                        ? 'enable-idea-button'
                                        : 'disable-idea-button',
                                ]" -->
                                <router-link
                                    v-if="
                                        $route.name !== 'login' &&
                                            $route.name !== 'submit-idea' &&
                                            !userAuth.is_mentor &&
                                            dashboardSettings &&
                                            dashboardSettings.dashboard_settings &&
                                            dashboardSettings.dashboard_settings
                                                .enable_idea_button
                                    "
                                    :to="{
                                        name: 'submit-idea',
                                        params: {
                                            lang: $i18n.locale,
                                        },
                                    }"
                                    class="header-button add-idea"
                                >
                                    <span>
                                        <svg
                                            fill="#fff"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 459.89 512"
                                        >
                                            <use
                                                xlink:href="#header-idea-icon"
                                            ></use>
                                        </svg>
                                        <!--<svg
                                            fill="#fff"
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="plus"
                                            class="svg-inline--fa fa-plus fa-w-14"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                        >
                                            <use
                                                xlink:href="#mobile-idea-icon"
                                            ></use>
                                        </svg>-->
                                    </span>
                                    {{ $t('ADD IDEA') }}
                                </router-link>
                                <a
                                    v-if="
                                        userAuth &&
                                            userAuth.id &&
                                            feedbackSettings &&
                                            feedbackSettings.form_fields
                                    "
                                    class="header-button feed-back"
                                    @click="$refs.feedbackForm.openForm()"
                                >
                                    {{
                                        feedbackSettings[
                                            `title_${$i18n.locale}`
                                        ]
                                    }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </fixed-header>
        <the-menu ref="menu"></the-menu>
        <search :searchOpen="searchOpen"></search>
        <Feedback ref="feedbackForm" v-if="userAuth && userAuth.id" />
    </div>
</template>

<script>
import { ActivitiesHelper } from '@/common/crud-helpers/activities'
import { NotificationHelper } from '@/common/crud-helpers/notification'
import { SettingsHelper } from '@/common/crud-helpers/settings'
import Feedback from '@/common/layout/Feedback.vue'
import Notifications from '@/common/layout/Notifications'
import Search from '@/common/layout/Search'
import TheMenu from '@/common/layout/TheMenu'
import { API_BASE } from '@/conf'
import cloneDeep from 'lodash/cloneDeep'
import FixedHeader from 'vue-fixed-header'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            searchOpen: false,
            notificationOpen: false,
            homeUrl: API_BASE + '/' + this.$i18n.locale + '/',
            headers: [],
        }
    },
    components: {
        FixedHeader,
        TheMenu,
        Search,
        Notifications,
        Feedback,
    },
    mounted() {
        document.addEventListener('click', e => {
            const isClosest = e.target.closest('#notification-popup')
            if (!isClosest && this.notificationOpen) {
                this.notificationOpenClike()
            }
        })
        if (!this.feedbackSettings || this.feedbackSettings.form_fields)
            SettingsHelper.getFeedbackSettings()
    },
    computed: {
        ...mapGetters([
            'notificationCount',
            'headerSettings',
            'userAuth',
            'dashboardSettings',
            'feedbackSettings',
        ]),
        otherLangText() {
            return this.$i18n.locale === 'ar' ? 'English' : 'العربية'
        },
        otherLocale() {
            return this.$i18n.locale === 'en' ? 'ar' : 'en'
        },
        headersList() {
            return this.headers.filter(page => {
                if (this.userAuth && this.userAuth.id) {
                    return page.enable === true
                } else {
                    return page.required_login === false && page.enable === true
                }
            })
        },
    },
    methods: {
        redirectToCMS(redirectUrl, new_tab) {
            if (localStorage.getItem('token')) {
                ActivitiesHelper.cmsRedirectUrl({
                    API_BASE: API_BASE,
                    lang: this.$i18n.locale,
                    url: redirectUrl,
                })
                    .then(resp => {
                        if (new_tab) {
                            window.open(
                                JSON.parse(resp.request.response).url,
                                '_blank',
                            )
                        } else {
                            window.location.href = JSON.parse(
                                resp.request.response,
                            ).url
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                if (new_tab) {
                    window.open(redirectUrl, '_blank')
                } else {
                    window.location.href = redirectUrl
                }
            }
        },
        openMenu: function() {
            var m = document.querySelector('.mainNavigation')
            m.classList.add('show')
            document.body.classList.add('no-scroll')
            this.$refs.menu.menuAnimation()
        },
        changeLocale(locale) {
            this.$i18n.locale = locale
            this.$router.push({
                params: { lang: locale },
            })
            this.$router.go()
        },
        notificationOpenClike() {
            if (this.notificationOpen) {
                NotificationHelper.seenNotification()
                NotificationHelper.getNotificationCount()
            }
            this.notificationOpen = !this.notificationOpen
        },
    },
    watch: {
        headerSettings() {
            this.headers = []
            let headers = cloneDeep(this.headerSettings.header_icons)

            headers.forEach(header => {
                this.headers.push({
                    icon: header.value.icon,
                    url: header.value[`url_${this.$i18n.locale}`],
                    required_login: header.value.required_login,
                    enable: header.value.enable,
                    new_tab: header.value.new_tab,
                })
            })
        },
    },
}
</script>
<style lang="scss" scoped>
@media (min-width: 1700px) {
    .container {
        max-width: 1600px;
    }
}
</style>
<style lang="scss">
a {
    cursor: pointer;
}
.header {
    position: fixed;
    z-index: 99;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: rem(55px) 0px;
    transition: all 0.6s;
    &:after {
        border: solid 2px var(--secondary);
        bottom: -2px;
        position: absolute;
        left: 0px;
        right: 0px;
        margin: 0 auto;
        width: 0%;
        content: '';
        transform: scale(0, 0);
        transition: all 0.6s linear;
    }
    // .grid{
    //     grid-template-columns: 170px 1fr;
    // }
    &--logo {
        height: 45px;
        @media screen and (max-width: 768px) {
            height: 30px;
        }
        img {
            height: 100%;
        }
    }
    &--links {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        svg {
            width: 25px;
            height: 25px;
        }
        img {
            width: 25px;
            height: 25px;
        }
        ul.list-inline {
            display: flex;
            align-items: center;
            justify-content: space-around;
            li {
                list-style: none;
                margin-right: rem(25px);
                padding-right: rem(25px);
                position: relative;
                display: flex;
                &:not(:last-child) {
                    &:after {
                        content: '';
                        position: absolute;
                        right: 0px;
                        top: 25%;
                        display: block;
                        width: 1px;
                        height: 12px;
                        background-color: #fff;
                    }
                }
                a {
                    display: flex;
                    color: #fff;

                    @media screen and (min-width: 1025px) {
                        &:hover {
                            opacity: 0.7;
                            text-decoration: none;
                            color: #fff;
                        }
                    }
                    &.user-login {
                        svg {
                            width: 22px;
                            height: 22px;
                        }
                    }
                }
            }
        }
        .enable-idea-button {
            background: linear-gradient(
                100deg,
                var(--primary) 0%,
                var(--secondary) 100%
            ) !important;
        }
        .disable-idea-button {
            background: var(--secondary) !important;
        }
        .header-button-main {
            width: 100%;
            height: 100%;
            flex-wrap: nowrap !important;
            .header-button {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: inherit;
                color: white;
            }
            .feed-back {
                height: 100%;
                background: transparent;
            }
        }
        .btn-idea {
            border: 1px solid #fff;
            padding: rem(10px) rem(30px);
            display: flex;
            align-items: center;
            font-size: rem(18px);
            font-weight: 800;
            position: relative;
            overflow: hidden;
            &:after {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                left: -100%;
                background: var(--primary);
                transition: all 0.4s ease;
                z-index: -1;
            }
            svg {
                width: 32px;
                height: 32px;
            }
            span {
                color: #fff;
                margin: 2px 0 0 10px;
            }
            @media screen and (min-width: 1025px) {
                &:hover {
                    text-decoration: none;
                    border-color: var(--primary);
                    &:after {
                        left: 0;
                    }
                }
            }
            &.on-mobile {
                background: var(--white);
                @include flex(center, center);
                padding: 0;
                width: rem(48px);
                height: rem(48px);
                position: fixed;
                right: 0;
                top: rem(250px);
                display: none;
                span {
                    margin: 0 !important;
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    @include flex(center, center);
                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d263a5+0,ad6dab+100 */
                    background: #d263a5; /* Old browsers */
                    background: -moz-linear-gradient(
                        -45deg,
                        #d263a5 0%,
                        #ad6dab 100%
                    ); /* FF3.6-15 */
                    background: -webkit-linear-gradient(
                        -45deg,
                        #d263a5 0%,
                        #ad6dab 100%
                    ); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(
                        135deg,
                        #d263a5 0%,
                        #ad6dab 100%
                    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    svg {
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }
    }
    &.vue-fixed-header--isFixed {
        position: fixed;
        background: rgba(0, 0, 0, 0.7);
        padding: rem(20px) 0px;
        -webkit-animation-name: animationFade;
        -o-animation-name: animationFade;
        animation-name: animationFade;
        -webkit-animation-duration: 1s;
        -o-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        -o-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    @media screen and (max-width: 767px) {
        padding: rem(32px) 0;
        &--links {
            .btn-idea {
                display: none;
                &.on-mobile {
                    display: flex !important;
                }
            }
            ul {
                width: 100%;
                li {
                    padding: 0 !important;
                    margin: 0 !important;
                    &:not(:last-child) {
                        &:after {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
    .lang-selector {
        font-family: $arabic-font !important;
    }
}
.notification_icon {
    svg {
        fill: #fff;
    }
}
.notification_dropdown {
    .notification {
        position: absolute;
        width: 400px;
        top: 58px;
        left: -25px;
        background: #fff;
        padding: 22px !important;
        transition: 0.2s ease all;
        opacity: 0;
        visibility: hidden;
        z-index: 999;
        -webkit-box-shadow: 0px 10px 13px -7px #000000,
            5px 5px 15px 5px rgba(0, 0, 0, 0);
        box-shadow: 0px 10px 13px -7px #000000,
            5px 5px 15px 5px rgba(0, 0, 0, 0);
        @media screen and (max-width: 991px) {
            left: 50%;
            transform: translateX(-50%);
            width: 300px;
            padding: 15px !important;
        }
        @media screen and (max-width: 576px) {
            top: 45px;
            left: 40%;
            transform: translateX(-55%);
        }
        @media screen and (max-width: 991px) and (orientation: landscape) {
            top: 50px;
        }
        ul {
            padding: 0px 10px;
            li {
                position: relative;
                display: block;
                margin-bottom: 15px !important;
                border-bottom: 1px solid #636060;
                padding-bottom: 15px !important;
                margin-left: unset;
                margin-right: unset;
                // @media screen and (max-width:991px) {
                //     padding
                // }
                a {
                    &:hover {
                        color: var(--primary) !important;
                    }
                }
                &:last-child {
                    border: unset !important;
                    padding: unset !important;
                }
                &.visited {
                    a {
                        opacity: 0.5;
                        text-decoration: none;
                        pointer-events: initial;
                    }
                    .lower_section {
                        &::before {
                            content: unset;
                        }
                        span {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
        .lower_section {
            &::before {
                content: '';
                width: 14px;
                height: 14px;
                background: var(--secondary);
                position: absolute;
                right: 0;
                border-radius: 50%;
            }
            span {
                color: var(--primary);
            }
        }
        &.active {
            opacity: 1;
            visibility: visible;
        }
    }
}

// ar style

.ar {
    .header {
        &--links {
            ul.list-inline {
                li {
                    margin-left: rem(25px);
                    padding-left: rem(25px);
                    margin-right: 0;
                    padding-right: 0;
                    &:not(:last-child) {
                        &:after {
                            left: 0px;
                            right: auto;
                        }
                    }
                }
            }
            .btn-idea {
                &:after {
                    right: -100%;
                    left: auto;
                }
                span {
                    color: #fff;
                    margin: 2px 10px 0 0;
                }
                @media screen and (min-width: 1025px) {
                    &:hover {
                        text-decoration: none;
                        border-color: var(--primary);
                        &:after {
                            right: 0;
                            left: auto;
                        }
                    }
                }
                &.on-mobile {
                    left: 0;
                    right: auto;
                }
            }
        }
    }
    .notification_dropdown {
        direction: rtl;
        .notification {
            .lower_section {
                text-align: right;
                &::before {
                    left: 0;
                    right: auto;
                    text-align: right;
                }
                span {
                    color: var(--primary);
                }
            }
            &.active {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
</style>
