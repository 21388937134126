<template>
    <div>
        <div
            class="filesList"
            style="padding: 1.25rem 1.5625rem 1.25rem 1.5625rem;"
        >
            <div v-for="(uploadedFile, k) in uploadedFiles" :key="k">
                <div>
                    <b-progress
                        v-if="uploadedFile.progress"
                        :value="uploadedFile.progress"
                        max="100"
                        show-progress
                        animated
                    ></b-progress>
                </div>
                <div class="filesList__items">
                    <div class="filesList__items--image">
                        {{ uploadedFile.filename }}
                    </div>
                    <div class="filesList__items--delete">
                        <a @click="deleteFile(k)">{{ $t('Delete') }}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="postComments">
            <div class="postComments__wrapper">
                <div>
                    <b-form-textarea
                        :placeholder="$t('Write your comment…')"
                        rows="2"
                        max-rows="6"
                        v-model="comment"
                    ></b-form-textarea>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="postComments__wrapper--fileupload">
                        <!-- <b-form-file ref="file-input" class="mb-2"></b-form-file> -->
                        <vue-dropzone
                            ref="vueDropzone"
                            id="dropzone"
                            :options="dropzoneOptions"
                            :useCustomSlot="true"
                            @vdropzone-success="someSuccessMethod"
                            @vdropzone-upload-progress="dropzoneUploadProgress"
                            @vdropzone-file-added="dropzoneFileAdded"
                        >
                            <img
                                src="@/assets/images/svg/fileupload-icon.svg"
                                alt=""
                                style="width: 30px;"
                            />
                        </vue-dropzone>
                    </div>
                    <div>
                        <b-button class="submit" @click="click">
                            <svg
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 321.49 294.65"
                            >
                                <use xlink:href="#submit-icon"></use>
                            </svg>
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'

import { API_BASE } from '@/conf'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
    props: {
        object: Object,
    },
    components: {
        vueDropzone: vue2Dropzone,
    },
    data: function() {
        return {
            dropzoneOptions: {
                url: `${API_BASE}/en/hcfljiqzeb/`,
                paramName: 'file',
                thumbnailWidth: 150,
                addRemoveLinks: false,
                headers: {
                    'Cache-Control': null,
                    'X-Requested-With': null,
                },
                previewsContainer: false,
            },
            comment: '',
            uploadedFiles: [],
        }
    },
    methods: {
        someSuccessMethod(file, response) {
            const f = this.uploadedFiles.find(f => file.upload.uuid == f.uuid)
            f.path = response.path
            f.progress = 0
            delete f.progress
            delete f.uuid
            this.$refs.vueDropzone.removeAllFiles()
        },
        dropzoneUploadProgress(file, totalBytes) {
            const f = this.uploadedFiles.find(f => file.upload.uuid == f.uuid)
            f.progress = totalBytes
        },
        dropzoneFileAdded(file) {
            this.uploadedFiles.push({
                uuid: file.upload.uuid,
                filename: file.name,
                progress: 0,
            })
        },
        click() {
            this.$emit('click', {
                uuid: this.object.uuid,
                comment: this.comment,
                attachment: this.uploadedFiles[0],
            })
            this.comment = ''
            this.uploadedFiles = []
        },
        deleteFile(k) {
            this.uploadedFiles.splice(k, 1)
        },
    },
}
</script>

<style lang="scss" scoped>
.filesList__items {
    padding: 0 !important;
}
.postComments {
    background: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    padding: rem(20px) rem(25px) rem(20px) rem(25px);
    &__wrapper {
        @include flex(flex-start, space-between);
        & > div {
            flex: 1;
            &:nth-child(2) {
                flex: 0 0 110px;
                max-width: 110px;
            }
        }

        .form-control {
            background: transparent;
            color: #464646;
            font-size: rem(20px);
            box-shadow: none;
            padding: 0px;
            padding-top: rem(10px);
            height: rem(50px) !important;
            &::placeholder {
                font-size: rem(20px);
                color: #464646;
            }
            &:focus {
                box-shadow: none;
                border-color: transparent;
            }
        }
        .submit {
            border-radius: 50%;
            width: 55px;
            height: 55px;
            background: #e4d700;
            color: #fff;
            padding: 0px;
            border: none;
            svg {
                width: 26px;
                height: auto;
                transform: rotate(-35deg);
                position: relative;
                right: -2px;
                top: -2px;
            }
        }
        &--fileupload {
            width: 55px;
            height: 55px;
            padding-top: 13px;
            .dropzone {
                padding: 0;
                min-height: auto;
                border: 0;
                margin-right: 20px;
            }
            .dropzone:hover {
                background-color: #ffffff;
            }
        }
        /deep/ {
            .custom-file-label {
                background: url("data:image/svg+xml;charset=UTF-8,%3csvg id='Layer_1' fill='%236e337a' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 477.08 500'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:none;%7d.cls-2%7bclip-path:url(%23clip-path);%7d%3c/style%3e%3cclipPath id='clip-path' transform='translate(0 0)'%3e%3crect class='cls-1' width='477.08' height='500'/%3e%3c/clipPath%3e%3c/defs%3e%3ctitle%3efileupload-icon%3c/title%3e%3cg class='cls-2'%3e%3cpath d='M109.47,229.41,33.38,305.5A19.55,19.55,0,1,1,5.73,277.85l89-89h0L243.49,40.09a136.87,136.87,0,0,1,193.57,0c53.36,53.36,53.36,140.2,0,193.56L288.24,382.46l-89,89A97.77,97.77,0,0,1,61,333.15L150,244.2l135-135a58.65,58.65,0,1,1,82.95,82.95l-135,135-36.32,36.32A19.56,19.56,0,0,1,169,335.82l28.78-28.77h0L340.27,164.52a19.55,19.55,0,1,0-27.65-27.65L166.55,282.94,88.69,360.8a58.66,58.66,0,1,0,82.95,83l82.3-82.29h0L409.4,206A97.76,97.76,0,1,0,271.14,67.74Z' transform='translate(0 0)'/%3e%3c/g%3e%3c/svg%3e");
                background-repeat: no-repeat;
                width: 30px;
                height: 30px;
                border: 0px;
                &:after {
                    display: none;
                }
                .form-file-text {
                    display: none !important;
                }
            }
        }

        @media screen and (max-width: 575px) {
            .submit {
                width: 40px;
                height: 40px;
                svg {
                    width: 22px;
                }
            }
            &--fileupload {
                width: 40px;
                height: 40px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        padding: rem(16px);
        &__wrapper {
            .form-control {
                padding-top: 10px;
            }
            .submit {
                width: 40px;
                height: 40px;
                svg {
                    width: 22px;
                }
            }
            /deep/.custom-file-label {
                width: 24px;
                height: 24px;
            }
            &--fileupload {
                height: 54px;
            }
            > div {
                &:nth-child(2) {
                    flex: 0 0 80px;
                    max-width: 80px;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        padding: rem(5px) rem(10px);

        &__wrapper {
            & > div {
                &:nth-child(2) {
                    flex: 0 0 65px;
                    max-width: 65px;
                }
            }
            &--fileupload {
                .dropzone {
                    margin-right: 10px;
                    img {
                        width: 25px !important;
                    }
                }
            }
            .submit {
                width: 35px;
                height: 35px;
                svg {
                    width: 18px;
                }
            }
        }
    }
    @media screen and (max-width: 450px) {
        &__wrapper {
            &--fileupload {
                height: 54px;
            }
            /deep/.custom-file-label {
                width: 20px;
                height: 20px;
            }
            > div {
                &:nth-child(2) {
                    flex: 0 0 75px;
                    max-width: 75px;
                }
            }
        }
    }
}

// ar style

.ar {
    .postComments {
        &__wrapper {
            .submit {
                svg {
                    left: -2px;
                    right: auto;
                    transform: rotate(200deg);
                }
            }
            &--fileupload {
                /deep/ {
                    .dropzone {
                        margin-right: 0px;
                        margin-left: 20px;
                        @media screen and (max-width: 767px) {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>
